import React, { createContext, useState, useEffect } from 'react';
import axios from "axios";
import Cookies from 'js-cookie';
import { parseInvoiceDocument } from './Utils/documentParser';
import { sortBillsByCode } from './Pages/Billing/utils/formatters';
const DataContext = React.createContext();

// http://127.0.0.1:3010

let burl = "https://api2.mypacome-manager.fr"

// burl = "https://api2.mypacome-manager.fr"
// burl = "http://localhost:3010"
// burl = "https://preprod.api2.mypacome-manager.fr"

//burl =  "https://api2.mypacome-manager.fr" "https://api.sandbox.mypacome-manager.fr" "https://localhost:3000"

export class DataProvider extends React.Component {
    state = {
        user: {},
        token: null,
        data: {},
        error: {},
        loading: false,
    }
    header = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    headerAuth = () => {
        return {
            headers: {
                "Content-Type": "application/json",
                "token": this.state.token
            }
        }
    }
    headerAuthFiles = () => {
        return {
            headers: {
                "Content-Type": "multipart/form-data",
                "token": this.state.token
            }
        }
    }

    headerAuthFilesTypeless = () => {
        return {
            headers: {
                "token": this.state.token
            }
        }
    }

    headerAuthFiles2 = () => {
        return {
            headers: {
                "Content-Type": "application/json",
                "token": this.state.token
            }
        }
    }

    setUser = async (user, token) => {
        return new Promise((resolve, reject) => {
            this.setState({ user: user, token: token }, (err) => {
                if (err) reject(err)
                else resolve()
            })
        })
    }
    setData = (data) => {
        return new Promise(((resolve, reject) => {
            const people = []
            for (const key in data.people) {
                for (const item in data.people[key]) {
                    const itemRefined = data.people[key][item]
                    itemRefined.type = key
                    people.push(itemRefined)
                }
            }
            this.setState({ data: { people: data.people, list: people } }, (err) => {
                if (err) {
                    this.setError({ message: err })
                        .then(() => {
                            return reject(err)
                        })
                        .catch((err2) => {
                            return reject(err2)
                        })

                } else {
                    return resolve()
                }
            })
        }))
    }
    addData = (data) => {
        return new Promise(((resolve, reject) => {
            const newData = this.state.data
            for (const key in data) {
                for (const item in data[key]) {
                    const itemRefined = data[key][item]
                    itemRefined.type = key

                    //Check if element already exist, if so delete it
                    newData.list = newData.list.filter((i) => {
                        return !(
                            i.email === itemRefined.email
                            && i.type === itemRefined.type
                            && (!!itemRefined.id ? i.id === itemRefined.id : true)
                        )
                    })
                    newData.people[key] = newData.people[key].filter((i) => {
                        return !(
                            i.email === itemRefined.email
                            && (!!itemRefined.id ? i.id === itemRefined.id : true)
                        )
                    })

                    //Push new element
                    newData.list.push(itemRefined)
                    newData.people[key].push(data[key][item])
                }
            }
            this.setState({ data: newData }, (err) => {
                if (err) {
                    this.setError({ message: err })
                        .then(() => {
                            return reject(err)
                        })
                        .catch((err) => {
                            return reject(err)
                        })

                } else {
                    return resolve()
                }
            })
        }))
    }
    setError = (data) => {
        return new Promise((resolve, reject) => {
            if (this._isMounted === false) {
                console.warn("Attempted to set error state on unmounted component");
                resolve();
                return;
            }

            this.setState({ error: data }, (err) => {
                if (err) reject(err);
                else resolve();
            });
        });
    }
    logout = () => {
        Cookies.remove('mypacome-PM', { path: '/' })
        this.setState({ user: {}, token: '' })
    }
    isLogged = () => {
        return !!this.state.token
    }
    findInterventionPeople = (id) => {
        let user = this.state.data.people?.Concierge.find(el => el.id === id)
        if (!user) user = this.state.data.people?.Equipe.find(el => el.id === id)
        if (!user) user = { name: "Error", lastName: "NotFound" }
        return user
    }
    getBurl = () => {
        return burl
    }
    errorHandler = (err) => {
        let errorList = ''

        console.error("API Error Details:", {
            status: err.response?.status,
            statusText: err.response?.statusText,
            data: err.response?.data,
            message: err.message,
            stack: err.stack
        });

        if (err.message === "Network Error") {
            errorList = "Erreur de connexion réseau. Vérifiez votre connexion internet et que le serveur API est accessible.";
            console.error(`Network Error with URL: ${burl}. Check your internet connection and server availability.`);
        }
        else if (err.message && err.message.includes("CORS")) {
            errorList = "Erreur de connexion au serveur (CORS). Vérifiez que le serveur API est démarré et correctement configuré.";
            console.error(`CORS Error with URL: ${burl}. Check server CORS configuration.`);
        }
        else if (err.response?.status === 500) {
            errorList = `Erreur interne du serveur: ${err.response?.data || 'Aucune information disponible'}`;
            console.error("Server 500 Error:", err.response?.data);
        }
        else if (err.response?.status === 401) {
            errorList = "Problème d'authentification, veuillez vous reconnecter.";
            this.logout();
        }
        else if (err.response?.data?.error) {
            console.log(err.response?.data.error)
            errorList = "\n" + errorRefiner(err.response.data.error).join(' \n,');
        }
        else {
            errorList = `Erreur non identifiée: ${err.message}`;
            console.error("Unknown error:", err);
        }

        const message = errorList || "veuillez verifier que vous avez bien remplis le formulaire.";

        this.setError({
            level: 'warning',
            message: 'Le serveur indique une erreur dans votre demande :\n ' + message,
            btn1Text: 'Revenir',
            btn2hidden: true
        })
    }
    apiReducer = async (action, payload, option) => {
        return new Promise((resolve, reject) => {
            let isAnAction = false

            //USERS

            if (action === 'login') {
                isAnAction = true
                axios.post(burl + '/user/login', payload)
                    .then((response) => {
                        if (!response.data.user || !response.data.token) return reject('Misssing information from server')
                        let user = response.data.user
                        let token = response.data.token
                        this.setUser(response.data.user, response.data.token)
                            .then((response) => {
                                if (option.remember) {
                                    Cookies.set('mypacome-PM', JSON.stringify({
                                        user: user,
                                        token: token
                                    }), { path: '/' })
                                    this.apiReducer('fetchData')
                                        .then(() => {
                                            return resolve()
                                        })
                                        .catch((err) => {
                                            this.setData({ message: err })
                                                .then(() => {
                                                    return reject(err)
                                                })
                                                .catch(() => {
                                                    return reject(err)
                                                })
                                        })
                                } else {
                                    Cookies.set('mypacome-PM', JSON.stringify({
                                        user: user,
                                        token: token
                                    }), { expires: 1, path: '/' })
                                    return resolve()
                                }
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })

            }
            if (action === 'creation') {
                isAnAction = true
                axios.post(burl + '/user/creation/' + option.token, payload)
                    .then(() => {
                        return resolve()
                    })
                    .catch((err) => {
                        return reject(err)
                    })
            }
            if (action === 'reset') {
                isAnAction = true
                axios.post(burl + '/user/reset', payload)
                    .then((response) => {
                        return resolve()
                    })
                    .catch((err) => {
                        return reject(err)
                    })
            }
            if (action === 'resetPassword') {
                isAnAction = true
                axios.post(burl + '/user/reset/' + option.token, payload)
                    .then(() => {
                        return resolve()
                    })
                    .catch((err) => {
                        return reject(err)
                    })
            }
            if (action === 'fetchData') {
                isAnAction = true
                this.setState({ loading: true })
                if (!this.state.token) {
                    console.error('No authentication token available. Please log in again.');
                    this.setError({
                        level: 'warning',
                        message: 'Votre session a expiré. Veuillez vous reconnecter.',
                        btn1Text: 'Revenir',
                        btn2hidden: true
                    });
                    this.logout();
                    this.setState({ loading: false });
                    return reject(new Error('No authentication token'));
                }

                axios.get(burl + '/user/optimized-all', {
                    ...this.headerAuth(),
                }).then((result) => {
                    this.setData({ people: result.data })
                        .then(() => {
                            this.setState({ loading: false }, () => {
                                return resolve()
                            })
                        })
                })
                    .catch((err) => {
                        console.error('Error setting data:', err);
                        this.setState({ loading: false }, () => {
                            return reject(err)
                        })
                    })
                    .catch((err) => {
                        console.error('API call failed with error:', err);
                        console.error('Error details:', {
                            message: err.message,
                            response: err.response ? {
                                status: err.response.status,
                                statusText: err.response.statusText,
                                data: err.response.data
                            } : 'No response',
                            request: err.request ? 'Request exists' : 'No request'
                        });

                        // If we get a 401 Unauthorized, clear the token and redirect to login
                        if (err.response && err.response.status === 401) {
                            this.logout();
                        }

                        // If we get a 502 Bad Gateway, provide a more helpful message
                        if (err.response && err.response.status === 502) {
                            console.error('API server returned 502 Bad Gateway. The server might be down or misconfigured.');
                            this.setError({
                                level: 'warning',
                                message: 'Le serveur API est indisponible (502 Bad Gateway). Veuillez contacter l\'administrateur système.',
                                btn1Text: 'Revenir',
                                btn2hidden: true
                            });
                            this.setState({ loading: false });
                            return reject(err);
                        }

                        this.errorHandler(err);
                        this.setState({ loading: false });
                        return reject(err);
                    })
            }

            //CLIENT

            //      TRANSACTIONAL
            if (action === 'addClient') {
                isAnAction = true
                axios.post(burl + '/client/', payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve(result.data)
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.setError({
                            level: 'warning',
                            message: 'Le serveur indique une erreur dans votre demande, veuillez verifier que vous avez bien remplis le formulaire : ' + err,
                            btn1Text: 'Revenir',
                            btn2hidden: true
                        })
                        return reject(err)
                    })
            }
            if (action === "getRealClients") {
                isAnAction = true
                axios.get(burl + '/client/real', this.headerAuth())
                    .then((result) => {
                        return resolve(result.data)
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }
            if (action === 'modifyClient') {
                isAnAction = true
                axios.put(burl + '/client/' + option.email, payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve(result.data)
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        console.log(err)
                        this.setError({
                            level: 'warning',
                            message: 'Le serveur indique une erreur dans votre demande :\n ' + err.message,
                            btn1Text: 'Revenir',
                            btn2hidden: true
                        })
                        return reject(err)
                    })
            }
            //      SUBSCRIPTION
            if (action === 'addSubscription') {
                isAnAction = true
                axios.post(
                    burl + '/client/subscription/' + option.email,
                    payload,
                    this.headerAuth()
                )
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        console.log(err)
                        this.errorHandler(err)
                        return reject(err)
                    })
            }
            if (action === 'modifySubscription') {
                isAnAction = true
                axios.put(
                    burl + '/client/subscription/' + option.email,
                    payload,
                    this.headerAuth()
                )
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }
            //      PROPERTY
            if (action === 'addProperty') {
                isAnAction = true
                axios.post(burl + '/client/properties/', payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        console.log(err)
                        this.setError({
                            level: 'warning',
                            message: 'Le serveur indique une erreur dans votre demande, veuillez verifier que vous avez bien remplis le formulaire',
                            btn1Text: 'Revenir',
                            btn2hidden: true
                        })
                        return reject(err)
                    })
            }
            if (action === 'modifyProperty') {
                isAnAction = true
                if (payload.guide) {
                    const fd = new FormData()
                    fd.append('guide', payload.guide)
                    fd.append("payload", payload)
                    payload = fd
                }
                axios.put(burl + '/client/properties/' + option.name, payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                console.log(err)
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        console.log(err)
                        this.setError({
                            level: 'warning',
                            message: 'Le serveur indique une erreur dans votre demande, veuillez verifier que vous avez bien remplis le formulaire',
                            btn1Text: 'Revenir',
                            btn2hidden: true
                        })
                        return reject(err)
                    })
            }
            //      HOMEVIEW
            if (action === 'addHomeView') {
                isAnAction = true
                axios.post(burl + '/client/homeview/', payload, this.headerAuthFiles())
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }
            //      HOMEVIEWNEW
            if (action === 'addHomeViewNew') {
                isAnAction = true
                axios.post(burl + '/client/homeviewnew/', payload, this.headerAuthFiles2())
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }
            //      BOOKING
            if (action === 'addBooking') {
                isAnAction = true
                axios.post(burl + '/client/properties/' + option.name, payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.setError({
                            level: 'warning',
                            message: 'Le serveur indique une erreur dans votre demande, veuillez verifier que vous avez bien remplis le formulaire',
                            btn1Text: 'Revenir',
                            btn2hidden: true
                        })
                        return reject(err)
                    })
            }
            if (action === 'addBookingInterventions') {
                isAnAction = true
                axios.post(burl + '/client/bookings/interventions/', payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        let errorList = ''
                        if (err.response?.data.error) {
                            errorList = "\n" + errorRefiner(err.response.data.error).join(' \n,')
                        }
                        const message = errorList ? errorList : "veuillez verifier que vous avez bien remplis le formulaire."

                        this.setError({
                            level: 'warning',
                            message: 'Le serveur indique une erreur dans votre demande :\n ' + message,
                            btn1Text: 'Revenir',
                            btn2hidden: true
                        })
                        return reject(err)
                    })
            }
            if (action === 'modifyBooking') {
                isAnAction = true
                axios.put(burl + '/client/bookings/' + option.id, payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        let errorList = ''
                        if (err.response?.data.error) {
                            errorList = "\n" + errorRefiner(err.response.data.error).join(' \n,')
                        }
                        const message = errorList ? errorList : "veuillez verifier que vous avez bien remplis le formulaire."

                        this.setError({
                            level: 'warning',
                            message: 'Le serveur indique une erreur dans votre demande :\n ' + message,
                            btn1Text: 'Revenir',
                            btn2hidden: true
                        })
                        return reject(err)
                    })
            }
            if (action === 'deleteBooking') {
                isAnAction = true
                axios.post(burl + '/client/properties/' + option.name, payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.setError({
                            level: 'warning',
                            message: 'Le serveur indique une erreur dans votre demande, veuillez verifier que vous avez bien remplis le formulaire',
                            btn1Text: 'Revenir',
                            btn2hidden: true
                        })
                        return reject(err)
                    })
            }
            //      INTERVENTIONS
            if (action === 'addIntervention') {
                isAnAction = true
                axios.post(burl + '/client/interventions/' + option.name, payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }
            if (action === 'ValidateInterventionConcierge') {
                isAnAction = true
                axios.post(burl + '/user/intervention/', payload, this.headerAuthFiles())
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }
            if (action === 'modifyIntervention') {
                isAnAction = true
                axios.put(burl + '/client/interventions/' + option.id, payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.setError({
                            level: 'warning',
                            message: 'Le serveur indique une erreur dans votre demande, veuillez verifier que vous avez bien remplis le formulaire',
                            btn1Text: 'Revenir',
                            btn2hidden: true
                        })
                        return reject(err)
                    })
            }
            if (action === 'modifyMultipleIntervention') {
                isAnAction = true
                axios.put(burl + '/client/interventionsMultiple/' + option.group, payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        let errorList = ''
                        if (err.response?.data.error) {
                            errorList = "\n" + errorRefiner(err.response.data.error).join(' \n,')
                        }
                        const message = errorList ? errorList : "veuillez verifier que vous avez bien remplis le formulaire."

                        this.setError({
                            level: 'warning',
                            message: 'Le serveur indique une erreur dans votre demande :\n ' + message,
                            btn1Text: 'Revenir',
                            btn2hidden: true
                        })
                        return reject(err)
                    })
            }

            //TEAM

            if (action === 'addTeam') {
                isAnAction = true
                axios.post(burl + '/user/', payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Equipe: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.setError({
                            level: 'warning',
                            message: 'Le serveur indique une erreur dans votre demande, veuillez verifier que vous avez bien remplis le formulaire',
                            btn1Text: 'Revenir',
                            btn2hidden: true
                        })
                        return reject(err)
                    })
            }
            if (action === 'modifyTeam') {
                isAnAction = true
                axios.put(burl + '/user/' + option.email, payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Equipe: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.setError({
                            level: 'warning',
                            message: 'Le serveur indique une erreur dans votre demande, veuillez verifier que vous avez bien remplis le formulaire',
                            btn1Text: 'Revenir',
                            btn2hidden: true
                        })
                        return reject(err)
                    })
            }

            //CONCIERGES

            if (action === 'addConcierge') {
                isAnAction = true
                axios.post(burl + '/concierge/', payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Concierge: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }
            if (action === 'modifyConcierge') {
                isAnAction = true
                axios.put(burl + '/concierge/' + option.email, payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Concierge: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.setError({
                            level: 'warning',
                            message: 'Le serveur indique une erreur dans votre demande, veuillez verifier que vous avez bien remplis le formulaire',
                            btn1Text: 'Revenir',
                            btn2hidden: true
                        })
                        return reject(err)
                    })
            }
            if (action === 'deleteConcierge') {
                isAnAction = true
                axios.delete(burl + '/concierge/' + option.email, this.headerAuth())
                    .then((result) => {
                        this.addData({ Concierge: [result.data.Concierge], Client: result.data.Client })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }

            //PROVIDERS

            if (action === 'addProvider') {
                isAnAction = true
                axios.post(burl + '/provider/', payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Prestataires: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }
            if (action === 'modifyProvider') {
                isAnAction = true
                axios.put(burl + '/provider/', payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Prestataires: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }

            //PRODUCTS

            if (action === 'getProductsList') {
                isAnAction = true
                axios.get(burl + '/billing/products', this.headerAuth())
                    .then((response) => {
                        const newData = this.state.data;
                        try {
                            // Handle both string and object responses
                            let products = response.data;
                            if (typeof products === 'string') {
                                products = JSON.parse(products);
                            }
                            if (typeof products.products === 'string') {
                                products.products = JSON.parse(products.products);
                            }
                            newData.products = products.products || products;

                            this.setState({ data: newData }, () => resolve(newData.products));
                        } catch (error) {
                            console.error('Error processing products response:', error);
                            reject(error);
                        }
                    })
                    .catch((err) => {
                        this.errorHandler(err);
                        reject(err);
                    });
            }

            if (action === 'setProductsList') {
                isAnAction = true
                axios.post(burl + '/gestion/produits', payload, this.headerAuthFiles())
                    .then((result) => {
                        return resolve("i")
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }

            if (action === 'createProductsFromCSV') {
                isAnAction = true
                payload.append('action', 'createProductsFromCSV')
                console.log(payload, 'payload')
                axios.post(burl + '/billing/products', payload, this.headerAuthFiles())
                    .then((result) => {
                        return resolve("i")
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }

            if (action === "getProducts") {
                isAnAction = true;
                axios.get(burl + '/billing/products', this.headerAuth())
                    .then((response) => resolve(response.data))
                    .catch((err) => reject(this.errorhandler(err.response)));
            }

            if (action === "getProductCode") {
                isAnAction = true;
                axios.get(burl + '/billing/products/code', this.headerAuth())
                    .then((response) => resolve(response.data))
                    .catch((err) => reject(this.errorhandler(err.response)));
            }

            if (action === "addProduct") {
                isAnAction = true;
                axios.post(burl + '/billing/products/add', payload, this.headerAuth())
                    .then((response) => resolve(response.data))
                    .catch((err) => reject(this.errorhandler(err.response)));
            }

            if (action === "modifyProduct") {
                isAnAction = true;
                axios.post(burl + '/billing/products/modify', payload, this.headerAuth())
                    .then((response) => resolve(response.data))
                    .catch((err) => reject(this.errorhandler(err.response)));
            }

            if (action === "createProductsFromCSV") {
                isAnAction = true;
                axios.post(burl + '/billing/products', payload, this.headerAuthFiles())
                    .then((response) => resolve(response.data))
                    .catch((err) => reject(console.error(err)));
            }

            // Modify Home Sheets

            if (action === 'modifyHomeViewStructure') {
                isAnAction = true
                axios.post(burl + '/gestion/homeviewstructure', payload, this.headerAuth())
                    .then((result) => {
                        return resolve()
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }

            if (action === "sendInventory") {
                isAnAction = true
                let fd = new FormData()
                if (typeof payload !== 'object') {
                    return reject('Payload is not an object')
                } else {
                    payload = JSON.stringify(payload)
                    fd.append('inv', payload)
                    fd.append('when', "sortie")
                    fd.append('email', option)
                }
                axios.post(burl + '/gestion/sendInventory', fd, this.headerAuthFiles())
                    .then((result) => {
                        return resolve()
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }

            if (action === "addSector") {
                isAnAction = true
                axios.post(burl + "/gestion/sector/create", payload, this.headerAuthFilesTypeless())
                    .then((result) => {
                        return resolve(result.data)
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }
            if (action === "modifySector") {
                isAnAction = true
                axios.post(burl + "/gestion/sector/modify", payload, this.headerAuthFilesTypeless())
                    .then((result) => {
                        return resolve(result.data)
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }

            if (action === "getSectors") {
                isAnAction = true
                axios.get(burl + "/gestion/sector/get", this.headerAuth())
                    .then((result) => {
                        return resolve(result.data)
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }

            if (action === "deleteSector") {
                isAnAction = true
                const fd = new FormData()
                fd.append('id', payload.id)
                axios.post(burl + "/gestion/sector/delete/", fd, this.headerAuth())
                    .then((result) => {
                        return resolve()
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }

            // Billing - products

            if (action === "getProducts") {
                isAnAction = true
                axios.get(burl + "/billing/products", this.headerAuth())
                    .then((result) => {
                        return resolve(result.data)
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }
            if (action === "getContacts") {
                isAnAction = true
                axios.get(burl + "/billing/contacts", this.headerAuth())
                    .then((result) => {
                        return resolve(result.data)
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }

            // Billing - invoices

            if (action === "getInvoices") {
                isAnAction = true
                axios.get(burl + "/billing/invoices", this.headerAuth())
                    .then((result) => {
                        if (result.data.invoices) {
                            result.data.invoices = result.data.invoices.map(invoice => {
                                // Use the enhanced document parser
                                invoice.billContents = parseInvoiceDocument(invoice);
                                return invoice;
                            });
                        }
                        return resolve(result.data)
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }

            if (action === "postInvoice") {
                isAnAction = true
                axios.post(burl + "/billing/invoices", payload, this.headerAuth())
                    .then((result) => {
                        return resolve(result.data)
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }

            if (action === "getInvoiceCode") {
                isAnAction = true
                axios.get(burl + "/billing/invoices/code", this.headerAuth())
                    .then((result) => {
                        return resolve(result.data)
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }

            if (action === "invoiceTransition") {
                isAnAction = true
                axios.post(burl + "/billing/invoices/transition", payload, this.headerAuth())
                    .then((result) => {
                        if (result.data && result.data.invoice && result.data.bill) {
                            console.log("Transition successful - received invoice and bill:", {
                                invoice: result.data.invoice.code,
                                bill: result.data.bill.code
                            });

                            this.setState(prevState => {
                                if (!prevState.invoices) {
                                    console.warn("Warning: invoices array is undefined in state");
                                    return {
                                        invoices: [result.data.invoice],
                                        bills: [...(prevState.bills || []), result.data.bill]
                                    };
                                }

                                return {
                                    invoices: prevState.invoices.map(inv =>
                                        inv._id === result.data.invoice._id ? result.data.invoice : inv
                                    ),
                                    bills: [...(prevState.bills || []), result.data.bill]
                                };
                            });

                            Promise.all([
                                this.apiReducer("getInvoices"),
                                this.apiReducer("getBills")
                            ])
                                .then(() => {
                                    return resolve(result.data);
                                })
                                .catch((err) => {
                                    console.warn("Warning: Could not refresh lists after transition", err);
                                    return resolve(result.data);
                                });
                        } else {
                            // If we don't have the expected structure, just return what we got
                            // This allows the API to handle the transition properly
                            console.warn("Transition response missing expected data structure, returning raw response");

                            // Force refresh both lists to ensure UI is updated regardless of response format
                            Promise.all([
                                this.apiReducer("getInvoices"),
                                this.apiReducer("getBills")
                            ])
                                .then(() => {
                                    return resolve(result.data || { success: true });
                                })
                                .catch((err) => {
                                    console.warn("Warning: Could not refresh lists after transition", err);
                                    return resolve(result.data || { success: true });
                                });
                        }
                    })
                    .catch((err) => {
                        console.error("Error in invoiceTransition:", err);
                        this.errorHandler(err);
                        return reject(err);
                    });
            }

            if (action === "getBills") {
                isAnAction = true;

                // Check if we need to bypass cache
                const bypassCache = payload && payload.bypassCache;
                const url = bypassCache
                    ? `${burl}/billing/bills?_nocache=${Date.now()}`
                    : `${burl}/billing/bills`;

                axios.get(url, this.headerAuth())
                    .then((result) => {
                        if (result.data.bills) {
                            result.data.bills = result.data.bills.map(bill => {
                                // Use the enhanced document parser
                                bill.billContents = parseInvoiceDocument(bill);
                                return bill;
                            });

                            // Filter by originalBillId if requested
                            if (payload && payload.filterByOriginalBill) {
                                result.data.bills = result.data.bills.filter(bill =>
                                    bill.originalBillId === payload.filterByOriginalBill
                                );
                            }

                            // Filter out deleted bills if requested
                            if (payload && payload.filterDeleted) {
                                result.data.bills = result.data.bills.filter(bill =>
                                    !bill.deleted && bill.state !== 'deleted' && bill.documentState !== 'deleted'
                                );
                            }

                            // Use the sortBillsByCode function from formatters.js if available
                            if (typeof sortBillsByCode === 'function') {
                                result.data.bills = sortBillsByCode(result.data.bills);
                            } else {
                                // Fallback to the old sorting method
                                result.data.bills.sort((a, b) => {
                                    // Extract numeric part from code (e.g., "FAC-2024001" -> 1)
                                    const getCodeNumber = (code) => {
                                        if (!code) return 0;
                                        const match = code.match(/\d+$/);
                                        return match ? parseInt(match[0], 10) : 0;
                                    };

                                    // First sort by year in code (e.g., "FAC-2024001" -> 2024)
                                    const getYear = (code) => {
                                        if (!code) return 0;
                                        const match = code.match(/(\d{4})/);
                                        return match ? parseInt(match[1], 10) : 0;
                                    };

                                    const yearA = getYear(a.code);
                                    const yearB = getYear(b.code);

                                    if (yearB !== yearA) {
                                        return yearB - yearA; // Sort by year descending
                                    }

                                    // Then sort by code number
                                    return getCodeNumber(b.code) - getCodeNumber(a.code);
                                });
                            }
                        }
                        return resolve(result.data);
                    })
                    .catch((err) => {
                        this.errorHandler(err);
                        return reject(err);
                    });
            }

            if (action === "addBill") {
                isAnAction = true
                axios.post(burl + "/billing/bills/", payload, this.headerAuth())
                    .then((result) => {
                        return resolve(result.data)
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }

            if (action === "modifyBill") {
                isAnAction = true;
                if (!payload._id) {
                    return reject(new Error('Bill ID is required for modification'));
                }
                axios.put(`${burl}/billing/bills/${payload._id}`, payload, this.headerAuth())
                    .then((result) => {
                        return resolve(result.data);
                    })
                    .catch((err) => {
                        this.errorHandler(err);
                        return reject(err);
                    });
            }

            if (action === "getBillCode") {
                isAnAction = true
                axios.get(burl + "/billing/bills/code", this.headerAuth())
                    .then((result) => {
                        return resolve(result.data)
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }

            if (action === "addProduct") {
                isAnAction = true
                axios.post(burl + "/billing/products/add", payload, this.headerAuth())
                    .then((result) => {
                        return resolve(result.data)
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }

            if (action === "modifyProduct") {
                isAnAction = true
                axios.post(burl + "/billing/products/modify", payload, this.headerAuth())
                    .then((result) => {
                        return resolve(result.data)
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }

            if (action === "getNextClientId") {
                isAnAction = true
                axios.get(burl + "/billing/contacts/nextid", this.headerAuth())
                    .then((result) => {
                        return resolve(result.data)
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }

            if (action === "exportBillsToCsv") {
                isAnAction = true
                const currentDate = new Date();
                const month = currentDate.getMonth() + 1;
                const year = currentDate.getFullYear();

                axios.get(burl + `/billing/bills/export?month=${month}&year=${year}`, this.headerAuth())
                    .then((result) => {
                        return resolve(result.data)
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }

            if (action === "addBillingClient") {
                isAnAction = true
                axios.post(burl + '/client', payload, this.headerAuth())
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve(result.data)
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })

            }

            if (action === "scrapeAirbnb") {
                isAnAction = true
                axios.post(burl + "/scraper/airbnb", payload, {
                    ...this.headerAuth(),
                    responseType: 'blob'
                })
                    .then((result) => {
                        return resolve(result.data)
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })
            }

            if (action === "sendBillEmail") {
                isAnAction = true;
                console.log('sendBillEmail payload:', {
                    ...payload,
                    pdfAttachment: payload.pdfAttachment ? '[PDF DATA]' : 'No PDF data',
                    email: payload.email // Log the email address
                });

                axios.post(burl + "/billing/bills/mail", payload, this.headerAuth())
                    .then((result) => {
                        console.log('sendBillEmail response:', result.data);
                        // Dispatch a custom event to notify components that the email was sent
                        const emailSentEvent = new CustomEvent('EMAIL_SENT', {
                            detail: { billId: payload.billId }
                        });
                        window.dispatchEvent(emailSentEvent);

                        return resolve(result.data);
                    })
                    .catch((err) => {
                        console.error('sendBillEmail error:', err);
                        this.errorHandler(err);
                        return reject(err);
                    });
            }

            if (action === "sendInvoiceEmail") {
                isAnAction = true;
                axios.post(burl + "/billing/invoices/mail", payload, this.headerAuth())
                    .then((result) => {
                        // Dispatch a custom event to notify components that the email was sent
                        const emailSentEvent = new CustomEvent('EMAIL_SENT', {
                            detail: { invoiceId: payload.invoiceId }
                        });
                        window.dispatchEvent(emailSentEvent);

                        return resolve(result.data);
                    })
                    .catch((err) => {
                        this.errorHandler(err);
                        return reject(err);
                    });
            }

            if (action === "addInvoice") {
                isAnAction = true;
                axios.post(burl + "/billing/invoices", payload, this.headerAuth())
                    .then((result) => {
                        return resolve(result.data);
                    })
                    .catch((err) => {
                        this.errorHandler(err);
                        return reject(err);
                    });
            }

            if (action === "duplicateBill") {
                isAnAction = true;
                axios.post(`${this.getBurl()}/billing/bills/duplicate`, payload, this.headerAuth())
                    .then((result) => {
                        return resolve(result.data);
                    })
                    .catch((err) => {
                        this.errorHandler(err);
                        return reject(err);
                    });
            }

            if (action === "deleteBill") {
                isAnAction = true;
                if (!payload._id) {
                    return reject(new Error('ID de facture requis pour la suppression'));
                }

                const bill = payload.bill || {};
                const updateData = {
                    ...bill,
                    _id: payload._id,
                    state: 'deleted',
                    documentState: 'deleted',
                    deleted: true
                };

                if (this.state.bills && this.state.bills.length > 0) {
                    const updatedBills = this.state.bills.map(b =>
                        b._id === payload._id ? { ...b, state: 'deleted', documentState: 'deleted', deleted: true } : b
                    );
                    this.setState({ bills: updatedBills });
                }

                return new Promise((resolveOptimistic) => {
                    const optimisticResponse = {
                        ...updateData,
                        success: true,
                        optimistic: true
                    };

                    resolveOptimistic(optimisticResponse);

                    axios.put(`${burl}/billing/bills/${payload._id}`, updateData, this.headerAuth())
                        .then(result => {
                            this.invalidateCache('api:bills')
                                .catch(err => console.error('Erreur d\'invalidation du cache:', err));

                            axios.get(`${burl}/billing/bills?_nocache=${Date.now()}`, this.headerAuth())
                                .catch(err => console.error('Erreur de rafraîchissement des données:', err));
                        })
                        .catch(err => {
                            this.errorHandler(err);

                            if (this.state.bills && this.state.bills.length > 0) {
                                const revertedBills = this.state.bills.map(b =>
                                    b._id === payload._id ? bill : b
                                );
                                this.setState({ bills: revertedBills });
                            }

                            this.setError({
                                level: 'error',
                                message: `La suppression de la facture a échoué: ${err.message}`,
                                btn1Text: 'OK',
                                btn2hidden: true
                            });
                        });
                });
            }

            if (action === "duplicateInvoice") {
                isAnAction = true;
                axios.post(`${this.getBurl()}/billing/invoices/duplicate`, payload, this.headerAuth())
                    .then((result) => {
                        return resolve(result.data);
                    })
                    .catch((err) => {
                        this.errorHandler(err);
                        return reject(err);
                    });
            }

            if (action === "modifyInvoice") {
                isAnAction = true;
                if (!payload._id) {
                    return reject(new Error('Invoice ID is required for modification'));
                }
                axios.put(`${burl}/billing/invoices/${payload._id}`, payload, this.headerAuth())
                    .then((result) => {
                        // Refresh invoices list after modification
                        this.apiReducer("getInvoices")
                            .then(() => {
                                return resolve(result.data);
                            })
                            .catch((err) => {
                                console.warn("Warning: Could not refresh invoices after modification", err);
                                return resolve(result.data);
                            });
                    })
                    .catch((err) => {
                        this.errorHandler(err);
                        return reject(err);
                    });
            }

            if (action === "validateBill") {
                isAnAction = true;
                axios.put(`${burl}/billing/bills/${payload._id}`, {
                    ...payload,
                    status: 'validated',
                    documentState: 'Facturé',
                    validationDate: new Date().toISOString()
                }, this.headerAuth())
                    .then(async (result) => {
                        // Invalidate cache after successful validation
                        await this.invalidateCache('bills');

                        return resolve(result.data);
                    })
                    .catch((err) => {
                        this.errorHandler(err);
                        return reject(err);
                    });
            }

            if (action === "addCreditNote") {
                isAnAction = true;
                return new Promise((resolve, reject) => {
                    const billData = {
                        ...payload,
                        type: 'avoir',
                        status: 'draft',
                        documentState: 'Brouillon'
                    };
                    axios.post(
                        `${burl}/billing/bills`,
                        billData,
                        this.headerAuth()
                    )
                        .then(response => {
                            resolve(response.data);
                        })
                        .catch(error => {
                            reject(error);
                        });
                });
            }

            if (action === "getCreditNotes") {
                isAnAction = true;
                return new Promise((resolve, reject) => {
                    axios.get(
                        `${burl}/billing/bills?state=Avoir`,
                        this.headerAuth()
                    )
                        .then(response => {
                            resolve(response.data);
                        })
                        .catch(error => {
                            reject(error);
                        });
                });
            }

            if (action === "sendCreditNoteEmail") {
                isAnAction = true;
                return new Promise((resolve, reject) => {
                    axios.post(
                        `${burl}/billing/bills/mail`,
                        payload,
                        this.headerAuth()
                    )
                        .then(response => {
                            resolve(response.data);
                        })
                        .catch(error => {
                            reject(error);
                        });
                });
            }

            //NOT AN ACTION
            if (!isAnAction) {
                return reject(`${action} is not a valid action`);
            }
        });
    }

    componentWillMount() {
        let cookie = Cookies.get('mypacome-PM')
        if (cookie) {
            try {
                cookie = JSON.parse(cookie)
            } catch (e) {
                console.log(e)
                return null
            }
            this.setState({ loading: true })
            this.setUser(cookie.user, cookie.token)
                .then(() => {
                    this.apiReducer('fetchData')
                        .then(() => {
                            this.setState({ loading: false })
                            return null
                        })
                        .catch((e) => {
                            this.setState({ loading: true })
                            return null
                        })
                })
                .catch((err) => {
                    console.log(err)
                    return null
                })
        }
    }

    updateBillSentStatus = (billId) => {
        try {
            if (this.state.bills && this.state.bills.length > 0) {
                const updatedBills = this.state.bills.map(bill => {
                    if (bill._id === billId) {
                        return {
                            ...bill,
                            sent: true,
                            sentByMail: true
                        };
                    }
                    return bill;
                });

                this.setState({
                    bills: updatedBills
                });

                console.log(`Bill ${billId} marked as sent in local state`);
            } else {
                console.log("No bills in state to update");
            }
        } catch (error) {
            console.error("Error updating bill sent status:", error);
        }
    }

    // Add a method to invalidate cache
    invalidateCache = async (cacheKey) => {
        try {
            const burl = this.getBurl();

            // Try to invalidate server cache
            try {
                await axios.post(`${burl}/billing/invalidate-cache`, { key: cacheKey }, this.headerAuth());
            } catch (err) {
                // Continue even if cache invalidation fails
            }

            // Always force a fresh fetch regardless of cache invalidation success
            if (cacheKey === 'bills') {
                try {
                    // Add timestamp to force bypass cache
                    const timestamp = Date.now();
                    const response = await axios.get(`${burl}/billing/bills?_nocache=${timestamp}`, this.headerAuth());

                    if (response.data && response.data.bills) {
                        // Update state with fresh data
                        this.setState(prevState => ({
                            data: {
                                ...prevState.data,
                                bills: response.data.bills
                            }
                        }));
                    }
                } catch (err) {
                    console.error('Failed to refresh bills data:', err);
                }
            } else if (cacheKey === 'invoices') {
                try {
                    // Add timestamp to force bypass cache
                    const timestamp = Date.now();
                    const response = await axios.get(`${burl}/billing/invoices?_nocache=${timestamp}`, this.headerAuth());

                    if (response.data && response.data.invoices) {
                        // Update state with fresh data
                        this.setState(prevState => ({
                            data: {
                                ...prevState.data,
                                invoices: response.data.invoices
                            }
                        }));
                    }
                } catch (err) {
                    console.error('Failed to refresh invoices data:', err);
                }
            }
        } catch (err) {
            console.error(`Error in invalidateCache:`, err);
        }
    };

    render() {
        const { user, token, data, error, loading } = this.state
            , { logout, isLogged, getBurl, apiReducer, setError, findInterventionPeople } = this

        return (
            <DataContext.Provider
                value={
                    {
                        user,
                        token,
                        data,
                        error,
                        loading,
                        apiReducer,
                        logout,
                        isLogged,
                        getBurl,
                        setError,
                        findInterventionPeople
                    }
                }>
                {this.props.children}
            </DataContext.Provider>
        )
    }
}

function errorRefiner(errorString) {
    const errorLongList = errorString.split(',')
    const errorRefinedList = []

    for (const errorLong of errorLongList) {
        const errorShort = errorLong.split(':')
        errorRefinedList.push(errorShort[errorShort.length - 1])
    }
    return errorRefinedList
}


export default DataContext