import React, { useState, useEffect, useRef } from 'react';
import UserContext from '../../../dataContext';  // Remove curly braces - it's a default export
import Spinner from "../../../Components/Spinner";
import ProductPopUpForm from "./PopUp/Products/ProductPopUpForm";
import './PopUp/productsPopUp.scss';

export default function ProductsPopUp({ showProductPopUp, setShowProductPopUp, addProduct, userContext }) {
    const [searchTerm, setSearchTerm] = useState("");
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showNewItemForm, setShowNewItemForm] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const contactPopUp = useRef(null);

    useEffect(() => {
        let mounted = true;

        if (showProductPopUp) {
            setLoading(true);
            userContext.apiReducer("getProducts")
                .then(response => {
                    if (mounted) {
                        // Sort products by code
                        const sortedProducts = response.sort((a, b) => a.code.localeCompare(b.code));
                        setProducts(sortedProducts);
                    }
                })
                .catch(err => {
                    console.error("Error fetching products:", err);
                })
                .finally(() => {
                    if (mounted) {
                        setLoading(false);
                    }
                });
        }

        return () => {
            mounted = false;
        };
    }, [showProductPopUp, userContext]);

    useEffect(() => {
    }, [showProductPopUp])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (showProductPopUp && !event.target.closest('.billing-popup')) {
                setShowProductPopUp(false);
            }
        };

        window.document.addEventListener('mousedown', handleClickOutside);

        return () => {
            window.document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showProductPopUp, setShowProductPopUp]);


    function sortProducts(list, method, key) {
        switch (method) {
            case "abc":
                if (key) {
                    list.sort((a, b) => {
                        if (a[key] < b[key]) return -1;
                        if (a[key] > b[key]) return 1;
                        return 0;
                    });
                }
                break;

            default:
                break;
        }
        return list
    }

    function capitalizeText(text) {
        return text.toLowerCase().replace(/\b\w/g, function (char) {
            return char.toUpperCase();
        });
    }

    const handleCreateProductClick = () => {
        setShowNewItemForm(true);
    }

    const handleProductCreate = (newProduct) => {

        setProducts(prevProducts => [...prevProducts, newProduct]);

        addProduct(newProduct);

        setIsSubmitting(false);
        setSearchTerm("");
        setShowNewItemForm(false);

        setTimeout(() => {
            setShowProductPopUp(false);
        }, 100);
    }

    const handleFormClose = (rep) => {
        setShowNewItemForm(false)
        setIsSubmitting(false)
        setShowProductPopUp(false)
        contactPopUp.current.classList.remove('open')
        addProduct(rep)
    }

    const handleProductSelect = (product) => {
        addProduct({
            ...product,
            taxes: product.taxes || "20",
            quantity: "1",
            price: product.price || "0",
            priceFull: product.priceFull || "0"
        });
        setShowProductPopUp(false);
    };

    if (showNewItemForm) {
        return (
            <div ref={contactPopUp} className={'billing-popup' + (showProductPopUp ? " open" : "")} onClick={(e) => {
                if (e.target.classList.contains('billing-popup')) {
                    handleFormClose()
                }
            }}>
                <div className="container">
                    <h3>{"Ajouter un produit"}</h3>

                    <ProductPopUpForm
                        action="addProduct"
                        userContext={userContext}
                        thing={{ object: {} }}
                        setShowPopUp={setShowNewItemForm}
                        handleProductUpdate={handleProductCreate}
                        setIsSubmitting={setIsSubmitting}
                        isSubmitting={isSubmitting}
                        setProducts={setProducts}
                        products={products}
                        onClose={handleFormClose}
                    />
                    <div className="d-flex justify-content-between mb-3">
                        <button
                            className="btn btn-primary mt-4"
                            onClick={handleFormClose}>
                            Revenir au choix des produits
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    if (loading) return (
        <div ref={contactPopUp} className={'billing-popup' + (showProductPopUp ? " open" : "")} onClick={(e) => { if (e.target.classList.contains('billing-popup')) { e.target.classList.remove('open'); setShowProductPopUp(false); } }}>
            <div className="container">
                <h3>Articles</h3>
                <h2>Chargement des produits...</h2>
                <Spinner strokeWidth={9} width={40} color={"#003952"} />
            </div>
        </div>
    )
    return (
        <div ref={contactPopUp} className={'billing-popup' + (showProductPopUp ? " open" : "")} onClick={(e) => {
            if (e.target.classList.contains('billing-popup')) {
                e.target.classList.remove('open');
                setShowProductPopUp(false);
            }
        }}>
            <div className="container">
                <h3>Articles</h3>

                <div className={"col-12"}>
                    <div className="search">
                        <input type="text" id="formControlLg" className="form-control form-control-lg" placeholder='Rechercher un produit...' onInput={(e) => setSearchTerm(e.target.value)} />
                    </div>

                    <button className="btn btn-primary mt-2" onClick={handleCreateProductClick}>
                        + Ajouter un nouveau produit
                    </button>


                    <table className='table-maxxing'>
                        <thead>
                            <tr>
                                <th scope='col' className="code-column">
                                    <span>Code</span>
                                </th>
                                <th scope='col' className="full">
                                    <span>Libellé</span>
                                </th>
                                <th scope='col' className="less">
                                    <span>Type</span>
                                </th>
                                <th scope='col' className="less">
                                    <span>Famille</span>
                                </th>
                                <th scope='col' className="lesser">
                                    <span>HT (€)</span>
                                </th>
                                <th scope='col' className="lesser">
                                    <span>TTC (€)</span>
                                </th>
                                <th scope='col' className="unit-column">
                                    <span>Unité</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {products?.map((product, index) => {
                                if (!product.code) return null
                                else if (searchTerm && !product.label.toLowerCase().includes(searchTerm.toLowerCase())) return null
                                return (
                                    <tr key={index} onClick={() => handleProductSelect(product)}>
                                        <td data-label="Code" className="code-column">
                                            {product.code?.toString().padStart(8, '0')}
                                        </td>
                                        <td data-label="Libellé" className="full">
                                            {product.label}
                                        </td>
                                        <td data-label="Type" className="less capitalize-text">
                                            {capitalizeText(product.type)}
                                        </td>
                                        <td data-label="Famille" className="less">
                                            {product.family}
                                        </td>
                                        <td data-label="Prix HT (€)" className="lesser text-right">
                                            {product.price}
                                        </td>
                                        <td data-label="Prix TTC (€)" className="lesser text-right">
                                            {product.priceFull}
                                        </td>
                                        <td data-label="Unité" className="unit-column">
                                            {product.quantity}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}