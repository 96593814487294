import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import Spinner from "../../Components/Spinner";
import UserContext from '../../dataContext';
import "../../assets/scss/Billing/invoices.scss";
import "../../assets/scss/Billing/billingPopup.scss";
import "../../assets/scss/Billing/table.scss";
import "../../assets/scss/Billing/billingsPages.scss"
import {
    BsCloudDownloadFill,
    BsFillPencilFill,
    BsSearch,
    BsMailbox,
    BsMailbox2,
    BsFilesAlt,
    BsEye,
    BsArrowRepeat,
    BsFilter,
    BsFileEarmarkTextFill,
    BsX,
    BsEnvelope
} from "react-icons/bs";
import PopUp from './Common/PopUp/PopUp';
import ProductsPopUp from './Common/ProductsPopUp';
import ClientPopUp from './Common/ClientPopUp';
import { PDFDownloadLink, pdf } from '@react-pdf/renderer';
import MyDocument from './PDFs/CommonPDF';
import CustomPDFViewer from './PDFs/PDFViewer';
import ReactDOM from 'react-dom';
import { parseInvoiceDocument } from '../../Utils/documentParser';
import { formatDocument, formatDate, calculateValidityDate } from './utils/formatters';
import { sendDocumentEmail } from './utils/api';
import axios from 'axios';

const parseDocument = parseInvoiceDocument;

const PDFPreviewModal = ({ invoice, onClose }) => {
    // Determine the correct document type and title based on the invoice state
    const isTransitioned = invoice.documentState === 'Facturé';
    const documentType = isTransitioned ? 'bill' : 'invoice';
    const documentTitle = isTransitioned ? 'facture' : 'devis';

    return (
        <div className="modal show d-block" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <div className="modal-dialog modal-xl" style={{ maxWidth: '95vw', height: '90vh' }}>
                <div className="modal-content h-100">
                    <div className="modal-header">
                        <h5 className="modal-title">
                            Aperçu de la {documentTitle} {invoice.code}
                        </h5>
                        <button type="button" className="btn-close" onClick={onClose}></button>
                    </div>
                    <div className="modal-body p-0" style={{ height: 'calc(100% - 60px)' }}>
                        <CustomPDFViewer bill={invoice} type={documentType} />
                    </div>
                </div>
            </div>
        </div>
    );
};

const modalStyles = `
.custom-modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    justify-content: center;
    align-items: center;
}

.custom-modal.show {
    display: flex !important;
    z-index: 9999;
}

.custom-modal-dialog {
    position: relative;
    width: 90%;
    max-width: 600px;
    margin: 1.75rem auto;
    z-index: 10000;
}

.custom-modal-content {
    background-color: white;
    border-radius: 5px;
    width: 100%;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
}

.custom-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #e9ecef;
}

.custom-modal-body {
    padding: 15px;
}

.custom-modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 15px;
    border-top: 1px solid #e9ecef;
    gap: 10px;
}

.custom-modal-close {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.email-preview {
    border: 1px solid #e9ecef;
    padding: 15px;
    margin-top: 10px;
    border-radius: 5px;
    background-color: #f8f9fa;
}

/* Ensure modal is on top of everything */
#email-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    overflow: visible;
    z-index: 10000;
}
`;

const EmailEditorModal = ({ show, onHide, onSend, emailData, setEmailData, isLoading }) => {
    const [isFullyMounted, setIsFullyMounted] = useState(false);
    const modalRef = useRef(null);

    const [localEmailData, setLocalEmailData] = useState({
        subject: '',
        to: '',
        html: '',
        includeTerms: false,
        user: '',
        role: ''
    });

    useEffect(() => {
        if (emailData && show) {
            setLocalEmailData({
                subject: emailData.subject || '',
                to: emailData.to || '',
                html: emailData.html || '',
                includeTerms: emailData.includeTerms || false,
                user: emailData.user || '',
                role: emailData.role || ''
            });
        }
    }, [emailData, show]);

    // Update HTML content when user or role changes
    useEffect(() => {
        if (emailData && emailData.generateHtmlContent && localEmailData.user && localEmailData.role) {
            // Only update if we have the generator function and both user and role
            const newHtml = emailData.generateHtmlContent(localEmailData.user, localEmailData.role);
            if (newHtml !== localEmailData.html) {
                setLocalEmailData(prev => ({
                    ...prev,
                    html: newHtml
                }));
            }
        }
    }, [localEmailData.user, localEmailData.role, emailData]);

    useEffect(() => {
        if (show) {
            setIsFullyMounted(false);
            // Set a timeout to mark the modal as fully mounted
            const mountTimer = setTimeout(() => {
                setIsFullyMounted(true);
            }, 300);
            return () => clearTimeout(mountTimer);
        }
    }, [show]);

    const handleClose = () => {
        if (onHide) {
            onHide();
        }
    };

    const handleSend = () => {
        // Update the parent's emailData with our local changes
        if (setEmailData) {
            setEmailData({
                ...emailData,
                to: localEmailData.to,
                subject: localEmailData.subject,
                html: localEmailData.html,
                includeTerms: localEmailData.includeTerms,
                user: localEmailData.user,
                role: localEmailData.role
            });
        }
        // Call the onSend callback
        if (onSend) {
            onSend();
        }
    };

    const handleInputChange = (field, value) => {
        setLocalEmailData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const generateHtmlContent = () => {
        if (emailData && emailData.generateHtmlContent) {
            return emailData.generateHtmlContent(localEmailData.user, localEmailData.role);
        }
        return '';
    };

    const handleOutsideClick = (e) => {
        if (isFullyMounted && modalRef.current && !modalRef.current.contains(e.target)) {
            handleClose();
        }
    };

    const handleRegenerateHtml = () => {
        if (emailData && emailData.generateHtmlContent) {
            const newHtml = emailData.generateHtmlContent(localEmailData.user, localEmailData.role);
            setLocalEmailData(prev => ({
                ...prev,
                html: newHtml
            }));
        }
    };

    if (!show) return null;

    return (
        <div
            className={`custom-modal ${show ? 'show' : ''}`}
            onClick={handleOutsideClick}
        >
            <div className="custom-modal-dialog" ref={modalRef}>
                <div className="custom-modal-content">
                    <div className="custom-modal-header">
                        <h5 className="modal-title">Envoyer par email</h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={handleClose}
                            aria-label="Close"
                        >
                            <BsX />
                        </button>
                    </div>
                    <div className="custom-modal-body">
                        <div className="mb-3">
                            <label htmlFor="emailTo" className="form-label">Destinataire</label>
                            <input
                                type="email"
                                className="form-control"
                                id="emailTo"
                                value={localEmailData.to}
                                onChange={(e) => handleInputChange('to', e.target.value)}
                                placeholder="email@example.com"
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="emailSubject" className="form-label">Sujet</label>
                            <input
                                type="text"
                                className="form-control"
                                id="emailSubject"
                                value={localEmailData.subject}
                                onChange={(e) => handleInputChange('subject', e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="emailUser" className="form-label">Votre nom</label>
                            <input
                                type="text"
                                className="form-control"
                                id="emailUser"
                                value={localEmailData.user}
                                onChange={(e) => handleInputChange('user', e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="emailRole" className="form-label">Votre rôle</label>
                            <input
                                type="text"
                                className="form-control"
                                id="emailRole"
                                value={localEmailData.role}
                                onChange={(e) => handleInputChange('role', e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Contenu de l'email</label>
                            {/* <div className="d-flex justify-content-end mb-2">
                                <button
                                    className="btn btn-sm btn-outline-secondary"
                                    onClick={handleRegenerateHtml}
                                >
                                    Régénérer
                                </button>
                            </div> */}
                            <div
                                className="form-control"
                                style={{
                                    height: '300px',
                                    overflow: 'auto',
                                    backgroundColor: '#f8f9fa',
                                    padding: '15px'
                                }}
                            >
                                <div dangerouslySetInnerHTML={{ __html: localEmailData.html }} />
                            </div>
                        </div>
                        <div className="form-check mt-3">
                            <input
                                type="hidden"
                                className="form-check-input"
                                id="includeTerms"
                                checked={false}
                            />

                        </div>
                    </div>
                    <div className="custom-modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={handleClose}
                        >
                            Annuler
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSend}
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <>
                                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                    Envoi en cours...
                                </>
                            ) : 'Envoyer'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Invoices = () => {
    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(true);
    const userContext = useContext(UserContext);
    const [search, setSearch] = useState("");
    const [showPopUp, setShowPopUp] = useState(false);
    const [action, setAction] = useState("");
    const [popUpContent, setPopUpContent] = useState({});
    const [contacts, setContacts] = useState([]);
    const [showClientPopUp, setShowClientPopUp] = useState(false);
    const [clientId, setClientId] = useState("");
    const [reference, setReference] = useState("");
    const [showProductPopUp, setShowProductPopUp] = useState(false);
    const [document, setDocument] = useState([]);
    const [siret, setSiret] = useState("");
    const [payingMethod, setPayingMethod] = useState("");
    const [address, setAddress] = useState("");
    const [fullAddress, setFullAddress] = useState("");
    const [fullSecondAddress, setFullSecondAddress] = useState("");
    const [billingAddress, setBillingAddress] = useState("");
    const [isTheSameAsFullAddress, setIsTheSameAsFullAddress] = useState(false);
    const [clientName, setClientName] = useState("");
    const [clientMail, setClientMail] = useState("");
    const [fullName, setFullName] = useState("");
    const [previewInvoice, setPreviewInvoice] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState({
        documentState: "",
        clientId: "",
        dateRange: {
            start: "",
            end: ""
        }
    });
    const [clientSearch, setClientSearch] = useState("");
    const [showEmailEditor, setShowEmailEditor] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [emailData, setEmailData] = useState({
        email: '',
        subject: '',
        htmlContent: '',
        includeTerms: true
    });

    const ITEMS_PER_PAGE = 15; // Same as in Bills.js

    const handleModif = async (invoiceData, action) => {
        try {
            if (!action) {
                console.error('Action is undefined in handleModif. Invoice data:', invoiceData);
                throw new Error('Action is required for modification. Please check the console for more details.');
            }

            if (!invoiceData._id && action !== 'addInvoice') {
                throw new Error('Invoice ID is required for modification');
            }

            // Ensure document is properly formatted before sending
            const formattedInvoice = {
                ...invoiceData,
                document: Array.isArray(invoiceData.document)
                    ? invoiceData.document
                    : typeof invoiceData.document === 'string'
                        ? JSON.parse(invoiceData.document)
                        : []
            };

            console.log('Attempting to modify invoice:', { id: formattedInvoice._id, action });
            const result = await userContext.apiReducer(action, formattedInvoice);

            if (result) {
                // Update the invoices list
                if (action === 'addInvoice') {
                    fetchInvoices();
                } else {
                    setInvoices(prevInvoices =>
                        prevInvoices.map(invoice =>
                            invoice._id === result._id ? result : invoice
                        )
                    );
                }
                setShowPopUp(false);
                return result;
            }
        } catch (err) {
            console.error('Error in handleModif:', err);
            userContext.setError({
                level: 'warning',
                message: 'Erreur lors de la modification : ' + (err.message || String(err)),
                btn1Text: 'Fermer',
                btn2hidden: true
            });
            throw err;
        }
    };

    const fetchInvoices = async () => {
        try {
            setLoading(true);
            const response = await userContext.apiReducer("getInvoices");
            if (Array.isArray(response)) {
                setInvoices(response.reverse() || []);
            } else {
                console.error("Invalid response format:", response);
                setInvoices([]);
            }
        } catch (error) {
            console.error("Error fetching invoices:", error);
            setInvoices([]);
        } finally {
            setLoading(false);
        }
    };

    const onUpdate = () => {
        fetchInvoices();
    };

    const contactPopUp = (invoice) => {
        if (!invoice) return;
        setClientId(invoice.client_id);
        setShowClientPopUp(true);
    };

    const addProduct = (product) => {
        if (!product) return;
        const newProduct = {
            ...product,
            quantity: 1,
            _id: product._id || product.code,
            totalHT: parseFloat(product.price || 0),
            totalTTC: parseFloat(product.priceFull || 0),
        };
        setDocument(prev => [...prev, newProduct]);
    };

    useEffect(() => {
        let mounted = true;
        const abortController = new AbortController();

        const fetchData = async () => {
            try {
                const response = await userContext.apiReducer("getInvoices", null, { signal: abortController.signal });
                if (mounted) {
                    setInvoices(response?.reverse() || []);
                    setLoading(false);
                }
            } catch (err) {
                if (err.name === 'AbortError') {
                    console.log('Fetch aborted');
                    return;
                }
                console.error(err);
                if (mounted) {
                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            mounted = false;
            abortController.abort();
        };
    }, [userContext]);

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const response = await userContext.apiReducer("getRealClients");
                setContacts(response.clients);
            } catch (err) {
                console.error(err);
            }
        };

        if (contacts.length === 0) {
            fetchClients();
        }
    }, [userContext, contacts.length]);

    // Calculate paginated invoices with enhanced filtering
    const filteredInvoices = invoices?.filter(invoice => {
        // Basic search filter (existing functionality)
        const matchesSearch = !search ||
            (invoice.code?.toLowerCase().includes(search.toLowerCase()) ||
                invoice.fullName?.toLowerCase().includes(search.toLowerCase()) ||
                invoice.reference?.toLowerCase().includes(search.toLowerCase()));

        if (!matchesSearch) return false;

        // Document state filter
        if (filters.documentState && invoice.documentState !== filters.documentState) {
            return false;
        }

        // Client filter
        if (filters.clientId && invoice.clientId !== filters.clientId) {
            return false;
        }

        // Date range filter
        if (filters.dateRange.start || filters.dateRange.end) {
            const invoiceDate = new Date(invoice.date);

            if (filters.dateRange.start) {
                const startDate = new Date(filters.dateRange.start);
                if (invoiceDate < startDate) return false;
            }

            if (filters.dateRange.end) {
                const endDate = new Date(filters.dateRange.end);
                endDate.setHours(23, 59, 59, 999); // End of the day
                if (invoiceDate > endDate) return false;
            }
        }

        return true;
    });

    const totalPages = Math.ceil(filteredInvoices.length / ITEMS_PER_PAGE);
    const paginatedInvoices = filteredInvoices.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    );

    // Reset filters function
    const resetFilters = () => {
        setFilters({
            documentState: "",
            clientId: "",
            dateRange: {
                start: "",
                end: ""
            }
        });
        setClientSearch("");
    };

    // Get unique clients from invoices
    const uniqueClients = useMemo(() => {
        const clientMap = new Map();

        invoices.forEach(invoice => {
            if (invoice.clientId && invoice.fullName) {
                clientMap.set(invoice.clientId, {
                    id: invoice.clientId,
                    name: invoice.fullName
                });
            }
        });

        return Array.from(clientMap.values());
    }, [invoices]);

    // Filter clients based on search
    const filteredClients = useMemo(() => {
        return uniqueClients.filter(client =>
            !clientSearch ||
            client.name.toLowerCase().includes(clientSearch.toLowerCase())
        );
    }, [uniqueClients, clientSearch]);

    if (loading) {
        return (
            <div className={"col cardsContainer mx-1 p-2 mt-3 mt-md-0"}>
                <div className={"row justify-content-between"}>
                    <div>
                        <h1 className="blue fw-bold text-center">Devis</h1>
                    </div>
                </div>
                <Spinner strokeWidth={9} width={40} color={"#003952"} />
                <h2 className="text-center">Chargement des devis...</h2>
            </div>
        );
    } else {
        return (
            <div className={"col cardsContainer mx-1 p-2 mt-3 mt-md-0 invoices"}>
                {/* Add hidden download anchor */}
                <a id="hidden-download" style={{ display: 'none' }} />

                <div className={"row justify-content-between"}>
                    <div>
                        <h1 className="blue fw-bold text-center">Devis</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="search-and-stuff d-flex justify-content-between align-items-center gap-2 position-relative">
                        <div className="search w-50 mx-auto">
                            <input
                                type="text"
                                id="formControlLg"
                                className="form-control form-control-lg"
                                placeholder='Rechercher un devis...'
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                        <div className="d-flex position-absolute end-0 me-3">
                            <button
                                className="btn btn-primary nice-button mx-2"
                                onClick={() => setShowFilters(!showFilters)}
                                title="Filtres avancés"
                            >
                                <BsFilter className="me-1" /> Filtres
                            </button>
                            <button
                                className="btn btn-primary nice-button"
                                onClick={() => {
                                    setShowPopUp(false);
                                    setTimeout(() => {
                                        setAction("addInvoice");
                                        setPopUpContent({ what: { type: "Devis", title: "Créer un devis" }, thing: { object: {} } });
                                        setShowPopUp(true);
                                    }, 0);
                                }}
                            >
                                +
                            </button>
                        </div>
                    </div>
                </div>

                {/* Filters Panel */}
                {showFilters && (
                    <div className="filters-panel mt-3 p-3 border rounded bg-light">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-between mb-3">
                                <h5>Filtres avancés</h5>
                                <button
                                    className="btn btn-sm btn-outline-secondary"
                                    onClick={resetFilters}
                                >
                                    Réinitialiser
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 mb-3">
                                <label className="form-label">État du document</label>
                                <select
                                    className="form-select"
                                    value={filters.documentState}
                                    onChange={(e) => setFilters({ ...filters, documentState: e.target.value })}
                                >
                                    <option value="">Tous</option>
                                    <option value="Brouillon">Brouillon</option>
                                    <option value="Accepté">Accepté</option>
                                    <option value="Facturé">Facturé</option>
                                </select>
                            </div>
                            <div className="col-md-3 mb-3">
                                <label className="form-label">Client</label>
                                <div className="position-relative">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={clientSearch}
                                        onChange={(e) => setClientSearch(e.target.value)}
                                        placeholder="Rechercher un client..."
                                    />
                                    {clientSearch && filteredClients.length > 0 && (
                                        <div className="position-absolute w-100 mt-1 bg-white border rounded shadow-sm z-index-dropdown" style={{ maxHeight: '200px', overflowY: 'auto', zIndex: 1000 }}>
                                            <div className="list-group">
                                                {filteredClients.map(client => (
                                                    <button
                                                        key={client.id}
                                                        className={`list-group-item list-group-item-action ${filters.clientId === client.id ? 'active' : ''}`}
                                                        onClick={() => {
                                                            setFilters({
                                                                ...filters,
                                                                clientId: client.id
                                                            });
                                                            setClientSearch(client.name);
                                                        }}
                                                    >
                                                        {client.name}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Plage de dates</label>
                                <div className="d-flex gap-2">
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={filters.dateRange.start}
                                        onChange={(e) => setFilters({
                                            ...filters,
                                            dateRange: { ...filters.dateRange, start: e.target.value }
                                        })}
                                        placeholder="Date de début"
                                    />
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={filters.dateRange.end}
                                        onChange={(e) => setFilters({
                                            ...filters,
                                            dateRange: { ...filters.dateRange, end: e.target.value }
                                        })}
                                        placeholder="Date de fin"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 d-flex justify-content-end">
                                <button
                                    className="btn btn-primary"
                                    onClick={() => setShowFilters(false)}
                                >
                                    Appliquer les filtres
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {paginatedInvoices.length > 0 ? (
                    <>
                        <table className='table-maxxing'>
                            <thead>
                                <tr>
                                    <th scope="col"><span>État</span></th>
                                    <th scope="col"><span>Numéro</span></th>
                                    <th scope="col"><span>Date</span></th>
                                    <th scope="col"><span>Validité</span></th>
                                    <th scope="col"><span>Client</span></th>
                                    <th scope="col"><span>Référence</span></th>
                                    <th scope="col"><span>Montant HT</span></th>
                                    <th scope="col"><span>Montant TTC</span></th>
                                    <th scope="col"><span>Actions</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedInvoices.map((invoice, index) => (
                                    <InvoiceSingle
                                        key={invoice._id || index}
                                        invoice={invoice}
                                        setInvoices={setInvoices}
                                        setPopUpContent={setPopUpContent}
                                        setAction={setAction}
                                        formatDate={formatDate}
                                        setShowPopUp={setShowPopUp}
                                        setPreviewInvoice={setPreviewInvoice}
                                        onUpdate={onUpdate}
                                        userContext={userContext}
                                    />
                                ))}
                            </tbody>
                        </table>

                        {/* Pagination Controls */}
                        <div className="pagination d-flex justify-content-center align-items-center gap-2 mt-3">
                            <button
                                className="btn btn-primary"
                                disabled={currentPage === 1}
                                onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
                            >
                                Précédent
                            </button>
                            <span>Page {currentPage} sur {totalPages || 1}</span>
                            <button
                                className="btn btn-primary"
                                disabled={currentPage === totalPages || totalPages === 0}
                                onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
                            >
                                Suivant
                            </button>
                        </div>
                    </>
                ) : (
                    <div className="text-center mt-5">
                        <h3>Aucun devis trouvé</h3>
                        {search && <p>Essayez de modifier votre recherche</p>}
                        {!search && invoices.length === 0 && (
                            <div>
                                <p>Aucun devis n'est disponible dans le système.</p>
                                <button
                                    className="btn btn-primary mt-3"
                                    onClick={() => {
                                        setShowPopUp(true);
                                        setAction("addInvoice");
                                        setPopUpContent({ what: { type: "Devis", title: "Créer un devis" }, thing: { object: {} } });
                                    }}
                                >
                                    Créer votre premier devis
                                </button>
                                <button
                                    className="btn btn-secondary mt-3 ms-2"
                                    onClick={() => fetchInvoices()}
                                >
                                    Rafraîchir les données
                                </button>
                            </div>
                        )}
                    </div>
                )}

                {/* Preview Modal */}
                {previewInvoice && (
                    <PDFPreviewModal
                        invoice={previewInvoice}
                        onClose={() => setPreviewInvoice(null)}
                    />
                )}

                {/* PopUps */}
                <PopUp
                    showPopUp={showPopUp}
                    popUpContent={popUpContent}
                    setShowPopUp={setShowPopUp}
                    userContext={userContext}
                    handleModif={handleModif}
                    formatDate={(date, format) => {
                        if (!date) return '';
                        const d = new Date(date);
                        if (format === 2) {
                            return d.toISOString().split('T')[0]; // Returns YYYY-MM-DD
                        }
                        return d.toLocaleDateString('fr-FR');
                    }}
                    setShowClientPopUp={setShowClientPopUp}
                    clientId={clientId}
                    action={action}
                    setShowProductPopUp={setShowProductPopUp}
                    document={document}
                    setDocument={setDocument}
                    billContents={document}
                    setBillContents={setDocument}
                    showProductPopUp={showProductPopUp}
                    reference={reference}
                    setReference={setReference}
                    siret={siret}
                    setSiret={setSiret}
                    address={address}
                    setAddress={setAddress}
                    type={"invoice"}
                    payingMethod={payingMethod}
                    setPayingMethod={setPayingMethod}
                    fullAddress={fullAddress}
                    setFullAddress={setFullAddress}
                    fullSecondAddress={fullSecondAddress}
                    setFullSecondAddress={setFullSecondAddress}
                    billingAddress={billingAddress}
                    setBillingAddress={setBillingAddress}
                    isTheSameAsFullAddress={isTheSameAsFullAddress}
                    setIsTheSameAsFullAddress={setIsTheSameAsFullAddress}
                    clientMail={clientMail}
                    setClientMail={setClientMail}
                    fullName={fullName}
                    setFullName={setFullName}
                    setClientId={setClientId}
                    onUpdate={onUpdate}
                />

                <ProductsPopUp
                    showProductPopUp={showProductPopUp}
                    setShowProductPopUp={setShowProductPopUp}
                    addProduct={addProduct}
                    userContext={userContext}
                />

                <ClientPopUp
                    showClientPopUp={showClientPopUp}
                    setShowClientPopUp={setShowClientPopUp}
                    contacts={contacts}
                    setSiret={setSiret}
                    setFullAddress={setFullAddress}
                    setClientId={setClientId}
                    payingMethod={payingMethod}
                    setPayingMethod={setPayingMethod}
                    setFullSecondAddress={setFullSecondAddress}
                    setBillingAddress={setBillingAddress}
                    setIsTheSameAsFullAddress={setIsTheSameAsFullAddress}
                    setClientMail={setClientMail}
                    setFullName={setFullName}
                    userContext={userContext}
                />

                {/* Add the email editor modal */}
                <style>{modalStyles}</style>
                <EmailEditorModal
                    show={showEmailEditor}
                    onHide={() => setShowEmailEditor(false)}
                    onSend={() => {
                        // Implementation of sendEmail function
                    }}
                    emailData={emailData}
                    setEmailData={setEmailData}
                    isLoading={false}
                />
            </div>
        );
    }
};

const InvoiceSingle = ({ invoice, setInvoices, setPopUpContent, setAction, formatDate, setShowPopUp, setPreviewInvoice, onUpdate, userContext }) => {
    const [isPDFLoading, setIsPDFLoading] = useState(false);
    const [showPDF, setShowPDF] = useState(false);
    const [isEmailSending, setIsEmailSending] = useState(false);
    const [isEmailPreparing, setIsEmailPreparing] = useState(false);
    const [isDuplicating, setIsDuplicating] = useState(false);
    const [showEmailEditor, setShowEmailEditor] = useState(false);
    const [emailSent, setEmailSent] = useState(invoice?.sentByMail || false);
    const [emailData, setEmailData] = useState({
        email: '',
        subject: '',
        htmlContent: '',
        includeTerms: true
    });

    // Determine document type based on state
    const isTransitioned = invoice.documentState === 'Facturé';
    const documentType = isTransitioned ? 'bill' : 'invoice';
    const documentTitle = isTransitioned ? 'Facture' : 'Devis';

    const handleFormattedInvoice = (invoice) => {
        return {
            ...invoice,
            document: Array.isArray(invoice.document)
                ? invoice.document
                : typeof invoice.document === 'string'
                    ? JSON.parse(invoice.document)
                    : [],
            reduction: invoice.reduction || {},
            totals: invoice.totals || {
                rawTotal: 0,
                taxesTotal: 0,
                totalPreTax: 0,
                total: 0
            }
        };
    };

    const handleEdit = () => {
        const formattedInvoice = handleFormattedInvoice(invoice);

        // Ensure we set a valid action
        const editAction = invoice.documentState === "Facturé" ? "viewInvoice" : "modifyInvoice";

        setAction(editAction);
        setPopUpContent({
            what: {
                type: "Devis",
                title: invoice.documentState === "Facturé" ? "Voir le devis" : "Modifier le devis"
            },
            thing: {
                object: formattedInvoice
            }
        });
        setShowPopUp(true);
    };

    const handlePreview = (e) => {
        e.preventDefault();
        e.stopPropagation();

        // Create a copy of the invoice with the correct document type for preview
        const previewData = {
            ...invoice,
            // If the invoice has been transitioned to a bill, ensure it shows as a bill in the preview
            _previewType: invoice.documentState === 'Facturé' ? 'bill' : 'invoice'
        };

        setPreviewInvoice(previewData);
    };

    const handleDownload = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsPDFLoading(true);
        setShowPDF(true);
    };

    const handleDuplicate = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        // Set duplicating state to true to show spinner
        setIsDuplicating(true);

        try {
            // Use the duplicateInvoice action
            const response = await userContext.apiReducer("duplicateInvoice", invoice);

            if (response) {
                // After successful duplication, open the popup with the duplicated invoice
                setPopUpContent({
                    what: { type: "Devis", title: "Modifier le devis" },
                    thing: { object: response }
                });
                setAction("modifyInvoice");
                setShowPopUp(true);

                // Also update the invoices list
                if (typeof onUpdate === 'function') {
                    onUpdate();
                }
            }
        } catch (error) {
            console.error("Erreur lors de la duplication :", error);
            userContext.setError({
                level: 'warning',
                message: `Échec de la duplication : ${error.message || 'Erreur inconnue'}`,
                btn1Text: 'Fermer',
                btn2hidden: true
            });
        } finally {
            // Reset duplicating state
            setIsDuplicating(false);
        }
    };

    const handleCreateBill = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (invoice.documentState !== 'Accepté') {
            userContext.setError({
                level: 'warning',
                message: 'Le devis doit être accepté avant de pouvoir créer une facture',
                btn1Text: 'Fermer',
                btn2hidden: true
            });
            return;
        }

        userContext.setError({
            level: 'prompt',
            message: `Voulez-vous créer une facture à partir du devis ${invoice.code} ?`,
            btn1Text: 'Créer',
            btn2Text: 'Annuler',
            btn1Action: async () => {
                try {
                    setIsDuplicating(true);
                    console.log("Starting invoice transition for:", invoice._id);

                    // Call the API to create a bill from the invoice
                    const response = await userContext.apiReducer("invoiceTransition", {
                        _id: invoice._id,
                        date: invoice.date,
                        validity: invoice.validity
                    });
                    console.log("Transition response:", response);

                    if (response && response.invoice && response.bill) {
                        console.log("Successfully created bill:", response.bill.code);

                        // Show success message
                        userContext.setError({
                            level: 'success',
                            message: `Facture ${response.bill.code} créée avec succès à partir du devis ${response.invoice.code}`,
                            btn1Text: 'Fermer',
                            btn2hidden: true
                        });

                        // Refresh the invoices list to show updated status
                        if (typeof onUpdate === 'function') {
                            onUpdate();
                        }
                    } else {
                        console.error("Invalid response format:", response);
                        userContext.setError({
                            level: 'error',
                            message: 'Erreur lors de la création de la facture: Format de réponse invalide',
                            btn1Text: 'Fermer',
                            btn2hidden: true
                        });
                    }
                } catch (error) {
                    console.error("Error creating bill:", error);
                    userContext.setError({
                        level: 'error',
                        message: `Erreur lors de la création de la facture: ${error.message || error}`,
                        btn1Text: 'Fermer',
                        btn2hidden: true
                    });
                } finally {
                    setIsDuplicating(false);
                }
            }
        });
    };

    const generateDefaultEmailContent = async (emailAddress) => {
        // Get document type and other details
        const documentType = "devis";
        const documentTypeCapitalized = "Devis";
        const billCode = invoice.code || '';
        const billReference = invoice.reference || '';
        const billDate = formatDate(invoice.date);
        const billTotalHT = invoice.totals?.rawTotal.toFixed(2) || '0.00';
        const billTotalTTC = invoice.totals?.total.toFixed(2) || '0.00';

        // Get client information for personalized greeting
        let clientCivility = '';
        let lastName = '';
        let isProfessional = false;

        try {
            if (invoice.clientId) {
                const clientResponse = await userContext.apiReducer("getClient", { id: invoice.clientId });
                if (clientResponse) {
                    clientCivility = clientResponse.civility || '';
                    lastName = clientResponse.lastName || '';
                    isProfessional = clientResponse.isProfessional || false;
                }
            }
        } catch (error) {
            console.error('Error fetching client data:', error);
        }

        if (!lastName && invoice.fullName) {
            const nameParts = invoice.fullName.trim().split(' ');
            if (nameParts.length > 1) {
                lastName = nameParts[nameParts.length - 1];
            }
        }

        const greeting = isProfessional
            ? `Bonjour,`
            : clientCivility && lastName
                ? `Bonjour ${clientCivility} ${lastName},`
                : clientCivility
                    ? `Bonjour ${clientCivility},`
                    : `Bonjour,`;

        const subject = `${documentTypeCapitalized} ${billCode}${billReference ? ` - ${billReference}` : ''}`;
        const user = userContext.user.name + ' ' + userContext.user.lastName;
        const role = "Responsable d'agence MYPacôme";

        // Create a function to generate the HTML content with dynamic signature
        const generateHtmlContent = (userName, userRole) => {
            return `
                <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto;">
                    <div style="background-color: #f8f9fa; padding: 20px; border-radius: 5px; margin-bottom: 20px;">
                        <h2 style="color: #2c3e50; margin-top: 0;">${greeting}</h2>
                        
                        <p style="color: #34495e;">Veuillez trouver ci-joint votre ${documentType} avec les détails suivants :</p>
                    </div>

                    <div style="background-color: #ffffff; border: 1px solid #e9ecef; border-radius: 5px; padding: 20px; margin-bottom: 20px;">
                        <table style="width: 100%; border-collapse: collapse;">
                            <tr>
                                <td style="padding: 8px; color: #2c3e50;"><strong>Numéro</strong></td>
                                <td style="padding: 8px; color: #34495e;">${billCode}</td>
                            </tr>
                            <tr>
                                <td style="padding: 8px; color: #2c3e50;"><strong>Date</strong></td>
                                <td style="padding: 8px; color: #34495e;">${billDate}</td>
                            </tr>
                            <tr>
                                <td style="padding: 8px; color: #2c3e50;"><strong>Total HT</strong></td>
                                <td style="padding: 8px; color: #34495e;">${billTotalHT}€</td>
                            </tr>
                            <tr>
                                <td style="padding: 8px; color: #2c3e50;"><strong>Total TTC</strong></td>
                                <td style="padding: 8px; color: #34495e;">${billTotalTTC}€</td>
                            </tr>
                        </table>
                    </div>

                    <div style="margin-bottom: 20px; color: #34495e;">
                        <p>Pour toute question concernant ce ${documentType}, n'hésitez pas à nous contacter.</p>
                        <p>Cordialement,</p>
                    </div>

                    <div style="color: #7f8c8d; font-size: 14px; border-top: 1px solid #e9ecef; padding-top: 20px;">
                        <p><strong>${userName || user}</strong><br>${userRole || role}</p>
                    </div>
                </div>
            `;
        };

        return {
            to: emailAddress,
            subject: subject,
            html: generateHtmlContent(user, role),
            includeTerms: true,
            billCode: billCode,
            billReference: billReference,
            documentType: documentType,
            user: user,
            role: role,
            generateHtmlContent: (userName, userRole) => generateHtmlContent(userName, userRole)
        };
    };

    const handleEmailSend = async (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        setIsEmailPreparing(true);

        try {
            const emailToUse = invoice.clientMail || ''
            const defaultEmailData = await generateDefaultEmailContent(emailToUse);

            defaultEmailData._isEmailPreparing = true;

            setEmailData(defaultEmailData);

            setTimeout(() => {
                setShowEmailEditor(true);

                setTimeout(() => {
                    setEmailData(prev => ({
                        ...prev,
                        _isEmailPreparing: false
                    }));
                    setIsEmailPreparing(false);
                }, 1000);
            }, 100);
        } catch (error) {
            console.error("Error preparing email:", error);
            userContext.setError({
                level: 'error',
                message: 'Erreur lors de la préparation de l\'email',
                btn1Text: 'Fermer',
                btn2hidden: true
            });

            setShowEmailEditor(false);
        } finally {
            setTimeout(() => {
                setIsEmailPreparing(false);
                setEmailData(prev => ({
                    ...prev,
                    _isEmailPreparing: false
                }));
            }, 1000);
        }
    };

    const sendEmail = () => {
        let requestInProgress = true;

        setIsEmailSending(true);

        generatePDF()
            .then(base64data => {
                // Prepare payload
                const payload = {
                    invoiceId: invoice._id,
                    email: emailData.to,
                    pdfAttachment: base64data,
                    includeTerms: emailData.includeTerms,
                    emailHtml: emailData.html
                };

                window.lastEmailApiCallTime = new Date().toISOString();
                console.log(`Starting email API call for invoice ${invoice._id} at ${window.lastEmailApiCallTime}`);

                // Return the API call promise without modifying state yet
                return userContext.apiReducer("sendInvoiceEmail", payload);
            })
            .then(response => {
                requestInProgress = false;
                console.log(`Email API response received for invoice ${invoice._id}:`, response);

                // Dispatch a custom event that will be caught by the event listener
                const emailSentEvent = new CustomEvent('EMAIL_SENT', {
                    detail: { invoiceId: invoice._id }
                });
                window.dispatchEvent(emailSentEvent);

                setShowEmailEditor(false);
                setIsEmailSending(false);

                // Success message is now handled by the EMAIL_SENT event handler
            })
            .catch(error => {
                console.error(`Error sending email for invoice ${invoice._id}:`, error);
                requestInProgress = false;

                setIsEmailSending(false);
                setShowEmailEditor(false);

                userContext.setError({
                    level: 'error',
                    message: "Une erreur est survenue lors de l'envoi de l'email: " + (error.message || "Erreur inconnue"),
                    btn1Text: 'Fermer',
                    btn2hidden: true
                });
            });
    };

    const resetEmailStates = () => {
        setIsEmailSending(false);
        setIsEmailPreparing(false);
        setShowEmailEditor(false);
        setEmailData({
            email: '',
            subject: '',
            htmlContent: '',
            includeTerms: true
        });
    };

    /**
     * Generates a PDF for the invoice
     * @returns {Promise<string>} Base64 encoded PDF
     */
    const generatePDF = async () => {
        try {
            // Create a blob from the PDF component
            const blob = await pdf(<MyDocument bill={invoice} type={documentType} />).toBlob();

            // Convert blob to base64
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    // Get base64 string (remove the data:application/pdf;base64, prefix)
                    const base64data = reader.result.split(',')[1];
                    resolve(base64data);
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        } catch (error) {
            console.error("Error generating PDF:", error);
            throw error;
        }
    };

    useEffect(() => {
        const handleEmailSent = (event) => {
            // Only process the event if it's for this specific invoice
            if (event.detail && event.detail.invoiceId === invoice._id) {
                console.log(`Email sent event received for invoice ${invoice._id}`);

                setShowEmailEditor(false);
                setIsEmailSending(false);

                if (invoice) {
                    invoice.sentByMail = true;
                    setEmailSent(true);
                }

                userContext.setError({
                    level: "success",
                    message: "Email envoyé avec succès!",
                    btn1Text: 'OK',
                    btn2hidden: true
                });

                // Call onUpdate to refresh the invoices list if needed
                if (typeof onUpdate === 'function') {
                    onUpdate();
                }
            }
        };

        window.addEventListener('EMAIL_SENT', handleEmailSent);

        return () => {
            window.removeEventListener('EMAIL_SENT', handleEmailSent);
        };
    }, [invoice, userContext, onUpdate]);

    const getSanitizedFileName = (invoice) => {
        const sanitizedName = invoice.fullName ? invoice.fullName.replace(/[^a-zA-Z0-9]/g, '_') : '';
        const docType = invoice.documentState === 'Facturé' ? 'Facture' : 'Devis';

        return `${sanitizedName}_${docType}_${invoice.code}.pdf`;
    };

    return (
        <>
            <tr>
                <td>
                    <span className={`badge ${invoice.documentState === "Brouillon" ? "bg-brouillon" :
                        invoice.documentState === "Facturé" ? "bg-success" :
                            invoice.documentState === "Accepté" ? "bg-primary" : "bg-secondary"}`}>
                        {invoice.documentState}
                    </span>
                </td>
                <td>{invoice.code}</td>
                <td>{formatDate(invoice.date)}</td>
                <td>{formatDate(invoice.validity)}</td>
                <td>{invoice.fullName}</td>
                <td>{invoice.reference}</td>
                <td>{invoice.totals?.rawTotal.toFixed(2)}€</td>
                <td>{invoice.totals?.total.toFixed(2)}€</td>
                <td>
                    <div className="d-flex justify-content-end">
                        <button
                            className="btn btn-sm btn-outline-primary mx-1"
                            title="Prévisualiser le devis"
                            onClick={handlePreview}
                        >
                            <BsEye className="billsActionsIcon" />
                        </button>
                        {invoice.documentState === "Facturé" ? (
                            <button
                                className="btn btn-sm btn-outline-primary mx-1"
                                title="Voir les détails du devis"
                                onClick={handleEdit}
                            >
                                <BsSearch className="billsActionsIcon" />
                            </button>
                        ) : (
                            <button
                                className="btn btn-sm btn-outline-primary mx-1"
                                title="Modifier le devis"
                                onClick={handleEdit}
                            >
                                <BsFillPencilFill className="billsActionsIcon" />
                            </button>
                        )}
                        <button
                            className="btn btn-sm btn-outline-primary mx-1"
                            title="Télécharger le devis en PDF"
                            onClick={handleDownload}
                        >
                            <BsCloudDownloadFill className="billsActionsIcon" />
                        </button>
                        <button
                            className="btn btn-sm btn-outline-primary mx-1"
                            title="Dupliquer le devis"
                            onClick={handleDuplicate}
                            disabled={isDuplicating}
                        >
                            {isDuplicating ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : (
                                <BsFilesAlt className="billsActionsIcon" />
                            )}
                            {isDuplicating && <span className="ms-1">Duplication...</span>}
                        </button>
                        <button
                            className={`btn btn-sm ${invoice.sent || invoice.sentByMail ? 'btn-outline-success' : 'btn-outline-primary'} mx-1`}
                            title="Envoyer le devis par email"
                            onClick={handleEmailSend}
                            disabled={isEmailSending || isEmailPreparing}
                        >
                            {isEmailSending || isEmailPreparing ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : (
                                <BsMailbox className="billsActionsIcon" />
                            )}
                            {isEmailSending && <span className="ms-1">Envoi...</span>}
                        </button>
                        {invoice.documentState === "Accepté" && (
                            <button
                                className="btn btn-sm btn-outline-primary mx-1"
                                title="Créer une facture à partir de ce devis"
                                onClick={handleCreateBill}
                                disabled={isDuplicating}
                            >
                                {isDuplicating ? (
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                ) : (
                                    <BsFileEarmarkTextFill className="billsActionsIcon" />
                                )}
                                {isDuplicating ? " Création en cours..." : ""}
                            </button>
                        )}
                    </div>
                    {showPDF && (
                        <PDFDownloadLink
                            document={<MyDocument bill={invoice} type="invoice" />}
                            fileName={getSanitizedFileName(invoice)}
                            className="d-none"
                        >
                            {({ blob, url, loading, error }) => {
                                if (!loading) {
                                    setIsPDFLoading(false);
                                    setShowPDF(false);
                                    // Trigger download
                                    const link = document.createElement('a');
                                    link.href = url;
                                    link.download = getSanitizedFileName(invoice);
                                    link.click();
                                }
                                return null;
                            }}
                        </PDFDownloadLink>
                    )}
                </td>
            </tr>

            {/* Add the email editor modal */}
            <style>{modalStyles}</style>
            <EmailEditorModal
                show={showEmailEditor}
                onHide={() => setShowEmailEditor(false)}
                onSend={sendEmail}
                emailData={emailData}
                setEmailData={setEmailData}
                isLoading={isEmailSending}
            />
        </>
    );
};

export default Invoices;