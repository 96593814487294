import React, { useState, useContext, useRef, useEffect } from 'react';
import UserContext from '../../dataContext';
import { PDFDownloadLink, pdf } from '@react-pdf/renderer';
import MyDocument from './PDFs/CommonPDF';
import {
    BsCloudDownloadFill,
    BsFillPencilFill,
    BsMailbox,
    BsArrowRepeat,
    BsFilesAlt,
    BsEye,
    BsSearch,
    BsX,
    BsEnvelope,
    BsTrash
} from "react-icons/bs";
import { formatDocument, formatDate, calculateValidityDate } from './utils/formatters';

// Add CSS styles for the custom modal
const modalStyles = `
.custom-modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    justify-content: center;
    align-items: center;
}

.custom-modal.show {
    display: flex !important;
    z-index: 9999;
}

.custom-modal-dialog {
    position: relative;
    width: 90%;
    max-width: 600px;
    margin: 1.75rem auto;
    z-index: 10000;
}

.custom-modal-content {
    background-color: white;
    border-radius: 5px;
    width: 100%;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
}

.custom-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #e9ecef;
}

.custom-modal-body {
    padding: 15px;
}

.custom-modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 15px;
    border-top: 1px solid #e9ecef;
    gap: 10px;
}

.custom-modal-close {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.email-preview {
    border: 1px solid #e9ecef;
    padding: 15px;
    margin-top: 10px;
    border-radius: 5px;
    background-color: #f8f9fa;
}

/* Ensure modal is on top of everything */
#email-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    overflow: visible;
    z-index: 10000;
}
`;

const EmailEditorModal = ({ show, onHide, onSend, emailData, setEmailData, isLoading }) => {
    const [isFullyMounted, setIsFullyMounted] = useState(false);
    const modalRef = useRef(null);

    const [localEmailData, setLocalEmailData] = useState({
        subject: '',
        to: '',
        includeTerms: false,
        user: '',
        role: ''
    });

    useEffect(() => {
        if (emailData && show) {
            setLocalEmailData({
                subject: emailData.subject || '',
                to: emailData.to || '',
                includeTerms: emailData.includeTerms || false,
                user: "Doriane Pépin" || '',
                role: emailData.role || ''
            });
        }
    }, [emailData, show]);

    useEffect(() => {
        if (show) {
            setIsFullyMounted(false);
            // Set a timeout to mark the modal as fully mounted
            const mountTimer = setTimeout(() => {
                setIsFullyMounted(true);
            }, 500);
            return () => clearTimeout(mountTimer);
        }
    }, [show]);

    useEffect(() => {
        let safetyTimeout = null;
        let initialRenderDelay = null;

        if (show) {
            initialRenderDelay = setTimeout(() => {
                // Removed the auto-close timeout
            }, 2000);
        }

        return () => {
            if (safetyTimeout) clearTimeout(safetyTimeout);
            if (initialRenderDelay) clearTimeout(initialRenderDelay);
        };
    }, [show, isLoading, onHide, isFullyMounted]);

    const handleClose = () => {
        onHide();
    };

    const handleSend = () => {
        console.log('Email data in modal before update:', {
            current: emailData,
            local: localEmailData
        });

        // Create a copy of the local data to ensure it's not modified
        const dataToSend = { ...localEmailData };

        console.log('Data being sent to sendEmail:', dataToSend);

        // Update the parent component's state (this might be async)
        setEmailData({
            ...emailData,
            ...localEmailData
        });

        // Pass the local data directly to ensure it's used immediately
        onSend(dataToSend);
    };

    const handleInputChange = (field, value) => {
        setLocalEmailData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    // Prevent closing when clicking outside the modal
    const handleOutsideClick = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            e.stopPropagation();
        }
    };

    // Ensure the modal is rendered at the document level
    useEffect(() => {
        // Add the styles to the document head
        const styleElement = document.createElement('style');
        styleElement.innerHTML = modalStyles;
        document.head.appendChild(styleElement);

        // Create a container for the modal if it doesn't exist
        let container = document.getElementById('email-modal-container');
        if (!container) {
            container = document.createElement('div');
            container.id = 'email-modal-container';
            document.body.appendChild(container);
        }

        return () => {
            // Clean up the style element when component unmounts
            document.head.removeChild(styleElement);
        };
    }, []);

    return (
        <div
            className={`custom-modal ${show ? 'show' : ''}`}
            onClick={handleOutsideClick}
            style={{ display: show ? 'flex' : 'none' }}
        >
            <div className="custom-modal-dialog" ref={modalRef}>
                <div className="custom-modal-content">
                    <div className="custom-modal-header">
                        <h5 className="custom-modal-title">Envoyer par email</h5>
                        <button type="button" className="close" onClick={handleClose}>
                            <span>&times;</span>
                        </button>
                    </div>
                    <div className="custom-modal-body">
                        <div className="form-group">
                            <label>Destinataire</label>
                            <input
                                type="email"
                                className="form-control"
                                value={localEmailData.to}
                                onChange={(e) => handleInputChange('to', e.target.value)}
                                placeholder="email@example.com"
                            />
                        </div>
                        <div className="form-group">
                            <label>Sujet</label>
                            <input
                                type="text"
                                className="form-control"
                                value={localEmailData.subject}
                                onChange={(e) => handleInputChange('subject', e.target.value)}
                                placeholder="Sujet de l'email"
                            />
                        </div>
                        <div className="form-group">
                            <label>Signature</label>
                            <input
                                type="text"
                                className="form-control"
                                value={localEmailData.user}
                                onChange={(e) => handleInputChange('user', e.target.value)}
                                placeholder="Signature de l'email"
                            />
                        </div>
                        <div className="form-group">
                            <label>Rôle</label>
                            <input
                                type="text"
                                className="form-control"
                                value={localEmailData.role}
                                onChange={(e) => handleInputChange('role', e.target.value)}
                                placeholder="Rôle de l'utilisateur"
                            />
                        </div>
                        <div className="form-group mt-3">
                            <p className="text-info">Le contenu de l'email sera généré automatiquement par le serveur.</p>
                        </div>
                        <div className="form-check mt-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="includeTerms"
                                checked={localEmailData.includeTerms}
                                onChange={(e) => handleInputChange('includeTerms', e.target.checked)}
                            />
                            <label className="form-check-label" htmlFor="includeTerms">
                                Inclure les conditions générales de vente
                            </label>
                        </div>
                    </div>
                    <div className="custom-modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={handleClose}
                        >
                            Annuler
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSend}
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <span className="sr-only">Envoi en cours...</span>
                                </>
                            ) : (
                                'Envoyer'
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const BillSingle = ({
    bill,
    setPopUpContent,
    setAction,
    formatDate,
    setShowPopUp,
    setPreviewBill,
    onUpdate,
    setBills
}) => {
    const userContext = useContext(UserContext);
    const [isPDFLoading, setIsPDFLoading] = useState(false);
    const [isEmailSending, setIsEmailSending] = useState(false);
    const [isEmailPreparing, setIsEmailPreparing] = useState(false);
    const [isDuplicating, setIsDuplicating] = useState(false);
    const [isCreatingCreditNote, setIsCreatingCreditNote] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showPDF, setShowPDF] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const duplicateRequestInProgress = useRef(false);

    // Email editor state
    const [showEmailEditor, setShowEmailEditor] = useState(false);
    const [emailData, setEmailData] = useState({
        email: '',
        subject: '',
        htmlContent: '',
        includeTerms: true
    });

    // Force reset isEmailSending when modal closes
    useEffect(() => {
        if (!showEmailEditor && isEmailSending) {
            setTimeout(() => {
                setIsEmailSending(false);
            }, 100);
        }
    }, [showEmailEditor, isEmailSending]);


    // Initialize emailSent state based on bill's sentByMail property
    useEffect(() => {
        if (bill && bill.sentByMail) {
            setEmailSent(true);
        } else {
            setEmailSent(false);
        }
    }, [bill]);

    const handleFormattedBill = (bill) => {
        return formatDocument(bill);
    };

    const handleEdit = () => {
        const formattedBill = handleFormattedBill(bill);

        setAction("modifyBill");
        setPopUpContent({
            what: {
                type: "Facture",
                title: bill.documentState === "Facturé" ? "Voir la facture" : "Modifier la facture"
            },
            thing: {
                object: formattedBill
            }
        });
        setShowPopUp(true);
    };

    const handleDownload = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsPDFLoading(true);

        try {
            const blob = await generatePDF();
            const url = URL.createObjectURL(blob);

            // Create a sanitized name for the filename
            const sanitizedName = bill.fullName ? bill.fullName.replace(/[^a-zA-Z0-9]/g, '_') : '';
            const isCredit = bill.documentState === 'Avoir' || bill.type === 'avoir' || (bill.code && bill.code.startsWith('AV-'));
            const docType = isCredit ? 'Avoir' : 'Facture';

            // Format: {Nom_P_TypeEtNumeroFichier}
            const fileName = `${sanitizedName}_${docType}_${bill.code}.pdf`;

            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            URL.revokeObjectURL(url);
            setIsPDFLoading(false);
        } catch (error) {
            console.error("Error downloading PDF:", error);
            userContext.setError({
                level: 'error',
                message: `Erreur lors du téléchargement du PDF: ${error.message}`,
                btn1Text: 'OK',
                btn2hidden: true
            });
            setIsPDFLoading(false);
        }
    };

    const generatePDF = async () => {
        try {
            const blob = await pdf(
                <MyDocument bill={bill} />,
                {
                    compress: true,
                    pdfVersion: "1.4",
                    userPassword: null,
                    ownerPassword: null,
                    permissions: null,
                    bufferPages: false
                }
            ).toBlob();

            return blob;
        } catch (error) {
            console.error("Error generating PDF:", error);
            throw error;
        }
    };

    /**
     * Converts a blob to base64
     * @param {Blob} blob - The blob to convert
     * @returns {Promise<string>} Base64 encoded string
     */
    const blobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                try {
                    const base64data = reader.result.split(',')[1];
                    resolve(base64data);
                } catch (error) {
                    reject(error);
                }
            };
            reader.onerror = (error) => {
                console.error("FileReader error:", error);
                reject(error);
            };
            reader.readAsDataURL(blob);
        });
    };

    const handleEmailSend = async () => {
        try {
            setIsLoading(true);

            // Determine document type
            const documentType = bill.documentState === 'Avoir' ? 'avoir' : 'facture';
            const documentTypeCapitalized = documentType.charAt(0).toUpperCase() + documentType.slice(1);

            // Create email subject
            const subject = `${documentTypeCapitalized} ${bill.code}${bill.reference ? ` - ${bill.reference}` : ''}`;

            const recipientEmail = bill.clientMail || '';

            // Get user information
            const user = userContext.user.name + ' ' + userContext.user.lastName;
            const role = "Responsable d'agence MYPacôme";

            const newEmailData = {
                to: recipientEmail,
                subject: subject,
                includeTerms: true,
                user: user,
                role: role,
            };

            setEmailData(newEmailData);
            setShowEmailEditor(true);
        } catch (error) {
            console.error('Error preparing email:', error);
            userContext.setError({
                level: 'error',
                message: `Erreur lors de la préparation de l'email: ${error.message || 'Erreur inconnue'}`,
                btn1Text: 'OK',
                btn2hidden: true
            });
        } finally {
            setIsLoading(false);
        }
    };

    const sendEmail = (localData) => {
        let requestInProgress = true;

        setIsEmailSending(true);

        // Use the local data passed from the modal if available
        const emailDataToUse = localData || emailData;

        // Ensure we're using the correct recipient from emailData
        // Prioritize the email address from the modal
        const recipientEmail = emailDataToUse.to || bill.clientMail;

        console.log('Email data before sending:', {
            to: emailDataToUse.to,
            clientMail: bill.clientMail,
            finalRecipient: recipientEmail,
            subject: emailDataToUse.subject,
            includeTerms: emailDataToUse.includeTerms,
            localData: localData ? 'provided' : 'not provided',
            emailDataToUse
        });

        if (!recipientEmail) {
            userContext.setError({
                level: 'error',
                message: "Aucune adresse email n'a été spécifiée",
                btn1Text: 'OK',
                btn2hidden: true
            });
            setIsEmailSending(false);
            return;
        }

        // Add a small delay to ensure state is updated
        setTimeout(() => {
            generatePDF()
                .then(blob => blobToBase64(blob))
                .then(base64data => {
                    // Prepare payload with correct recipient and server-side HTML generation data
                    const payload = {
                        billId: bill._id,
                        email: recipientEmail, // Use the recipient email directly
                        pdfAttachment: base64data,
                        includeTerms: emailDataToUse.includeTerms,
                        subject: emailDataToUse.subject,
                        // Additional data for server-side HTML generation
                        sender: {
                            name: emailDataToUse.user,
                            role: emailDataToUse.role
                        }
                    };

                    window.lastEmailApiCallTime = new Date().toISOString();
                    console.log(`Starting email API call for bill ${bill._id} at ${window.lastEmailApiCallTime}`);
                    console.log(`Sending email to: ${recipientEmail}`);
                    console.log('Final payload:', {
                        ...payload,
                        pdfAttachment: '[PDF DATA]',
                        email: recipientEmail // Log the email address again to confirm
                    });

                    return userContext.apiReducer("sendBillEmail", payload);
                })
                .then(response => {
                    requestInProgress = false;
                    console.log(`Email API response received for bill ${bill._id}:`, response);

                    // Only update UI after confirmed success from the API
                    // The EMAIL_SENT event will handle the state updates

                    setShowEmailEditor(false);
                    setIsEmailSending(false);

                    // Success message is now handled by the EMAIL_SENT event handler
                })
                .catch(error => {
                    console.error(`Error sending email for bill ${bill._id}:`, error);
                    requestInProgress = false;

                    setIsEmailSending(false);
                    setShowEmailEditor(false);

                    userContext.setError({
                        level: 'error',
                        message: "Une erreur est survenue lors de l'envoi de l'email: " + (error.message || "Erreur inconnue"),
                        btn1Text: 'OK',
                        btn2hidden: true
                    });
                })
                .finally(() => {
                    setIsEmailSending(false);
                });
        }, 100); // Small delay to ensure state updates
    };


    const handleDuplicate = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        // Prevent multiple submissions
        if (duplicateRequestInProgress.current || isDuplicating) {
            return;
        }

        // Set duplicating state to true to show spinner
        setIsDuplicating(true);
        duplicateRequestInProgress.current = true;

        try {
            // Add a small delay before making the API call to prevent double-clicks
            await new Promise(resolve => setTimeout(resolve, 300));

            const response = await userContext.apiReducer("duplicateBill", bill);
            if (response) {
                // After successful duplication, open the popup with the duplicated bill
                setPopUpContent({
                    what: { type: "Facture", title: "Modifier la facture" },
                    thing: { object: response }
                });
                setAction("modifyBill");
                setShowPopUp(true);

                // Also update the bills list
                onUpdate();

                // Add a delay before allowing another duplication request
                setTimeout(() => {
                    duplicateRequestInProgress.current = false;
                }, 5000); // 5 seconds delay
            }
        } catch (error) {
            console.error("Error duplicating bill:", error);
            userContext.setError({
                level: 'warning',
                message: `Échec de la duplication : ${error.message || 'Erreur inconnue'}`,
                btn1Text: 'Fermer',
                btn2hidden: true
            });
            // Reset the request flag in case of error
            setTimeout(() => {
                duplicateRequestInProgress.current = false;
            }, 2000); // 2 seconds delay even on error
        } finally {
            // Reset duplicating state
            setIsDuplicating(false);
        }
    };

    const handleCreateCreditNote = async (e) => {
        e.preventDefault();

        if (!bill || !bill._id) {
            userContext.setError({
                level: 'warning',
                message: 'Bill ID is required for credit note creation',
                btn1Text: 'OK',
                btn2hidden: true
            });
            return;
        }

        // Ask for confirmation before creating credit note
        userContext.setError({
            level: 'prompt',
            message: `Êtes-vous sûr de vouloir créer un avoir pour la facture ${bill.code} ?`,
            btn1Text: 'Créer',
            btn2Text: 'Annuler',
            btn1Action: async () => {
                try {
                    setIsCreatingCreditNote(true);

                    // Get all bills first to ensure we have the latest data
                    await userContext.apiReducer("getBills", { bypassCache: true });

                    // Then specifically filter for credit notes related to this bill
                    const existingCreditNotes = await userContext.apiReducer("getBills", {
                        filterByOriginalBill: bill._id,
                        bypassCache: true
                    });

                    // Count existing credit notes for this bill more accurately
                    // Use a regex pattern to match credit notes with the pattern AV-{baseCode}-{number}
                    const originalCode = bill.code;
                    const baseCode = originalCode.replace(/^FAC-/, '');
                    const creditNoteRegex = new RegExp(`^AV-${baseCode}-\\d+$`);

                    // Filter and find the highest sequence number
                    let highestSequence = 0;
                    if (existingCreditNotes?.bills?.length > 0) {
                        existingCreditNotes.bills.forEach(note => {
                            if (note.originalBillId === bill._id && note.code && creditNoteRegex.test(note.code)) {
                                // Extract the sequence number from the code
                                const sequencePart = note.code.split('-')[2];
                                const sequence = parseInt(sequencePart, 10);
                                if (!isNaN(sequence) && sequence > highestSequence) {
                                    highestSequence = sequence;
                                }
                            }
                        });
                    }

                    // Increment for the new credit note
                    const creditNoteNumber = highestSequence + 1;

                    // Format: AV-{baseCode}-{sequence}
                    const creditNoteCode = `AV-${baseCode}-${creditNoteNumber}`;
                    console.log(`Creating credit note with code: ${creditNoteCode}, sequence: ${creditNoteNumber}, highest found: ${highestSequence}`);

                    const billContents = bill.billContents || bill.items || [];

                    let parsedContents = billContents;
                    if (typeof billContents === 'string') {
                        try {
                            parsedContents = JSON.parse(billContents);
                        } catch (e) {
                            console.error('Error parsing bill contents:', e);
                            parsedContents = [];
                        }
                    }

                    const creditNoteData = {
                        type: 'avoir',
                        status: 'draft',
                        documentState: 'Brouillon', // Set to Brouillon instead of Avoir
                        client: bill.client,
                        clientId: bill.clientId || bill.client?._id,
                        property: bill.property,
                        amount: -Math.abs(bill.amount),
                        originalBillId: bill._id,
                        originalBillCode: originalCode,
                        creditNoteNumber: creditNoteNumber,
                        code: creditNoteCode,
                        creationDate: new Date().toISOString(),
                        date: new Date().toISOString(),
                        validity: bill.validity || 30,
                        payingMethod: bill.payingMethod || 'virement',
                        items: parsedContents,
                        billContents: parsedContents,
                        document: parsedContents,
                        notes: `Avoir pour la facture ${bill.code}`,
                        fullName: bill.fullName,
                        clientMail: bill.clientMail,
                        reference: bill.reference,
                        fullAddress: { ...bill.fullAddress },
                        fullSecondAddress: { ...bill.fullSecondAddress },
                        billingAddress: { ...bill.billingAddress },
                        siret: bill.siret,
                        totals: {
                            totalPreTax: -Math.abs(bill.totals?.totalPreTax || 0),
                            taxesTotal: -Math.abs(bill.totals?.taxesTotal || 0),
                            total: -Math.abs(bill.totals?.total || 0),
                            rawTotal: -Math.abs(bill.totals?.rawTotal || 0)
                        }
                    };

                    // Optimistically update UI if we have access to the bills list
                    if (setBills) {
                        // Get bills from the existingCreditNotes response instead of userContext.state
                        const currentBills = existingCreditNotes?.bills || [];
                        // Add the new credit note to the bills list
                        const optimisticCreditNote = {
                            ...creditNoteData,
                            _id: `temp-${Date.now()}`, // Temporary ID until we get the real one
                        };
                        setBills([...currentBills, optimisticCreditNote]);
                    }

                    // Make the API call
                    const response = await userContext.apiReducer("addBill", creditNoteData);

                    if (!response) {
                        throw new Error("No response received from server");
                    }

                    // Show success message
                    userContext.setError({
                        level: 'success',
                        message: 'Avoir créé avec succès',
                        btn1Text: 'OK',
                        btn2hidden: true
                    });

                    // Refresh data once to ensure consistency with server
                    if (onUpdate) {
                        onUpdate();
                    }
                } catch (error) {
                    // Revert optimistic update by refreshing data
                    if (onUpdate) {
                        onUpdate();
                    }

                    userContext.setError({
                        level: 'error',
                        message: `Erreur lors de la création de l'avoir: ${error.message || 'Erreur inconnue'}`,
                        btn1Text: 'OK',
                        btn2hidden: true
                    });
                } finally {
                    setIsCreatingCreditNote(false);
                }
            }
        });
    };

    const handlePreview = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setPreviewBill(bill);
    };

    const handleDelete = async (e) => {
        e.preventDefault();

        if (!bill || !bill._id) {
            userContext.setError({
                level: 'warning',
                message: 'ID de facture requis pour la suppression',
                btn1Text: 'OK',
                btn2hidden: true
            });
            return;
        }

        userContext.setError({
            level: 'warning',
            message: `Êtes-vous sûr de vouloir supprimer cette facture (${bill.code}) ?`,
            btn1Text: 'Annuler',
            btn2Text: 'Supprimer',
            btn1Action: () => { },
            btn2Action: async () => {
                // Store the bill row reference outside the try/catch block so it's accessible in both
                const billRow = document.querySelector(`tr[data-bill-id="${bill._id}"]`);

                try {
                    // Immediately hide the row for visual feedback
                    if (billRow) {
                        billRow.style.display = 'none';
                    }

                    // Make the API call to delete the bill
                    console.log(`Sending delete request for bill: ${bill._id}`);
                    const result = await userContext.apiReducer("deleteBill", {
                        _id: bill._id,
                        bill: bill
                    });
                    console.log("Delete API call result:", result);

                    // Show success message
                    userContext.setError({
                        level: 'success',
                        message: `La facture ${bill.code} a été supprimée avec succès`,
                        btn1Text: 'OK',
                        btn2hidden: true
                    });

                    // Force a refresh of the bills list
                    if (typeof onUpdate === 'function') {
                        onUpdate();
                    }
                } catch (error) {
                    console.error("Error deleting bill:", error);

                    // Show the row again if deletion failed
                    if (billRow) {
                        billRow.style.display = '';
                    }

                    userContext.setError({
                        level: 'error',
                        message: `Erreur lors de la suppression de la facture: ${error.message || 'Erreur inconnue'}`,
                        btn1Text: 'OK',
                        btn2hidden: true
                    });

                    // Refresh to get the correct state
                    if (typeof onUpdate === 'function') {
                        onUpdate();
                    }
                }
            }
        });
    };




    useEffect(() => {
        const handleEmailSent = (event) => {
            if (event.detail && event.detail.billId === bill._id) {
                console.log(`Email sent event received for bill ${bill._id}`);

                setShowEmailEditor(false);
                setIsEmailSending(false);

                if (bill) {
                    bill.sentByMail = true;
                    setEmailSent(true);
                }

                userContext.setError({
                    level: "success",
                    message: "Email envoyé avec succès!",
                    btn1Text: 'OK',
                    btn2hidden: true
                });

                // Call onUpdate to refresh the bills list if needed
                if (typeof onUpdate === 'function') {
                    onUpdate();
                }
            }
        };

        window.addEventListener('EMAIL_SENT', handleEmailSent);

        return () => {
            window.removeEventListener('EMAIL_SENT', handleEmailSent);
        };
    }, [bill, userContext, onUpdate]);

    // Add a validateBill function
    const validateBill = async (billId) => {
        if (!billId) {
            throw new Error('Bill ID is required for validation');
        }

        // Ask for confirmation before validating
        userContext.setError({
            level: 'prompt',
            message: 'Êtes-vous sûr de vouloir valider cette facture ? Cette action est irréversible.',
            btn1Text: 'Valider',
            btn2Text: 'Annuler',
            btn1Action: async () => {
                try {
                    // Use the userContext's apiReducer to validate the bill
                    await userContext.apiReducer("validateBill", {
                        _id: billId,
                        documentState: 'Facturé',
                        validationDate: new Date().toISOString()
                    });

                    // Force refresh to ensure UI is updated
                    window.location.reload();
                } catch (error) {
                    console.error('Error validating bill:', error);
                    userContext.setError({
                        level: 'error',
                        message: `Erreur lors de la validation: ${error.message || 'Erreur inconnue'}`,
                        btn1Text: 'OK',
                        btn2hidden: true
                    });
                }
            }
        });
    };

    // Calculate the correct totalPreTax value based on the reduction
    const calculateTotalWithReduction = () => {
        // Parse the reduction if it's a string
        let parsedReduction = { currency: "", amount: 0 };
        try {
            if (bill.reduction) {
                parsedReduction = typeof bill.reduction === 'string'
                    ? JSON.parse(bill.reduction)
                    : bill.reduction;
            }
        } catch (err) {
            console.error('Error parsing reduction:', err);
        }

        // Get the raw total from the bill
        const rawTotal = parseFloat(bill.totals?.rawTotal || 0);

        // Calculate the reduction amount
        let reductionAmount = 0;
        if (parsedReduction.currency === "%") {
            reductionAmount = (rawTotal * parseFloat(parsedReduction.amount || 0)) / 100;
        } else if (parsedReduction.currency === "€") {
            reductionAmount = parseFloat(parsedReduction.amount || 0);
        }

        // Calculate the final total pre-tax
        const finalTotalPreTax = rawTotal - reductionAmount;

        // Calculate the final total TTC (with taxes)
        const taxRate = 0.2; // 20% VAT
        const finalTaxAmount = finalTotalPreTax * taxRate;
        const finalTotalTTC = finalTotalPreTax + finalTaxAmount;

        return {
            totalHT: finalTotalPreTax,
            totalTTC: finalTotalTTC
        };
    };

    return (
        <>
            <tr className="billInfo" data-bill-id={bill._id}>
                <td>
                    <span className={`badge ${bill.documentState === "Brouillon" ? "bg-brouillon" :
                        bill.documentState === "Facturé" ? "bg-success" :
                            bill.documentState === "Avoir" ? "bg-success-dark" : "bg-primary"}`}>
                        {bill.documentState === "Brouillon" ?
                            (bill.type === 'invoice' ? "Devis brouillon" :
                                bill.type === 'avoir' || bill.code?.startsWith('AV') ? "Avoir brouillon" :
                                    "Facture brouillon") :
                            bill.documentState === "Facturé" ? "Facturé" :
                                bill.documentState === "Avoir" ? "Avoir" : bill.documentState}
                    </span>
                </td>
                <td>{bill.code}</td>
                <td>{formatDate(bill.date)}</td>
                <td>{formatDate(bill.validity)}</td>
                <td>{bill.fullName}</td>
                <td>{bill.reference}</td>
                <td>{calculateTotalWithReduction().totalHT.toFixed(2)}€</td>
                <td>{calculateTotalWithReduction().totalTTC.toFixed(2)}€</td>
                <td>
                    <div className="d-flex justify-content-end">
                        <button
                            className="btn btn-sm btn-outline-primary mx-1"
                            title="Prévisualiser la facture"
                            onClick={handlePreview}
                        >
                            <BsEye className="billsActionsIcon" />
                        </button>
                        {bill.documentState === "Facturé" || bill.documentState === "Avoir" ? (
                            <button
                                className="btn btn-sm btn-outline-primary mx-1"
                                title="Voir les détails de la facture"
                                onClick={handleEdit}
                            >
                                <BsSearch className="billsActionsIcon" />
                            </button>
                        ) : (
                            <button
                                className="btn btn-sm btn-outline-primary mx-1"
                                title="Modifier la facture"
                                onClick={handleEdit}
                            >
                                <BsFillPencilFill className="billsActionsIcon" />
                            </button>
                        )}
                        <button
                            className="btn btn-sm btn-outline-primary mx-1"
                            title="Télécharger la facture en PDF"
                            onClick={handleDownload}
                        >
                            <BsCloudDownloadFill className="billsActionsIcon" />
                        </button>
                        <button
                            className={`btn btn-sm ${bill.sentByMail || emailSent ? "btn-outline-success" : "btn-outline-primary"} mx-1`}
                            title="Envoyer la facture par email"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleEmailSend();
                            }}
                            disabled={isEmailPreparing || isEmailSending}
                        >
                            {isEmailPreparing ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : isEmailSending ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : (
                                <BsMailbox className="billsActionsIcon" />
                            )}
                        </button>
                        <button
                            className="btn btn-sm btn-outline-primary mx-1"
                            title="Dupliquer la facture"
                            onClick={handleDuplicate}
                            disabled={isDuplicating}
                        >
                            {isDuplicating ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : (
                                <BsFilesAlt className="billsActionsIcon" />
                            )}
                            {isDuplicating && <span className="ms-1">Duplication...</span>}
                        </button>

                        {bill.documentState === "Facturé" && (
                            <button
                                className="btn btn-sm btn-outline-danger mx-1"
                                title="Créer un avoir pour cette facture"
                                onClick={handleCreateCreditNote}
                                disabled={isCreatingCreditNote}
                            >
                                {isCreatingCreditNote ? (
                                    <div className="spinner-border spinner-border-sm" role="status">
                                        <span className="visually-hidden">Chargement...</span>
                                    </div>
                                ) : (
                                    <span>AV</span>
                                )}
                            </button>
                        )}

                        <button
                            className="btn btn-sm btn-outline-danger mx-1"
                            title={bill.documentState !== "Brouillon" ? "Seuls les brouillons peuvent être supprimés" : "Supprimer"}
                            onClick={handleDelete}
                            hidden={bill.documentState !== "Brouillon"}
                        >
                            <BsTrash />
                        </button>
                    </div>
                </td>
            </tr>

            <EmailEditorModal
                show={showEmailEditor}
                onHide={() => setShowEmailEditor(false)}
                onSend={sendEmail}
                emailData={emailData}
                setEmailData={setEmailData}
                isLoading={isEmailSending}
            />
        </>
    );
};

export default BillSingle;
