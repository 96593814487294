import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';

const blue = "#003952"

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#E0E0E0',
        borderBottomStyle: 'solid',
        alignItems: 'center',
        minHeight: 25,
        width: '100%'
    },
    cell: {
        padding: '3pt 5pt',
        fontSize: 8,
        fontFamily: 'monts',
        fontWeight: 'normal',  // Add explicit normal weight
        color: '#333333',
    },
    headerRow: {
        backgroundColor: blue,
    },
    headerCell: {
        color: '#ffffff',
        fontFamily: 'monts',
        fontWeight: 'bold',   // Changed from 700 to 'bold'
        fontSize: 8,
    },
    commentRow: {
        backgroundColor: '#F5F5F5',
    },
    subheaderRow: {
        backgroundColor: '#E0E0E0',
    },
    textRight: {
        textAlign: 'right',
    },
    reductionRow: {
        backgroundColor: '#F5F5F5',
        fontStyle: 'italic'
    }
});

const TableRow = ({ data = [], widths = [], isHeader, tableStyle = '' }) => {
    const rowStyle = [
        styles.row,
        isHeader && styles.headerRow,
        tableStyle === 'comment' && styles.commentRow,
        tableStyle === 'subheader' && styles.subheaderRow,
        tableStyle === 'reduction' && styles.reductionRow
    ].filter(Boolean);

    return (
        <View style={rowStyle} wrap={false}>
            {data.map((cellData, index) => (
                <View
                    key={index}
                    style={{
                        width: widths[index] || 'auto',
                        flex: widths[index] ? undefined : 1
                    }}
                >
                    <Text style={[
                        styles.cell,
                        isHeader && styles.headerCell,
                        index >= 1 && styles.textRight
                    ]}>
                        {typeof cellData === 'number'
                            ? (index >= 2 ? cellData.toFixed(2) : cellData.toString())
                            : String(cellData || '')}
                    </Text>
                </View>
            ))}
        </View>
    );
};

export default TableRow;
