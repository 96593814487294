const numberToWords = (num, isCredit = false) => {
    const units = ["zéro", "un", "deux", "trois", "quatre", "cinq", "six", "sept", "huit", "neuf"];
    const teens = ["dix", "onze", "douze", "treize", "quatorze", "quinze", "seize", "dix-sept", "dix-huit", "dix-neuf"];
    const tens = ["", "dix", "vingt", "trente", "quarante", "cinquante", "soixante", "soixante", "quatre-vingt", "quatre-vingt"];

    const convertTens = (n) => {
        if (n < 10) return units[n];
        if (n < 20) return teens[n - 10];

        const ten = Math.floor(n / 10);
        const unit = n % 10;

        // Special case for numbers between 70-79 (use soixante-dix through soixante-dix-neuf)
        if (ten === 7) {
            if (unit === 0) return "soixante-dix";
            if (unit === 1) return "soixante-et-onze";
            return "soixante-" + teens[unit]; // This gives us soixante-treize etc
        }

        // Special case for numbers between 90-99
        if (ten === 9) {
            if (unit === 0) return "quatre-vingt-dix";
            return "quatre-vingt-" + teens[unit]; // This gives us quatre-vingt-treize etc
        }

        // Handle 80-89
        if (ten === 8) {
            if (unit === 0) return "quatre-vingts"; // Note the "s" at the end
            return "quatre-vingt-" + units[unit];
        }

        // Regular case
        if (unit === 0) return tens[ten];
        if (unit === 1 && ten !== 8) return tens[ten] + "-et-un";
        return tens[ten] + "-" + units[unit];
    };

    const convertHundreds = (n) => {
        const hundred = Math.floor(n / 100);
        const rest = n % 100;
        return (hundred ? (hundred === 1 ? "cent" : units[hundred] + " cent") : "") + (rest ? " " + convertTens(rest) : "");
    };

    const convertThousands = (n) => {
        const thousand = Math.floor(n / 1000);
        const rest = n % 1000;
        return (thousand ? (thousand === 1 ? "mille" : units[thousand] + " mille") : "") + (rest ? " " + convertHundreds(rest) : "");
    };

    // Safely handle null, undefined, or invalid input
    if (num === null || num === undefined) {
        num = 0;
    }
    num = parseFloat(num);
    if (isNaN(num)) {
        num = 0;
    }

    // Handle negative numbers for credit notes
    const isNegative = num < 0;
    num = Math.abs(num);

    let [euros, cents] = num.toFixed(2).split(".");
    if (cents === undefined) cents = "00";
    const eurosInWords = convertThousands(parseInt(euros));
    const centsInWords = convertTens(parseInt(cents));

    // Format string differently for credit notes (avoirs)
    if (isCredit) {
        return `Le montant de l'avoir s'élève à ${eurosInWords} euros et ${centsInWords} centimes`;
    }
    return `Le montant total s'élève à ${eurosInWords} euros et ${centsInWords} centimes`;
};

export default numberToWords;