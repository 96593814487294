// External components
import React from "react";

// Icons
import { BsXCircle, BsCheckCircle, BsQuestionCircle } from "react-icons/bs";

// Context
import DataContext from "../../dataContext";
import "../../assets/scss/General/fancyButton.scss"

export default function ErrorModal(props) {
    const context = React.useContext(DataContext)

    if (!context.error.message) return null

    const getModalStyle = () => {
        switch (context.error.level) {
            case 'success':
                return { icon: <BsCheckCircle className={'icon'} />, title: 'Succès!', className: 'success', btnClass: 'success' };
            case 'prompt':
                return { icon: <BsQuestionCircle className={'icon'} />, title: 'Confirmation', className: 'info', btnClass: 'primary' };
            default:
                return { icon: <BsXCircle className={'icon'} />, title: 'Oooops!', className: 'danger', btnClass: 'warning' };
        }
    };

    const modalStyle = getModalStyle();

    return (
        <div className={"overlay-modal"}>
            <div id="myModal" className={"modal fade show"} aria-modal="true">
                <div className="modal-dialog modal-confirm">
                    <div className="modal-content">
                        <div className={`modal-header justify-content-center ${modalStyle.className}`}>
                            <div className="icon-box">
                                {modalStyle.icon}
                            </div>
                        </div>
                        <div className="modal-body text-center">
                            <div className={'row'}>
                                <h4>{modalStyle.title}</h4>
                            </div>
                            <div className={'row'}>
                                <p>{context.error.message}</p>
                            </div>
                            <div className={'buttons'}>
                                <button
                                    className={`btn btn-${modalStyle.btnClass} fancy-button-${modalStyle.className}`}
                                    onClick={() => {
                                        if (context.error.btn1Action) {
                                            context.error.btn1Action()
                                        }
                                        context.setError({})
                                    }}
                                >
                                    {context.error.btn1Text || "OK"}
                                </button>
                                {!context.error.btn2hidden && (
                                    <button
                                        className={`btn btn-secondary fancy-button-gray`}
                                        onClick={() => {
                                            if (context.error.btn2Action) {
                                                context.error.btn2Action()
                                            }
                                            if (!context.error.btn2ActionAvoidClose) context.setError({})
                                        }}
                                    >
                                        {context.error.btn2Text || "Annuler"}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}