/**
 * Utility functions for calculations in the billing system
 */

/**
 * Calculates the totals for a document including raw total, pre-tax total, taxes, and final total
 * @param {Array} document - The document items
 * @param {Object} reduction - The reduction to apply (if any)
 * @returns {Object} The calculated totals
 */
export const calculateTotals = (document = [], reduction = {}) => {
    if (!document || !Array.isArray(document)) {
        return {
            rawTotal: "0.00",
            totalPreTax: "0.00",
            taxesTotal: "0.00",
            total: "0.00"
        };
    }

    let rawTotal = 0;
    let totalPreTax = 0;
    let taxesTotal = 0;

    // First calculate raw total
    document.forEach((item) => {
        if (!item) return;
        if (item._id) {
            let quantity = parseFloat(item.quantity || 1);
            let price = parseFloat(item.price || 0);
            let itemTotal = quantity * price;
            rawTotal += itemTotal;
        }
    });

    // Apply reduction to get total pre-tax
    totalPreTax = rawTotal;
    if (reduction?.currency === "%") {
        totalPreTax = rawTotal * (1 - (parseFloat(reduction.amount || 0) / 100));
    } else if (reduction?.currency === "€") {
        totalPreTax = rawTotal - parseFloat(reduction.amount || 0);
    }

    // Calculate taxes based on the reduced amounts
    document.forEach((item) => {
        if (!item) return;
        if (item._id) {
            let quantity = parseFloat(item.quantity || 1);
            let price = parseFloat(item.price || 0);
            let itemTotal = quantity * price;

            // Calculate this item's share of the total
            let itemShare = rawTotal > 0 ? (itemTotal / rawTotal) : 0;

            // Apply the same reduction ratio to this item
            let reducedItemAmount = totalPreTax * itemShare;

            // Calculate taxes on the reduced amount
            let itemTaxes = reducedItemAmount * (parseFloat(item.taxes || 0) / 100);
            taxesTotal += itemTaxes;
        }
    });

    // Ensure everything is properly rounded
    return {
        rawTotal: rawTotal.toFixed(2),
        totalPreTax: totalPreTax.toFixed(2),
        taxesTotal: taxesTotal.toFixed(2),
        total: (totalPreTax + taxesTotal).toFixed(2)
    };
};

/**
 * Calculates the price without taxes for a list of items
 * @param {Array} items - The items to calculate
 * @returns {number} The total price without taxes
 */
export const calculatePriceNoTaxes = (items) => {
    if (!Array.isArray(items)) return 0;
    return items.reduce((total, item) => {
        if (item.quantity && item.price) {
            return total + (parseFloat(item.quantity) * parseFloat(item.price));
        }
        return total;
    }, 0);
};

/**
 * Validates a document before submission
 * @param {Object} document - The document to validate
 * @returns {Object} Validation result with isValid and message
 */
export const validateDocument = (document) => {
    if (!document) {
        return { isValid: false, message: 'Document is required' };
    }

    // Validate required fields
    const requiredFields = [
        { field: 'clientId', label: 'Client' },
        { field: 'payingMethod', label: 'Méthode de paiement' },
        { field: 'fullName', label: 'Nom complet' }
    ];

    for (const { field, label } of requiredFields) {
        if (!document[field]) {
            return {
                isValid: false,
                message: `Le champ "${label}" est requis`
            };
        }
    }

    // Validate document contents
    const contents = document.billContents || document.document;
    if (!contents || contents.length === 0) {
        return {
            isValid: false,
            message: 'Au moins un article est requis'
        };
    }

    // Validate each item in the document
    const items = Array.isArray(contents) ? contents :
        typeof contents === 'string' ? JSON.parse(contents) : [];

    const productItems = items.filter(item => item.type !== 'comment' && item.type !== 'section');

    if (productItems.length === 0) {
        return {
            isValid: false,
            message: 'Au moins un produit est requis'
        };
    }

    if (!productItems.every(item => item.price && item.quantity)) {
        return {
            isValid: false,
            message: 'Tous les articles doivent avoir un prix et une quantité valides'
        };
    }

    return { isValid: true };
}; 