import React, { useState, useContext, useEffect, useRef } from 'react';
import DataContext from '../../dataContext';
import Spinner from '../Spinner';

// Basic styled components since we don't have access to the UI library
const Card = ({ className, children }) => (
    <div className={`bg-white rounded-lg shadow-md p-4 ${className}`}>{children}</div>
);

const CardHeader = ({ className, children }) => (
    <div className={`mb-4 ${className}`}>{children}</div>
);

const CardContent = ({ className, children }) => (
    <div className={`space-y-4 ${className}`}>{children}</div>
);

const Input = ({ type, placeholder, value, onChange, disabled, className }) => (
    <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={`w-full px-3 py-2 border rounded ${className}`}
    />
);

const Button = ({ onClick, disabled, isLoading, className, children }) => (
    <button
        onClick={onClick}
        disabled={disabled}
        className={`btn btn-primary mt-4 btn-accordion ${className}`}
    >
        {isLoading ? <Spinner strokeWidth={3} width={20} color={"#faba79"} /> : children}
    </button>
);

export default function AirbnbScraper() {
    const [url, setUrl] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState('');
    const [progress, setProgress] = useState({ current: 0, total: 0 });
    const { apiReducer, getBurl } = useContext(DataContext);
    const clientId = useRef(Math.random().toString(36).substring(7));

    useEffect(() => {
        let eventSource;

        if (isProcessing) {
            eventSource = new EventSource(`${getBurl()}/scraper/airbnb/progress?clientId=${clientId.current}`);

            eventSource.onmessage = (event) => {
                const data = JSON.parse(event.data);
                setProgress({
                    current: data.current,
                    total: data.total
                });
            };

            eventSource.onerror = () => {
                eventSource.close();
            };
        }

        return () => {
            if (eventSource) {
                eventSource.close();
            }
        };
    }, [isProcessing, getBurl]);

    const handleScrape = async () => {
        try {
            setIsProcessing(true);
            setError('');
            setProgress({ current: 0, total: 0 });

            const blobData = await apiReducer('scrapeAirbnb', {
                url,
                clientId: clientId.current
            });

            // Create and trigger download
            const blob = new Blob([blobData], { type: 'application/zip' });
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = 'airbnb-images.zip';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            setError(error.response?.data?.message || 'Failed to process the request');
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        <Card className="w-full max-w-md">
            <CardHeader className="text-lg font-semibold">Entrez une URL Airbnb</CardHeader>
            <CardContent className="space-y-4">
                <div>
                    <Input
                        type="text"
                        placeholder="https://www.airbnb.fr/rooms/..."
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        disabled={isProcessing}
                    />
                </div>

                {isProcessing && progress.total > 0 && (
                    <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
                        <div
                            className="bg-blue-600 h-2.5 rounded-full transition-all duration-300"
                            style={{ width: `${(progress.current / progress.total) * 100}%` }}
                        />
                        <div className="text-sm text-gray-600 text-center mt-2">
                            Téléchargement: {progress.current}/{progress.total} images
                        </div>
                    </div>
                )}

                {error && (
                    <div className="text-red-500 text-sm">{error}</div>
                )}

                <Button
                    onClick={handleScrape}
                    disabled={isProcessing || !url}
                    isLoading={isProcessing}
                    className="w-full"
                >
                    {isProcessing ? 'Traitement en cours...' : 'Télécharger les images'}
                </Button>
            </CardContent>
        </Card>
    );
}