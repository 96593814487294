import { BsDownload, BsFillCaretDownFill, BsFillTrashFill } from "react-icons/bs";
import BillingSuggestions from "../../../input/BillingSuggestions";
import { billing, getFormattedDate, nameOfMonth, slugify } from "../../../../../Utils/functions";
import { CSVLink } from "react-csv";
import { useState, useEffect } from "react";

export default function ClientInvoices({ obj, setObj, closeInstant, refresh, setLoading, context, overlayProps }) {
    const [productSearch, setProductSearch] = useState('')
    const [focused, setFocused] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [error, setError] = useState(null);

    // Log initial state
    console.log('Initial state:', {
        hasSubscription: !!overlayProps.item.subscription,
        hasList: !!obj?.list,
        clientData: overlayProps.item
    });

    useEffect(() => {
        if (!!overlayProps.item.subscription && !obj?.list) {
            console.log('Calling billing function with:', overlayProps.item);
            const billingResult = billing(overlayProps.item);
            console.log('Billing function result:', billingResult);
            setObj(billingResult);
        }
    }, [overlayProps.item, obj?.list, setObj]);

    // Load products if needed
    useEffect(() => {
        const loadProducts = async () => {
            if (!context.data.products) {
                try {
                    setLoading(true);
                    const response = await context.apiReducer('getProductsList');

                    // Validate response format
                    if (!Array.isArray(response)) {
                        throw new Error('Invalid products data format');
                    }

                    setLoading(false);
                } catch (error) {
                    console.error('Error loading products:', error);
                    setError(error.message);
                    setLoading(false);
                }
            }
        };

        loadProducts();
    }, [context.data.products]);

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let service = {
                "service": e.target[0].value,
                "price": e.target[1].value,
                "quantity": e.target[2].value,
                "date": new Date().toISOString()
            };

            // Validate service data
            if (!service.service || !service.price || !service.quantity) {
                throw new Error('Please fill in all fields');
            }

            // Reset form
            e.target[0].value = "";
            e.target[1].value = "";
            e.target[2].value = "";
            setSelectedProduct(null);

            if (overlayProps.item.additionalServices) {
                overlayProps.item.additionalServices.push(service);
                service = overlayProps.item.additionalServices;
            }

            setLoading(true);
            await context.apiReducer('modifyClient',
                { additionalServices: service },
                { email: overlayProps.item.email }
            );

            setLoading(false);
            const item = overlayProps.item;
            overlayProps.edit(item);
            setObj(billing(overlayProps.item));
            refresh();

        } catch (error) {
            console.error('Error adding service:', error);
            setError(error.message);
            setLoading(false);
        }
    };

    if (!overlayProps.item.subscription) {
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Facturation de {overlayProps.item.lastName} {overlayProps.item.name}</h2>
                                <p className={"form-text light"}>Extraction de la facturation selon les mois</p>
                            </div>
                        </div>
                        <h3>Pas de contrat enregistré</h3>
                    </div>
                </div>
            </div>
        )
    }

    if (!obj?.list) {
        return (
            <div className={"overlay-modal"}>
                <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                    <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                        <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                            <div className={"col"}>
                                <h2 className={""}>Facturation de {overlayProps.item.lastName} {overlayProps.item.name}</h2>
                                <p className={"form-text light"}>Extraction de la facturation selon les mois</p>
                            </div>
                        </div>
                        <h3>Chargement en cours</h3>
                    </div>
                </div>
            </div>
        )
    }
    for (let row of obj.csvTotal) {
        // Loop through each element
        for (let i = 0; i < row.length; i++) {
            // Check if the element is a string
            if (typeof row[i] === "string") {
                // Replace any comma with a dot using a regular expression
                row[i] = row[i].replace(/,/g, ".");
            }
        }
    }

    const additionalServices = overlayProps.item.additionalServices
    let totalAdditionalServices = 0
    additionalServices.forEach(additionalService => {
        totalAdditionalServices += (parseFloat(additionalService.price) * parseInt(additionalService.quantity))
    });

    totalAdditionalServices = +(Math.round(totalAdditionalServices / 1 + "e+2") + "e-2")

    return (
        <div className={"overlay-modal"}>
            {error && (
                <div className="alert alert-danger" role="alert">
                    {error}
                    <button type="button" className="close" onClick={() => setError(null)}>
                        <span>&times;</span>
                    </button>
                </div>
            )}
            <div onClick={closeInstant} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                <div className={"col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"}>
                    <div className={"row mb-3 sticky-top border-bottom bg-success"}>
                        <div className={"col"}>
                            <h2 className={""}>Facturation de {overlayProps.item.lastName} {overlayProps.item.name}</h2>
                            <p className={"form-text light"}>Extraction de la facturation selon les mois</p>
                        </div>
                    </div>
                    <div className={"row d-inline-flex w-100"}>
                        <p className={"w-50"}>Rapport complet du contrat en cours</p>
                        <CSVLink type={"button"} className={"btn btn-light w-50"}
                            data={obj.csvTotal}
                            filename={
                                overlayProps.item.lastName
                                + "-"
                                + overlayProps.item.name
                                + "_"
                                + "TOTAL"
                                + ".csv"
                            }>
                            <BsDownload />
                        </CSVLink>
                    </div>
                    {obj.list?.map((monthItem, index) => {
                        for (let row of monthItem.csv) {
                            // Loop through each element
                            for (let i = 0; i < row.length; i++) {
                                // Check if the element is a string
                                if (typeof row[i] === "string") {
                                    // Replace any comma with a dot using a regular expression
                                    row[i] = row[i].replace(/,/g, ".");
                                }
                            }
                        }
                        return (
                            <div key={monthItem.start} className={"mb-5"}> {/*Month section: To fold if not first*/}
                                <hr />
                                <div className={"row bg-darker-light justify-content-between"}>
                                    <div className={"col-7"}>
                                        <h3>Facture
                                            de {nameOfMonth(monthItem.start)} - {monthItem.start.getFullYear()}</h3>
                                        <h4>Période
                                            du {getFormattedDate(monthItem.start)} au {getFormattedDate(monthItem.end)}</h4>
                                    </div>
                                    <div className={"col-3"}>
                                        <h3 className={"w-100 text-end"}>{+(Math.round(monthItem.prices.total / 0.833333 + "e+2") + "e-2")} €
                                            TTC</h3>
                                        <h4 className={"w-100 text-end"}>{+(Math.round(monthItem.prices.total / 1 + "e+2") + "e-2")} € HT</h4>
                                    </div>
                                    <div className={"col-1 flex-wrap"}>
                                        <CSVLink type={"button"} className={"btn btn-light w-100"}
                                            data={monthItem.csv}
                                            filename={
                                                overlayProps.item.lastName
                                                + "-"
                                                + overlayProps.item.name
                                                + "_"
                                                + nameOfMonth(monthItem.start)
                                                + "-"
                                                + monthItem.start.getFullYear()
                                                + ".csv"
                                            }>
                                            <BsDownload />
                                        </CSVLink>
                                        <button type={"button"} className={"btn btn-light w-100"} onClick={() => {
                                            const object = obj
                                            object.list[index].collapse = !object.list[index].collapse
                                            setObj(object)
                                            refresh()
                                        }}>
                                            <BsFillCaretDownFill />
                                        </button>
                                    </div>
                                </div>
                                <div className={monthItem.collapse ? "collapse" : ""}>
                                    {/*Renting section*/}
                                    <div className={"mt-3"}>
                                        <h5>Gestion locative</h5>
                                        <p hidden={monthItem.subscription.rate === 0}>Pourcentage hors taxe sur la
                                            nuitée
                                            : {monthItem.subscription?.rate}</p>
                                        <p hidden={monthItem.bookings.length > 0}>Aucune location</p>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Location</th>
                                                    <th scope="col">Prix/nuitée <small>€ ht</small></th>
                                                    <th scope="col">Commission/nuitée <small>€ ht</small></th>
                                                    <th scope="col">Commission Totale <small>€ ht</small></th>
                                                    <th scope="col">Linge <small>€ ht</small></th>
                                                    <th scope="col">Ménage <small>€ ht</small></th>
                                                    <th scope="col">Total <small>€ ht</small></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {monthItem.bookings.map((booking, index) => {
                                                    if (!booking.prices?.night
                                                        || !booking.laundry?.price
                                                        || !booking.prices?.cleaning) {
                                                        return (
                                                            <tr key={`incomplete-booking-${index}`}>
                                                                <td>{booking.platform} : {getFormattedDate(new Date(booking.dates.start))} - {getFormattedDate(new Date(booking.dates.end))}</td>
                                                                {booking.data.night === undefined ?
                                                                    <td className={'warning-bg'}>N/A</td>
                                                                    :
                                                                    <td>{booking.data.night}</td>
                                                                }
                                                                {booking.data.night === undefined ?
                                                                    <td className={'warning-bg'}>N/A</td>
                                                                    :
                                                                    <td>{booking.data.took}</td>
                                                                }
                                                                {booking.data.cleaning === undefined ?
                                                                    <td className={'warning-bg'}>N/A</td>
                                                                    :
                                                                    <td>{booking.data.cleaning}</td>
                                                                }
                                                                {booking.data.cleaning === undefined ?
                                                                    <td className={'warning-bg'}>N/A</td>
                                                                    :
                                                                    <td>{booking.data.cleaning}</td>
                                                                }
                                                                <td className={'danger-bg'}>-</td>
                                                            </tr>
                                                        )
                                                    }

                                                    return (
                                                        <tr key={`booking-${booking.platform}-${booking.dates.start}-${booking.dates.end}`}>
                                                            <td>{booking.platform} : {getFormattedDate(new Date(booking.dates.start))} - {getFormattedDate(new Date(booking.dates.end))}</td>
                                                            <td>{booking.data.night}</td>
                                                            <td>{booking.data.took}</td>
                                                            <td>{booking.data.took * booking.dates.days}</td>
                                                            <td>{booking.data.laundry}</td>
                                                            <td>{booking.data.cleaning}</td>
                                                            <td>{booking.data.total}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                        <p className={"text-end"}>Prix total de la gestion locative
                                            : <span
                                                className={"fw-bold"}>{monthItem.prices.booking} <small>€ ht</small></span>
                                        </p>
                                    </div>
                                    {/*Subscription section*/}
                                    <div className={"mt-3"}>
                                        <h5>Abonnement</h5>
                                        <p hidden={monthItem.subscription !== undefined}>Non abonné</p>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Intervention mensuel</th>
                                                    <th scope="col">Gestion mensuel</th>
                                                    <th scope="col">Prix <small>€ ht</small></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{monthItem.subscription.intervention}</td>
                                                    <td>{monthItem.subscription.management}</td>
                                                    <td>{monthItem.subscription.price}€</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p className={"text-end"}>Prix total de l'abonnement
                                            : <span className={"fw-bold"}>{monthItem.prices.subscription} <small>€ ht</small></span>
                                        </p>
                                    </div>
                                    {/*Custom section*/}
                                    <div>
                                        <h5>Prestations supplémentaires</h5>
                                        <form onSubmit={handleSubmit} className={"mt-3 d-flex flex-row"}>
                                            <div className="form-floating mb-3 me-3 col-6">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    placeholder="Photos..."
                                                    className="form-control"
                                                    value={productSearch}
                                                    onChange={(e) => {
                                                        setProductSearch(e.target.value);
                                                        if (!focused) setFocused(true);
                                                    }}
                                                    onFocus={(e) => {
                                                        if (!focused) { setFocused(true); }
                                                    }}
                                                ></input>
                                                <label htmlFor="name" className="form-label">
                                                    Prestation
                                                </label>
                                                <small className="text-muted">
                                                    Quele prestation voulez-vous facturer ?
                                                </small>
                                                <BillingSuggestions
                                                    data={context.data.products}
                                                    search={slugify(productSearch)}
                                                    focused={focused}
                                                    setFocused={setFocused}
                                                    onFocus={() => {
                                                        if (!focused) setFocused(true)
                                                    }}
                                                    onBlur={() => {
                                                        if (focused) setFocused(false)
                                                    }}
                                                    click={(value) => {
                                                        if (!value) return;
                                                        setLoading(true);
                                                        setSelectedProduct(value);
                                                        setProductSearch(value.Code + " - " + value.Libellé);
                                                        setLoading(false);
                                                    }}
                                                />
                                            </div>
                                            <div className="form-floating mb-3 col-2">
                                                <input
                                                    value={selectedProduct ? selectedProduct["Prix de vente HT"] : ''}
                                                    type='number'
                                                    step={0.01}
                                                    name='price'
                                                    className="form-control"
                                                    readOnly
                                                ></input>
                                                <label htmlFor="price" className="form-label">Prix unitaire</label>
                                            </div>
                                            <div className="form-floating mb-3 col-2 ms-3">
                                                <input type='number' name='quantity' className="form-control"></input>
                                                <label htmlFor="quantity" className="form-label">Quantité</label>
                                            </div>

                                            <div className="col-1">
                                                <button type="submit" className="form-control btn btn-light btn-success ms-3">✓</button>
                                            </div>
                                        </form>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Prestation</th>
                                                    <th scope="col">Prix</th>
                                                    <th scope="col">Quantité</th>
                                                    <th scope="col">Supprimer</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    monthItem.additionalServices.map((service) => {
                                                        return (
                                                            <tr key={service.service + service.price + service.quantity}>
                                                                <td className='col-7'>{service.service}</td>
                                                                <td className='col-2'>{service.price}€</td>
                                                                <td className='col-1'>{service.quantity}</td>
                                                                <td className='col-1'><button className={"btn btn-light mx-1"} onClick={(e) => {
                                                                    const result = additionalServices.filter((additionalService) => service.date !== additionalService.date)
                                                                    context.apiReducer('modifyClient', { additionalServices: result }, { email: overlayProps.item.email })
                                                                        .then(() => {
                                                                            const item = overlayProps.item
                                                                            item.additionalServices = result
                                                                            overlayProps.edit(item)
                                                                            setObj(billing(overlayProps.item))
                                                                            refresh()
                                                                        })
                                                                        .catch((e) => {
                                                                            console.log(e)
                                                                        })
                                                                }}><BsFillTrashFill /></button></td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        <p className={"text-end"}>Prix total des prestations supplémentaires
                                            : <span className={"fw-bold"}>{totalAdditionalServices} <small>€ ht</small></span>
                                        </p>
                                    </div>
                                    {/*Invoice section*/}
                                    <div className={"mt-3"}>
                                        <h5>Devis</h5>
                                        <p>Non disponible pour l'instant</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}