// External component
import React from "react";

import { Loader } from '@googlemaps/js-api-loader';
import Geocode from "react-geocode";

import UserContext from '../dataContext'

Geocode.setApiKey("AIzaSyAvOasSxV7DCtj8h-G8tgWHeSURhpaHWgo");
Geocode.setLanguage("fr");
Geocode.setLocationType("ROOFTOP");

export default function Map() {
  const context = React.useContext(UserContext)
  if (context.loading) {
    return (
      <>
        <div className={"col border cardsContainer mx-1 p-2 mt-3 mt-md-0"}>
          <h1>Chargement de la carte...</h1>
        </div>
      </>
    )
  } else if (!context.loading) {
    return (
      <>
        <div className="col border cardsContainer mx-1 p-2 mt-3 mt-md-0">
          <div style={{ height: '80dvh' }} className="map">
          </div>
          <h4><span class="badge bg-primary-new">‎ ‎ ‎ </span> = Propriétaires</h4>
          <h4><span class="badge bg-secondary-new">‎ ‎ ‎ </span> = Concierges</h4>
          <MapLoader context={context} />
        </div>
      </>
    )
  }
  else { console.log("exeption") }
}
function MapLoader({ context }) {

  const loader = new Loader({
    apiKey: "AIzaSyAz10eVlGPFqK5gKXsCBCAW3YBLWa8miEc",
    version: "weekly",
    libraries: ["places"]
  });

  const mapOptions = {
    center: {
      lat: 49.223365,
      lng: 0.203242
    },
    zoom: 10
  };

  loader
    .load()
    .then((google) => {
      loadMap(google, mapOptions, context)
    })
    .catch(e => {
      console.log(e)
    });
  return null
}



function loadMap(google, mapOptions, context) {
  const map = new google.maps.Map(document.querySelector(".map"), mapOptions);

  // console.log(context.data)
  let propertyMarkers = []

  const propertyIcon = {
    url: "https://mypacome.fr/wp-content/uploads/2024/07/marker-svgrepo-com.png", // url
    scaledSize: new google.maps.Size(40, 40), // scaled size
  };

  const conciergeIcon = {
    url: "https://mypacome.fr/wp-content/uploads/2024/07/marker-svgrepo-com1.png", // url
    scaledSize: new google.maps.Size(40, 40), // scaled size
  };

  // Add coordinate validation
  const isValidCoordinate = (lat, lng) => {
    // Special case for (0,0) coordinates
    if (lat === 0 && lng === 0) {
      return false;
    }

    // For Normandy region:
    // Latitude should be between 48.5° and 49.8° N
    // Longitude should be between -0.5° and 1.0° E
    const validLat = !isNaN(lat) && lat >= 48.5 && lat <= 49.8;
    const validLng = !isNaN(lng) && lng >= -0.5 && lng <= 1.0;
    return validLat && validLng;
  };

  context.data.people.Client.forEach(client => {
    if (client.archive) { } else {
      client.properties.forEach(property => {
        // Parse coordinates, handling potential swapped lat/lng
        let lat = parseFloat(property.geo.lat);
        let lng = parseFloat(property.geo.long);

        // If coordinates look swapped (longitude in lat field), swap them back
        if (!isValidCoordinate(lat, lng) && isValidCoordinate(lng, lat)) {
          [lat, lng] = [lng, lat];
        }

        // Skip invalid coordinates
        if (!isValidCoordinate(lat, lng)) {
          console.warn(`Invalid coordinates for property ${property.name}: ${lat}, ${lng}`);
          return;
        }

        let address = property.address.number + " " + property.address.road + ", " + property.address.zip + " " + property.address.city + ((property.address.other) ? `, ${property.address.other}` : "")

        let markerProperty = new google.maps.Marker({
          position: { lat, lng },
          map,
          title: property.name,
          icon: propertyIcon,
        });
        propertyMarkers.push(markerProperty)
        markerProperty.addListener("mouseover", function () {
          markerProperty.setAnimation(google.maps.Animation.BOUNCE);
        });
        markerProperty.addListener("mouseout", function () {
          markerProperty.setAnimation(null);
        });
        markerProperty.addListener("click", () => {
          displayInfoWindow(property.name, `Adresse : ${address}`, markerProperty, map, `Coordonnées : ${client.phone}, ${client.email}`)
        });
      });
    }
  });

  context.data.people.Concierge.forEach(concierge => {
    if (concierge.archive) { } else {
      let address = concierge.address.number + " " + concierge.address.road + ", " + concierge.address.zip + " " + concierge.address.city;

      Geocode.fromAddress(address).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          // console.log(lat, lng);
          let markerConcierge = new google.maps.Marker({
            position: { lat: lat, lng: lng },
            map,
            title: `${concierge.name} ${concierge.lastName}`,
            icon: conciergeIcon,
          });
          markerConcierge.addListener("mouseover", () => {
            markerConcierge.setAnimation(google.maps.Animation.BOUNCE);
          });
          markerConcierge.addListener("mouseout", () => {
            markerConcierge.setAnimation(null);
          });
          markerConcierge.addListener("click", () => {
            displayInfoWindow(`${concierge.name} ${concierge.lastName}`, `Adresse : ${address}`, markerConcierge, map, `Coordonnées : ${concierge.phone}, ${concierge.email}`)
          });
        },
        (error) => {
          console.error(error);
        }
      );

    }
  });

  // Marqueur pour l'agence
  const agencyIcon = {
    url: "https://i.ibb.co/G92kyk1/map-icon.png", // url
    scaledSize: new google.maps.Size(30, 30), // scaled size
  };

  const markerAgency = new google.maps.Marker({
    position: { lat: 49.283, lng: 0.203242 },
    map,
    title: "Agence MYPacôme",
    icon: agencyIcon,
  });

  const contentString =
    '<div id="content">' +
    '<h2>Agence MYPacôme</h2>' +
    "<p>2 Impasse de Grieu 14130 Pont L'Êveque</p>" +
    "</div>";

  console.log(contentString, "contentString")

  const infoWindow = new google.maps.InfoWindow({
    content: "",
  });
  markerAgency.addListener("click", () => {
    displayInfoWindow("Agence MYPacôme", "14 bis Rue Georges Clemenceau 14130 Pont-l'Évêque", markerAgency, map)
  });

  function displayInfoWindow(mainText, secondaryText, marker, map, thirdText) {
    // thirdText ? "" : thirdText = ""
    const labelDiv = document.createElement('div')
    labelDiv.title = "Agence MYPacôme"
    const h5 = document.createElement("h5")
    const br = document.createElement("br")
    const h6 = document.createElement("h6")
    mainText = document.createTextNode(mainText)
    secondaryText = document.createTextNode(secondaryText)
    thirdText = document.createTextNode(thirdText)
    labelDiv.style.fontFamily = 'system-ui, -apple-system, "Segoe UI"'
    labelDiv.style.fontSize = "13px"
    labelDiv.style.color = "black"
    h5.appendChild(mainText)
    labelDiv.appendChild(h5)
    h6.appendChild(secondaryText)
    h6.appendChild(br)
    h6.appendChild(thirdText)
    labelDiv.appendChild(h6)
    infoWindow.setContent(labelDiv);
    infoWindow.open(map, marker);
  }

}




// export default function Data() {
//     const [month] = React.useState(() => {
//         return monthList()
//     })
//         , [CA] = React.useState(() => {
//         const arr = []
//         for (let i = 0; i <= monthList().length; i++) {
//             arr.push(getRandomInt(6540))
//         }
//         return arr
//     })
//         , [Dep] = React.useState(() => {
//         const arr = []
//         for (let i = 0; i <= monthList().length; i++) {
//             arr.push(getRandomInt(6540))
//         }
//         return arr
//     })
//         , [ben, setBen] = React.useState([])
//         , dataLine = {
//         labels: month,
//         datasets: [
//             {
//                 label: "Chiffre d'affaire " + new Date().toLocaleDateString('fr-FR', {year: 'numeric'}),
//                 data: CA,
//                 fill: false,
//                 backgroundColor: '#52D6F44F',
//                 borderColor: '#408697',
//                 tension: 0.3
//             },
//             {
//                 label: "Dépenses " + new Date().toLocaleDateString('fr-FR', {year: 'numeric'}),
//                 data: Dep,
//                 fill: {
//                     target: '-1',
//                     above: '#ff47474F',   // Area will be red above the origin
//                     below: '#52D6F44F'    // And blue below the origin
//                 },
//                 backgroundColor: '#ff47474F',
//                 borderColor: '#ff4747',
//                 tension: 0.3
//             },
//             {
//                 label: "Revenues d'exploitation " + new Date().toLocaleDateString('fr-FR', {year: 'numeric'}),
//                 data: ben,
//                 fill: {
//                     target: true,
//                     above: '#71c0164F',   // Area will be red above the origin
//                     below: '#ff47474F'    // And blue below the origin
//                 },
//                 backgroundColor: '#71c0164F',
//                 borderColor: '#71c016',
//                 tension: 0.3
//             },
//         ],
//     }


//     React.useEffect(() => {
//         const benTemp = []
//         CA.map((item, index) => {
//             benTemp.push(item - Dep[index])
//             return null
//         })
//         setBen(benTemp)
//     }, [CA, Dep, setBen])

//     return (
//         <div>
//             <RowContent>
//                 <ContentCard>
//                     <Map markers={
//                         [{
//                             circle: false,
//                             position: [49.2830, 0.2034],
//                             description: "Agence MYPacome",
//                             address: "2 Impasse de Grieu 14130 Pont L'Êveque"
//                         }, {
//                             circle: false,
//                             type: "red",
//                             position: [49.358863, 0.085397],
//                             description: "Client - Mr Motard",
//                             address: "2 rue des vélos forts 14320 trouville"
//                         }, {
//                             circle: true,
//                             radius: 10000,
//                             type: "blue",
//                             position: [49.407124, 0.198861],
//                             description: "Concierge - Anabelle Chucky",
//                             address: "42 rue de la course Honfleur 14330"
//                         }, {
//                             circle: true,
//                             radius: 16000,
//                             type: "purple",
//                             position: [49.167269, 0.210035],
//                             description: "Prestataire - Multi Maison",
//                             address: "91 avenue de Gaulle 14270 Lisieux "
//                         },]
//                     } polygons={[
//                         [
//                             [49.299264, -0.157200],
//                             [49.406707, 0.115183],
//                             [49.436237, 0.224136],
//                             [49.485860, 0.507118],
//                             [49.373600, 0.574003],
//                             [49.128482, 0.487782],
//                             [49.138648, 0.050467],

//                         ]
//                     ]}/>
//                 </ContentCard>
//             </RowContent>
//             <RowContent>
//                 <ContentCard>
//                     <ChartsLine data={dataLine}/>
//                 </ContentCard>
//             </RowContent>
//         </div>
//     )
// }


// function monthList() {
//     let arr = []
//         , d1 = new Date()
//         , d2 = new Date(d1.getFullYear(), 0, 1)

//     for (; d2 <= d1; d2.setMonth(d2.getMonth() + 1)) {
//         arr.push(capitalizeFirstLetter(d2.toLocaleDateString('fr-FR', {month: 'long'})))
//     }

//     return arr;
// }

// function capitalizeFirstLetter(string) {
//     return string.charAt(0).toUpperCase() + string.slice(1);
// }

// function getRandomInt(max) {
//     return Math.floor(Math.random() * max);
// }