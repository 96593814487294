import React from 'react'
import UserContext from "../dataContext";

// INTERNAL COMPONENTS
import Overlay from "../Components/Subcomponents/Overlay/Overlay";

// ICONS
import { BsPlus, BsDownload, BsCalendar, BsX } from "react-icons/bs";

// FUNCTIONS
import { billing, nameOfMonth, round, toMonthYear, toTTC, csvMultipleClients } from "../Utils/functions";
import { CSVLink } from "react-csv";
import Spinner from '../Components/Spinner';

export default function Billing(props) {
    const userContext = React.useContext(UserContext)

    const [item, setItem] = React.useState({})
        , [loading, setLoading] = React.useState(true)
        , [, r] = React.useState({})
        , [list, setList] = React.useState([])
        , [monthList, setMonthList] = React.useState([])
        , [currentObj, setCurrentObj] = React.useState({ list: [], csv: [] })
        , [total, setTotal] = React.useState(0)
        , [clientMultiMonthCsv, setClientMultiMonthCsv] = React.useState({})
        , [clientMultiMonthSelect, setClientMultiMonthSelect] = React.useState(null)
        , [showExportModal, setShowExportModal] = React.useState(false)
        , [rangeStartIndex, setRangeStartIndex] = React.useState(0)
        , [rangeEndIndex, setRangeEndIndex] = React.useState(0)
        , [exportMode, setExportMode] = React.useState("all") // "all" or "range"

    const forceUpdate = React.useCallback(() => {
        r({})
    }, [r]);

    const selectFromDate = function (date, listTemp) {
        const currentListTemp = []
        let totalTemp = 0

        for (const client of listTemp) {
            const index = client.list.findIndex((el) => new Date(el.start).getTime() === new Date(date).getTime())
            if (index !== -1) {
                totalTemp += client.list[index].prices.total
                currentListTemp.push({ client: client.client, billing: client.list[index] })
            }
        }

        setCurrentObj({ list: currentListTemp, csv: csvMultipleClients(currentListTemp) })
        setTotal(round(totalTemp))
    }

    // Handle selecting client for multi-month export
    const handleMultiMonthSelect = (clientId) => {
        setClientMultiMonthSelect(clientId);
        const selectedClient = list.find(c => c.client.clientId === clientId);
        if (selectedClient && selectedClient.list.length > 0) {
            // Set default range to include all months
            setRangeStartIndex(selectedClient.list.length - 1); // Oldest month (last in the array)
            setRangeEndIndex(0); // Newest month (first in the array)
            setExportMode("all");
        }
        setShowExportModal(true);
    }

    // Generate CSV data for all months for a client
    const generateClientMultiMonthCSV = () => {
        if (!clientMultiMonthSelect) return;

        const selectedClient = list.find(c => c.client.clientId === clientMultiMonthSelect);
        if (!selectedClient) return;

        const combinedData = [];

        // Get months for this client
        const clientMonths = selectedClient.list;
        if (clientMonths.length === 0) return;

        // Add header row (only once)
        combinedData.push(clientMonths[0].csv[0]);

        // Determine which months to include based on exportMode
        let monthsToExport = [];
        if (exportMode === "all") {
            monthsToExport = clientMonths;
        } else {
            // Range mode - get months from start to end index
            const startIndex = Math.min(rangeStartIndex, clientMonths.length - 1);
            const endIndex = Math.min(rangeEndIndex, clientMonths.length - 1);

            for (let i = endIndex; i <= startIndex; i++) {
                monthsToExport.push(clientMonths[i]);
            }
        }

        // Add data from each month
        for (const month of monthsToExport) {
            const monthData = month.csv;
            // Skip the header row
            for (let i = 1; i < monthData.length; i++) {
                combinedData.push(monthData[i]);
            }
        }

        // Fix comma to dot conversion
        for (let row of combinedData) {
            for (let i = 0; i < row.length; i++) {
                if (typeof row[i] === "string") {
                    row[i] = row[i].replace(/,/g, ".");
                }
            }
        }

        setClientMultiMonthCsv(prev => ({
            ...prev,
            [clientMultiMonthSelect]: combinedData
        }));
    }

    // Toggle between "all months" and "date range" export modes
    const toggleExportMode = () => {
        setExportMode(exportMode === "all" ? "range" : "all");
    }

    // Effect to generate CSV when export parameters change
    React.useEffect(() => {
        if (clientMultiMonthSelect) {
            generateClientMultiMonthCSV();
        }
    }, [clientMultiMonthSelect, exportMode, rangeStartIndex, rangeEndIndex]);

    // Close the modal and reset selection
    const closeExportModal = () => {
        setShowExportModal(false);
        setClientMultiMonthSelect(null);
    }

    // Get the selected client's information
    const getSelectedClient = () => {
        if (!clientMultiMonthSelect) return null;

        const clientObj = list.find(c => c.client.clientId === clientMultiMonthSelect);
        if (!clientObj) return null;

        // Get list of months for this client
        const clientMonths = clientObj.list;

        // Calculate date range based on export mode
        let firstMonth, lastMonth, monthsCount;

        if (exportMode === "all") {
            monthsCount = clientMonths.length;
            firstMonth = clientMonths.length > 0 ? toMonthYear(clientMonths[clientMonths.length - 1].start) : '';
            lastMonth = clientMonths.length > 0 ? toMonthYear(clientMonths[0].start) : '';
        } else {
            // Range mode
            const startIndex = Math.min(rangeStartIndex, clientMonths.length - 1);
            const endIndex = Math.min(rangeEndIndex, clientMonths.length - 1);
            monthsCount = startIndex - endIndex + 1;
            firstMonth = clientMonths[startIndex] ? toMonthYear(clientMonths[startIndex].start) : '';
            lastMonth = clientMonths[endIndex] ? toMonthYear(clientMonths[endIndex].start) : '';
        }

        return {
            name: clientObj.client.name,
            lastName: clientObj.client.lastName,
            monthsCount,
            firstMonth,
            lastMonth,
            months: clientMonths,
        };
    }

    if (loading
        && list.length === 0
        && userContext.loading
        && userContext.data?.people?.Client?.length > 0) {

        const listTemp = []
        const monthListTemp = []
        const dateMin = new Date(2020, 0, 1);

        for (const client of userContext.data?.people?.Client) {
            if (!client.archive && client.subscription && client.clientId !== "C-9999") {
                const obj = { client: client, ...billing(client) }
                listTemp.push(obj)
                for (const listItem of obj.list) {
                    if (!(monthListTemp.some((el) => el.value.getTime() === listItem.start.getTime())) && listItem.start.getTime() > dateMin) {
                        monthListTemp.push({ value: listItem.start, title: toMonthYear(listItem.start) })
                    }
                }
            }
        }
        monthListTemp.sort((a, b) => new Date(a.start) > new Date(b.start) ? -1 : 1)
        setMonthList(monthListTemp)
        setList(listTemp)

        selectFromDate(monthListTemp[0].value, listTemp)

        setLoading(false)
    }

    if (userContext.loading || loading) {
        return (
            <div className={"col border cardsContainer mx-1 p-2 mt-3 mt-md-0"}>
                <div className={"row justify-content-between"}>
                    <div className={"col-auto\n"}>
                        <h1>Facturation client</h1>
                    </div>
                    <div className={"col-auto\n"}>
                        <select className="form-select form-select-lg" aria-label="select" disabled>
                            <option selected>MOIS - ANNÉE</option>
                        </select>
                    </div>
                </div>
                <div className={"row justify-content-between mt-3"}>
                    <div className={"col-auto\n"}>
                        <h3>Total du mois</h3>
                        <h4>XXX.XX € HT</h4>
                        <h4>XXX.XX € TTC</h4>
                    </div>
                </div>
                <div className={"mt-3"}>
                    <h3>Détail par client</h3>
                    <Spinner strokeWidth={9} width={40} color={"#003952"} />
                </div>
            </div>
        )
    }

    for (let row of currentObj.csv) {
        for (let i = 0; i < row.length; i++) {
            if (typeof row[i] === "string") {
                row[i] = row[i].replace(/,/g, ".");
            }
        }
    }

    // Get selected client info for the modal
    const selectedClient = getSelectedClient();

    // Calculate filename based on export mode
    const getExportFilename = () => {
        if (!selectedClient) return "";

        let dateRange = "";
        if (exportMode === "range") {
            dateRange = selectedClient.firstMonth + "_a_" + selectedClient.lastMonth;
        } else {
            dateRange = "Tous_Mois";
        }

        return `${selectedClient.lastName}-${selectedClient.name}_${dateRange}.csv`;
    };

    return (
        <div className={"col border cardsContainer mx-1 p-2 mt-3 mt-md-0"}>
            <div className={"row justify-content-between"}>
                <div className={"col-auto\n"}>
                    <h1>Facturation client</h1>
                </div>
                <div className={"col-2\n"}>
                    <select className="form-select form-select-lg" aria-label="select" onChange={(e) => {
                        selectFromDate(e.target.value, list)
                    }}>
                        {monthList.map((month) => {
                            return <option value={month.value}>{month.title}</option>
                        })}
                    </select>
                </div>
            </div>
            <div className={"row justify-content-between mt-3"}>
                <div className={"col-auto\n"}>
                    <h3>Total du mois</h3>
                    <h4>{total} € HT</h4>
                    <h4>{toTTC(total)} € TTC</h4>
                </div>
                <div className={"col-2\n"}>
                    <CSVLink type={"button"} className={"btn btn-outline-tertiary form-select-lg w-100 h-"}
                        data={currentObj.csv} separator={","}
                        filename={"Total.csv"}>
                        <BsDownload className={"display-2"} />
                    </CSVLink>
                </div>
            </div>

            {/* Multi-month export modal */}
            {showExportModal && selectedClient && (
                <>
                    <div className="modal-backdrop show" style={{ display: 'block', opacity: 0.5 }}></div>
                    <div className="modal show" style={{ display: 'block', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1050 }}>
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Export multi-mois</h5>
                                    <button type="button" className="close" onClick={closeExportModal}>
                                        <BsX />
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p>Données de facturation de <strong>{selectedClient.lastName} {selectedClient.name}</strong></p>

                                    <div className="form-check form-check-inline mb-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="exportMode"
                                            id="exportModeAll"
                                            checked={exportMode === "all"}
                                            onChange={() => setExportMode("all")}
                                        />
                                        <label className="form-check-label" htmlFor="exportModeAll">
                                            Tous les mois
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline mb-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="exportMode"
                                            id="exportModeRange"
                                            checked={exportMode === "range"}
                                            onChange={() => setExportMode("range")}
                                        />
                                        <label className="form-check-label" htmlFor="exportModeRange">
                                            Sélectionner une période
                                        </label>
                                    </div>

                                    {exportMode === "range" && selectedClient.months.length > 0 && (
                                        <div className="row mb-3">
                                            <div className="col-6">
                                                <label htmlFor="startMonth">Du mois</label>
                                                <select
                                                    id="startMonth"
                                                    className="form-control"
                                                    value={rangeStartIndex}
                                                    onChange={(e) => setRangeStartIndex(parseInt(e.target.value))}
                                                >
                                                    {selectedClient.months.map((month, index) => (
                                                        <option key={`start-${index}`} value={index} disabled={index < rangeEndIndex}>
                                                            {toMonthYear(month.start)}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-6">
                                                <label htmlFor="endMonth">Au mois</label>
                                                <select
                                                    id="endMonth"
                                                    className="form-control"
                                                    value={rangeEndIndex}
                                                    onChange={(e) => setRangeEndIndex(parseInt(e.target.value))}
                                                >
                                                    {selectedClient.months.map((month, index) => (
                                                        <option key={`end-${index}`} value={index} disabled={index > rangeStartIndex}>
                                                            {toMonthYear(month.start)}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    )}

                                    <div className="card bg-light mb-3">
                                        <div className="card-body">
                                            <h6 className="card-title">Détails de l'export</h6>
                                            <ul className="list-unstyled">
                                                <li><strong>Nombre de mois:</strong> {selectedClient.monthsCount}</li>
                                                <li><strong>Période:</strong> {selectedClient.firstMonth} à {selectedClient.lastMonth}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={closeExportModal}>
                                        Annuler
                                    </button>
                                    {clientMultiMonthCsv[clientMultiMonthSelect] && (
                                        <CSVLink
                                            type={"button"}
                                            className={"btn btn-primary"}
                                            data={clientMultiMonthCsv[clientMultiMonthSelect]}
                                            separator={","}
                                            filename={getExportFilename()}
                                            onClick={closeExportModal}
                                        >
                                            <BsDownload /> Exporter {exportMode === "all" ? "tous les mois" : "la période"}
                                        </CSVLink>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

            <div className={"mt-3"}>
                <h3>Détail par client</h3>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                            <th className={"text-center"}>Montant facturé (€ HT)</th>
                            <th className={"text-end"}>action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentObj.list.sort((a, b) => a.client.lastName > b.client.lastName ? 1 : -1).map((client) => {
                            if (client.client.archive) return null
                            if (!client.client.subscription) return null

                            const clientId = client.client.clientId;

                            for (let row of client.billing.csv) {
                                for (let i = 0; i < row.length; i++) {
                                    if (typeof row[i] === "string") {
                                        row[i] = row[i].replace(/,/g, ".");
                                    }
                                }
                            }

                            return (
                                <tr key={client.client.lastName + "-" + client.client.name}>
                                    <td>{client.client.lastName}</td>
                                    <td>{client.client.name}</td>
                                    <td className={"text-center"}>{round(client.billing.prices.total)}</td>
                                    <td className={"text-end"}>
                                        <div className="d-flex justify-content-end align-items-center">
                                            <button type={"button"} className={"btn btn-light mr-1"} onClick={() => {
                                                const object = client.client
                                                object.choices = "ClientInvoices"
                                                setItem(object)
                                            }}>
                                                <BsPlus />
                                            </button>

                                            {/* Regular month export */}
                                            <CSVLink type={"button"} className={"btn btn-light mx-1"}
                                                data={client.billing.csv}
                                                filename={
                                                    client.client.lastName
                                                    + "-"
                                                    + client.client.name
                                                    + "_"
                                                    + nameOfMonth(client.billing.start)
                                                    + "-"
                                                    + client.billing.start.getFullYear()
                                                    + ".csv"
                                                }>
                                                <BsDownload />
                                            </CSVLink>

                                            {/* Multi-month export button */}
                                            <button
                                                type={"button"}
                                                className={"btn btn-light mx-1"}
                                                onClick={() => handleMultiMonthSelect(clientId)}
                                                title="Export multi-mois"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                            >
                                                <BsCalendar />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <Overlay item={item}
                close={() => {
                    setItem(false)
                }}
                edit={(value) => {
                    setItem(value)
                    forceUpdate()
                }} />
        </div>
    )
}