import { useState, useRef, useContext } from "react";
import Spinner from "../../../Components/Spinner";
import { PopUp } from "../Contacts"; // Import the PopUp component from Contacts.js, not from PopUp.js
import UserContext from "../../../dataContext"; // Import UserContext
import PropTypes from "prop-types";

export default function ClientPopUp({
    showClientPopUp,
    setShowClientPopUp = () => { },
    contacts = [],
    setClientId = () => { },
    setSiret = () => { },
    setAddress = () => { },
    setPayingMethod = () => { },
    setFullAddress = () => { },
    setFullSecondAddress = () => { },
    setBillingAddress = () => { },
    setIsTheSameAsFullAddress = () => { },
    setFullName = () => { },
    setClientMail = () => { },
    handleModif = () => { },
    userContext,
    setShowPopUp = () => { }
}) {
    // If userContext is not provided as a prop, get it from the context
    const contextFromHook = useContext(UserContext);
    const effectiveUserContext = userContext || contextFromHook;

    const [searchTerm, setSearchTerm] = useState("");
    const [showCreateClientPopUp, setShowCreateClientPopUp] = useState(false);
    const [popUpContent, setPopUpContent] = useState(null);
    const [hasLoadedClients, setHasLoadedClients] = useState(false);
    const contactPopUp = useRef(null);

    const handleCreateClientClick = () => {
        if (!effectiveUserContext) {
            console.error("Cannot create client: userContext is undefined");
            alert("Cannot create client: System context is unavailable. Please try again later.");
            return;
        }

        setPopUpContent({
            what: { type: "Contact", title: "Créer un contact" },
            thing: { object: {} }
        });
        setShowCreateClientPopUp(true);
    };

    const handleClientSelection = (contact) => {
        try {
            if (typeof setClientId === 'function') setClientId(contact.clientId);
            if (typeof setSiret === 'function') setSiret(contact.siret);

            if (typeof setAddress === 'function') {
                setAddress({
                    roadAndNumber: contact.fullAddress?.roadAndNumber || "",
                    zipCode: contact.fullAddress?.zipCode || "",
                    city: contact.fullAddress?.city || "",
                    country: contact.fullAddress?.country || "",
                    complement: contact.fullAddress?.complement || false
                });
            }

            if (typeof setPayingMethod === 'function') setPayingMethod(contact.paymentMethod);

            if (typeof setFullAddress === 'function') {
                setFullAddress({
                    roadAndNumber: contact.fullAddress?.roadAndNumber || "",
                    zipCode: contact.fullAddress?.zipCode || "",
                    city: contact.fullAddress?.city || "",
                    country: contact.fullAddress?.country || "",
                    complement: contact.fullAddress?.complement || false
                });
            }

            if (typeof setFullSecondAddress === 'function') {
                setFullSecondAddress({
                    roadAndNumber: contact.fullSecondAddress?.roadAndNumber || "",
                    zipCode: contact.fullSecondAddress?.zipCode || "",
                    city: contact.fullSecondAddress?.city || "",
                    country: contact.fullSecondAddress?.country || "",
                    complement: contact.fullSecondAddress?.complement || false
                });
            }

            if (typeof setBillingAddress === 'function') {
                setBillingAddress({
                    roadAndNumber: contact.billingAddress?.roadAndNumber || "",
                    zipCode: contact.billingAddress?.zipCode || "",
                    city: contact.billingAddress?.city || "",
                    country: contact.billingAddress?.country || "",
                    complement: contact.billingAddress?.complement || false,
                    isTheSameAsFullAddress: contact.billingAddress?.isTheSameAsFullAddress || false
                });
            }

            if (typeof setIsTheSameAsFullAddress === 'function') setIsTheSameAsFullAddress(contact.billingAddress?.isTheSameAsFullAddress);

            if (typeof setFullName === 'function') {
                setFullName(
                    contact.status !== "Personne Morale"
                        ? `${contact.lastName || ''} ${contact.name || ''}`
                        : contact.socialReason || ''
                );
            }

            if (typeof setClientMail === 'function') setClientMail(contact.email);

            // Close the client popup immediately
            if (typeof setShowClientPopUp === 'function') setShowClientPopUp(false);
        } catch (error) {
            console.error("Error in handleClientSelection:", error);
            // Consider adding a user-friendly error notification here
        }
    };

    const wrappedHandleModif = async (newClient, oldClient, type) => {
        try {
            // Validate inputs
            if (!newClient) {
                throw new Error('New client data is required');
            }

            // Call the original handleModif with proper action type
            if (typeof handleModif === 'function') {
                const response = await handleModif(newClient, 'addClient');

                // If we got a successful response, handle the client selection
                if (response && type === "addClient") {
                    if (typeof handleClientSelection === 'function') {
                        handleClientSelection(response);
                    }
                }
            }

            // Close the create client popup
            if (typeof setShowCreateClientPopUp === 'function') {
                setShowCreateClientPopUp(false);
            }

            // Close the main client popup
            if (typeof setShowClientPopUp === 'function') {
                setShowClientPopUp(false);
            }

            // Show success message
            if (effectiveUserContext) {
                effectiveUserContext.setError({
                    level: 'success',
                    message: "Le client a été créé avec succès",
                    btn1Text: 'OK',
                    btn2hidden: true
                });
            }
        } catch (error) {
            console.error("Error in wrappedHandleModif:", error);
            effectiveUserContext.setError({
                level: 'error',
                message: "Une erreur est survenue lors de la création du client: " + (error.message || "Erreur inconnue"),
                btn1Text: 'OK',
                btn2hidden: true
            });
            throw error; // Re-throw to allow proper error handling up the chain
        }
    };

    const filteredContacts = contacts.filter(contact => {
        if (!contact || !searchTerm) return true;

        const searchTermLower = searchTerm.toLowerCase().trim();

        // Create an array of all searchable fields with their values
        const searchableFields = [
            contact.clientId,
            contact.name,
            contact.email,
            contact.socialReason,
            contact.lastName,
            contact.fullAddress?.city,
            contact.fullAddress?.zipCode,
            contact.fullAddress?.roadAndNumber,
            contact.phone,
            contact.phone2
        ];

        // Check if any field contains the search term
        return searchableFields.some(field =>
            field && field.toString().toLowerCase().includes(searchTermLower)
        );
    });

    // Update hasLoadedClients when contacts are loaded
    if (contacts.length > 0 && !hasLoadedClients) {
        setHasLoadedClients(true);
    }

    // Show loading state only if we haven't loaded any clients yet
    if (contacts.length === 0 && !hasLoadedClients) return (
        <div className={'billing-popup' + (showClientPopUp ? " open" : "")} onClick={(e) => {
            if (e.target.classList.contains('billing-popup')) {
                setShowClientPopUp(false);
            }
        }}>
            <div className="container">
                <h3>Clients</h3>
                <h2>Chargement des clients...</h2>
                <Spinner strokeWidth={9} width={40} color={"#003952"} />
            </div>
        </div>
    )

    // If we've loaded clients before but now contacts is empty, show empty state instead of loading
    if (contacts.length === 0 && hasLoadedClients) return (
        <div className={'billing-popup' + (showClientPopUp ? " open" : "")} onClick={(e) => {
            if (e.target.classList.contains('billing-popup')) {
                setShowClientPopUp(false);
            }
        }}>
            <div className="container">
                <h3>Clients</h3>
                <h2>Aucun client trouvé</h2>
                <button className="btn btn-secondary mt-2" onClick={handleCreateClientClick}>
                    + Ajouter un nouveau client
                </button>
            </div>
        </div>
    )

    return (
        <>
            <div ref={contactPopUp} className={'billing-popup' + (showClientPopUp ? " open" : "")} onClick={(e) => {
                if (e.target.classList.contains('billing-popup')) {
                    setShowClientPopUp(false);
                }
            }}>
                <div className="container contactsPopUp" onClick={(e) => e.stopPropagation()}>
                    <h3>Clients</h3>
                    <input
                        type="text"
                        placeholder="Rechercher un client..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="form-control mb-3"
                    />
                    <button className="btn btn-secondary mt-2" onClick={handleCreateClientClick}>
                        + Ajouter un nouveau client
                    </button>

                    <table className="table table-hover mt-3">
                        <thead>
                            <tr>
                                <th>Code</th>
                                <th>Nom</th>
                                <th>Prénom</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredContacts.map((contact, index) => (
                                <tr
                                    key={index}
                                    onClick={() => {
                                        handleClientSelection(contact)
                                    }}
                                >
                                    <td>{contact.clientId}</td>
                                    {contact.status === "Personne morale" ? (
                                        <>
                                            <td><strong>{contact.socialReason}</strong></td>
                                            <td><strong>{contact.formeJuridique}</strong></td>
                                        </>
                                    ) : (
                                        <>
                                            <td><strong>{contact.lastName}</strong></td>
                                            <td><strong>{contact.name}</strong></td>
                                        </>
                                    )}
                                    <td>{contact.email}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {showCreateClientPopUp && popUpContent && effectiveUserContext && (
                <PopUp
                    showPopUp={showCreateClientPopUp}
                    setShowPopUp={setShowCreateClientPopUp}
                    popUpContent={popUpContent}
                    userContext={effectiveUserContext}
                    handleModif={wrappedHandleModif}
                    apiActionOut={"addClient"}
                    setShowClientPopUp={setShowClientPopUp}
                    handleClientSelection={handleClientSelection}
                />
            )}
        </>
    );
}

ClientPopUp.propTypes = {
    setShowClientPopUp: PropTypes.func,
    setShowPopUp: PropTypes.func,
    setClientId: PropTypes.func,
    setSiret: PropTypes.func,
    setAddress: PropTypes.func,
    setPayingMethod: PropTypes.func,
    setFullAddress: PropTypes.func,
    setFullSecondAddress: PropTypes.func,
    setBillingAddress: PropTypes.func,
    setIsTheSameAsFullAddress: PropTypes.func,
    setFullName: PropTypes.func,
    setClientMail: PropTypes.func,
    userContext: PropTypes.object
};