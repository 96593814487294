import { useContext, useEffect, useState } from 'react'
import UserContext from '../../dataContext'
import Spinner from '../../Components/Spinner'
import "../../assets/scss/Billing/products.scss"
import "../../assets/scss/Billing/billingsPages.scss"
import { BsCloudDownloadFill, BsCloudUploadFill, BsFillPencilFill, BsXLg } from 'react-icons/bs'
import PopUp from './Common/PopUp/PopUp'
import { Parser } from 'json2csv';
import ProductTable from './Common/PopUp/Products/ProductTable'

const ITEMS_PER_PAGE = 15;

export default function Products() {
    const userContext = useContext(UserContext)
    const [loading, setLoading] = useState(true)
    const [products, setProducts] = useState([])
    const [activeSidebar, setActiveSidebar] = useState(false)
    const [sidebarContent, setSidebarContent] = useState(null)
    const [search, setSearch] = useState("")
    const [showPopUp, setShowPopUp] = useState(false)
    const [action, setAction] = useState("")
    const [popUpContent, setPopUpContent] = useState("")
    const [inactiveProducts, setInactiveProducts] = useState([])
    const [activeProducts, setActiveProducts] = useState([])
    const [activeCurrentPage, setActiveCurrentPage] = useState(1);
    const [inactiveCurrentPage, setInactiveCurrentPage] = useState(1);
    const [document, setDocument] = useState([]);

    function convertToCSV(data) {
        const fields = [
            { label: 'Code', value: 'code' },
            { label: 'Libellé', value: 'label' },
            { label: 'Famille', value: 'family' },
            { label: 'Type', value: 'type' },
            { label: 'Prix', value: 'price' },
            { label: 'Prix Total', value: 'priceFull' },
            { label: 'Quantité', value: 'quantity' },
            { label: 'TVA', value: 'taxes' },
            { label: 'Compte comptable', value: 'account' },
            { label: 'Description', value: 'description' },
            { label: 'État', value: 'state' }
        ];
        const json2csvParser = new Parser({ fields, withBOM: true });
        return json2csvParser.parse(data);
    }

    function downloadCSV(csv, filename) {
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    useEffect(() => {
        userContext.apiReducer("getProducts").then(productsList => {
            setLoading(false)
            productsList = sortProducts(productsList, "abc", "code")
            setProducts(productsList)
        })
    }, [userContext])

    useEffect(() => {
        if (products.length > 0) {
            setActiveProducts(products.filter(p => !p.inactive));
            setInactiveProducts(products.filter(p => p.inactive));
        }
    }, [products])

    const handleProductUpdate = (modifiedProduct) => {
        const updatedProducts = products.map(p =>
            p._id === modifiedProduct._id ? modifiedProduct : p
        );
        setProducts(updatedProducts);

        if (modifiedProduct.inactive) {
            setActiveProducts(prev => prev.filter(p => p._id !== modifiedProduct._id));
            setInactiveProducts(prev => [...prev, modifiedProduct]);
        } else {
            setInactiveProducts(prev => prev.filter(p => p._id !== modifiedProduct._id));
            setActiveProducts(prev => [...prev, modifiedProduct]);
        }
    };

    const handleProductAdd = (newProduct) => {
        setProducts(prev => [...prev, newProduct]);
        if (newProduct.inactive) {
            setInactiveProducts(prev => [...prev, newProduct]);
        } else {
            setActiveProducts(prev => [...prev, newProduct]);
        }
    };

    const deleteProduct = async (productId) => {
        try {
            // Delete product logic
            // ...
        } catch (error) {
            console.error("Error deleting product:", error);
            userContext.setError({
                level: 'error',
                message: 'Erreur lors de la suppression du produit',
                btn1Text: 'Fermer',
                btn2hidden: true
            });
        }
    };

    function sortProducts(list, method, key) {
        switch (method) {
            case "abc":
                if (key) {
                    list.sort((a, b) => {
                        if (a[key] < b[key]) return -1;
                        if (a[key] > b[key]) return 1;
                        return 0;
                    });
                }
                break;

            default:
                break;
        }
        return list
    }

    function capitalizeText(text) {
        return text.toLowerCase().replace(/\b\w/g, function (char) {
            return char.toUpperCase();
        });
    }

    // Calculate paginated active products
    const filteredActiveProducts = activeProducts?.filter(product =>
        search ? (
            product.label?.toLowerCase().includes(search.toLowerCase()) ||
            product.family?.toLowerCase().includes(search.toLowerCase()) ||
            product.type?.toLowerCase().includes(search.toLowerCase())
        ) : true
    );

    const activeTotalPages = Math.ceil(filteredActiveProducts.length / ITEMS_PER_PAGE);
    const paginatedActiveProducts = filteredActiveProducts.slice(
        (activeCurrentPage - 1) * ITEMS_PER_PAGE,
        activeCurrentPage * ITEMS_PER_PAGE
    );

    // Calculate paginated inactive products
    const filteredInactiveProducts = inactiveProducts?.filter(product =>
        search ? (
            product.label?.toLowerCase().includes(search.toLowerCase()) ||
            product.family?.toLowerCase().includes(search.toLowerCase()) ||
            product.type?.toLowerCase().includes(search.toLowerCase())
        ) : true
    );

    const inactiveTotalPages = Math.ceil(filteredInactiveProducts.length / ITEMS_PER_PAGE);
    const paginatedInactiveProducts = filteredInactiveProducts.slice(
        (inactiveCurrentPage - 1) * ITEMS_PER_PAGE,
        inactiveCurrentPage * ITEMS_PER_PAGE
    );

    // Reset pagination when search changes
    useEffect(() => {
        setActiveCurrentPage(1);
        setInactiveCurrentPage(1);
    }, [search]);

    if (loading) {
        return (
            <div className={"col cardsContainer mx-1 p-2 mt-3 mt-md-0"}>
                <div className={"row justify-content-between"}>
                    <div>
                        <h1 className="blue fw-bold text-center">Produits</h1>
                    </div>
                </div>
                <Spinner strokeWidth={9} width={40} color={"#003952"} />
                <h2 className="text-center">Chargement des produits...</h2>
            </div>
        )
    }

    return (
        <div className={"col cardsContainer mx-1 p-2 mt-3 mt-md-0 products"}>
            <div className={"row justify-content-between"}>
                <div>
                    <h1 className="blue fw-bold text-center">Produits</h1>
                </div>
            </div>
            <div className="search-and-stuff d-flex justify-content-between align-items-center">
                <div className="search">
                    <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Rechercher un produit..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
                <button
                    className="btn btn-primary nice-button"
                    onClick={() => {
                        setShowPopUp(true);
                        setAction("addProduct");
                        setPopUpContent({ what: { type: "Produit", title: "Créer un produit" }, thing: { object: {} } });
                    }}
                >+</button>
                <br />

            </div>

            <div className="active-products mb-5">
                <h3 className="blue mt-4">Produits Actifs ({filteredActiveProducts.length})</h3>

                <table className='table-maxxing'>
                    <thead>
                        <tr>
                            <th scope="col"><span>Code</span></th>
                            <th scope="col"><span>Libellé</span></th>
                            <th scope="col"><span>Famille</span></th>
                            <th scope="col"><span>Type</span></th>
                            <th scope="col"><span>Prix HT</span></th>
                            <th scope="col"><span>Prix TTC</span></th>
                            <th scope="col"><span>Actions</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedActiveProducts.map((product, index) => (
                            <tr key={product._id || index}>
                                <td>{String(product.code).padStart(7, '0')}</td>
                                <td><strong>{product.label}</strong></td>
                                <td>{product.family}</td>
                                <td>{product.type}</td>
                                <td>{parseFloat(product.price || 0).toFixed(2)}€</td>
                                <td>{parseFloat(product.priceFull || 0).toFixed(2)}€</td>
                                <td>
                                    <div className="d-flex justify-content-end">
                                        <button
                                            className="btn btn-sm btn-outline-primary mx-1"
                                            title="Modifier"
                                            onClick={() => {
                                                setShowPopUp(true);
                                                setAction("modifyProduct");
                                                setPopUpContent({ what: { type: "Produit", title: "Modifier un produit" }, thing: { object: product } });
                                            }}
                                        >
                                            <BsFillPencilFill className="billsActionsIcon" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {filteredActiveProducts.length > 0 && (
                    <div className="pagination d-flex justify-content-center align-items-center gap-2 mt-3">
                        <button
                            className="btn btn-primary"
                            disabled={activeCurrentPage === 1}
                            onClick={() => setActiveCurrentPage(prev => Math.max(1, prev - 1))}
                        >
                            Précédent
                        </button>
                        <span>Page {activeCurrentPage} of {activeTotalPages}</span>
                        <button
                            className="btn btn-primary"
                            disabled={activeCurrentPage === activeTotalPages}
                            onClick={() => setActiveCurrentPage(prev => Math.min(activeTotalPages, prev + 1))}
                        >
                            Suivant
                        </button>
                    </div>
                )}
            </div>

            <div className="inactive-products">
                <h3 className="blue mt-4">Produits Inactifs ({filteredInactiveProducts.length})</h3>
                <table className='table-maxxing'>
                    <thead>
                        <tr>
                            <th scope="col"><span>Code</span></th>
                            <th scope="col"><span>Libellé</span></th>
                            <th scope="col"><span>Famille</span></th>
                            <th scope="col"><span>Type</span></th>
                            <th scope="col"><span>Prix HT</span></th>
                            <th scope="col"><span>Prix TTC</span></th>
                            <th scope="col"><span>Actions</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedInactiveProducts.map((product, index) => (
                            <tr key={product._id || index}>
                                <td>{product.code}</td>
                                <td>{product.label}</td>
                                <td>{product.family}</td>
                                <td>{product.type}</td>
                                <td>{parseFloat(product.price || 0).toFixed(2)}€</td>
                                <td>{parseFloat(product.priceFull || 0).toFixed(2)}€</td>
                                <td>
                                    <div className="d-flex justify-content-end">
                                        <button
                                            className="btn btn-sm btn-outline-primary mx-1"
                                            title="Modifier"
                                            onClick={() => {
                                                setShowPopUp(true);
                                                setAction("modifyProduct");
                                                setPopUpContent({ what: { type: "Produit", title: "Modifier un produit" }, thing: { object: product } });
                                            }}
                                        >
                                            <BsFillPencilFill className="billsActionsIcon" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {filteredInactiveProducts.length > 0 && (
                    <div className="pagination d-flex justify-content-center align-items-center gap-2 mt-3">
                        <button
                            className="btn btn-primary"
                            disabled={inactiveCurrentPage === 1}
                            onClick={() => setInactiveCurrentPage(prev => Math.max(1, prev - 1))}
                        >
                            Précédent
                        </button>
                        <span>Page {inactiveCurrentPage} of {inactiveTotalPages}</span>
                        <button
                            className="btn btn-primary"
                            disabled={inactiveCurrentPage === inactiveTotalPages}
                            onClick={() => setInactiveCurrentPage(prev => Math.min(inactiveTotalPages, prev + 1))}
                        >
                            Suivant
                        </button>
                    </div>
                )}
            </div>

            <div id='product-sidebar' className={'col' + (activeSidebar ? " active" : "")}>
                <div className='header'>
                    <button className="close-sidebar" onClick={() => setActiveSidebar(false)}><BsXLg /></button>
                    <button className="edit-sidebar" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowPopUp(true)
                        setAction("modifyProduct")
                        const product = { ...sidebarContent.thing }
                        setPopUpContent({ what: { type: "Produits", title: "Modifier un produit" }, thing: { product } })
                    }}><BsFillPencilFill /></button>


                    {sidebarContent ? <h2>{sidebarContent.what}</h2> : ""}
                </div>
                {sidebarContent ?
                    <div className='content'>
                        <div className='row'>
                            <div className='col-12'>
                                <h2>{sidebarContent.thing.code + " - " + sidebarContent.thing.label}</h2>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <ul>
                                    <li><span>Description : </span>{sidebarContent.thing.description}</li>
                                    <li><span>Type :</span> {capitalizeText(sidebarContent.thing.type)}</li>
                                    <li><span>Famille :</span> {sidebarContent.thing.family}</li>
                                    <li><span>Prix HT :</span> {sidebarContent.thing.price}€</li>
                                    <li><span>Prix TTC :</span> {sidebarContent.thing.priceFull}€</li>
                                    <li><span>TVA :</span> {sidebarContent.thing.taxes}% </li>
                                    <li><span>Unité :</span> {sidebarContent.thing.quantity}</li>
                                    <li><span>Compte comptable :</span> {sidebarContent.thing.account}</li>
                                    <li><span>Actif :</span> {sidebarContent.thing.state === "ACTIF" ? "Oui" : "Non"}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    : ""}
            </div>
            <PopUp showPopUp={showPopUp} setShowPopUp={setShowPopUp} action={action} popUpContent={popUpContent} type={"product"} setProducts={setProducts} handleProductUpdate={handleProductUpdate} document={document} setDocument={setDocument} userContext={userContext} setReference={() => { }} setSiret={() => { }} setAddress={() => { }} setPayingMethod={() => { }} setBillingAddress={() => { }} setFullAddress={() => { }} setFullSecondAddress={() => { }} setIsTheSameAsFullAddress={() => { }} setFullName={() => { }} setClientMail={() => { }} setClientId={() => { }} reference="" siret="" address="" payingMethod="" billingAddress={{}} fullAddress="" fullSecondAddress="" isTheSameAsFullAddress={false} fullName="" clientMail="" clientId="" onUpdate={() => { }} />
        </div>
    )
}