// External components
import React from "react";
import Image from "react-image-webp";

// Internal components
import MenuLink from "./MenuComponents/MenuLink";
import MenuListLink from "./MenuComponents/MenuListLink";

// Images
import "../../assets/scss/sidebar.scss"

export default function SideBar(props) {
    const concernedElement = document.querySelector("#sidebar")


    document.addEventListener("mousedown", (event) => {
        if (concernedElement) {
            if (!concernedElement.contains(event.target) && props.active.active) {
                props.active.setActive(false)
            }
        }
    });

    return (
        <nav id="sidebar" className={props.active.active ? "active col" : "col"}>
            <div className="sidebar-header row justify-content-center gx-0">
                <Image webp={"https://i.ibb.co/3CgD7G4/new-Colors-Logo-2.webp"} src={"https://i.ibb.co/3CgD7G4/new-Colors-Logo-2.webp"} alt={"logo"} className={"col-6"} />
                <div className={"col-12"}>
                    <h3>MYPacôme</h3>
                </div>
            </div>
            <ul className="list-unstyled components">
                <MenuLink icon={"BsHouse"} reference={"/"} name={"Dashboard"} />
                <MenuLink icon={"BsCalendar"} reference={"/planning"} name={"Planning"} />
                <MenuLink icon={"BsPeople"} reference={"/clients"} name={"Clients"} />
                <MenuListLink icon={"BsFilePerson"} name={"RH"} links={[
                    { reference: "/concierges", name: "Concierges" },
                    { reference: "/team", name: "Équipe interne" },
                    { reference: "/providers", name: "Prestataires" },
                ]} />
                <MenuLink icon={"BsFileSpreadsheet"} reference={"/billing"} name={"Facturation"} />
                <MenuLink icon={"BsArchive"} reference={"/archives"} name={"Archives"} />
                <MenuLink icon={"BsMap"} reference={"/map"} name={"Carte"} />
                <MenuLink icon={"BsPinMap"} reference={"/secteurs"} name={"Secteurs"} />
                <MenuLink icon={"BsWrench"} reference={"/gestion"} name={"Gestion"} />
                <MenuListLink icon={"BsFileSpreadsheet"} name={"Ventes"} links={[
                    { reference: "/billing/products", name: "Produits" },
                    { reference: "/billing/contacts", name: "Clients" },
                    { reference: "/billing/invoices", name: "Devis" },
                    { reference: "/billing/bills", name: "Factures" },
                ]} />
            </ul>
        </nav>
    )
}