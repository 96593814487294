import { useContext, useEffect, useState, useCallback } from "react";
import UserContext from "../../../../../dataContext";
import Spinner from "../../../../../Components/Spinner";

export default function ProductPopUpForm({ action, thing, reset, setIsSubmitting, setShowPopUp, setProducts }) {
    const userContext = useContext(UserContext);
    // Initialize all state values from thing.object
    const [label, setLabel] = useState(thing?.object?.label || "");
    const [family, setFamily] = useState(thing?.object?.family || "");
    const [type, setType] = useState(thing?.object?.type || "");
    const [priceFull, setPriceFull] = useState(thing?.object?.priceFull || "");
    const [price, setPrice] = useState(thing?.object?.price || "");
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState(thing?.object?.code || "");
    const [quantity, setQuantity] = useState(thing?.object?.quantity || "");
    const [taxes, setTaxes] = useState(thing?.object?.taxes || "");
    const [account, setAccount] = useState(thing?.object?.account || "");
    const [description, setDescription] = useState(thing?.object?.description || "");
    const [state, setState] = useState(thing?.object?.state || "");
    const product = thing?.product;
    console.log(thing, "thing")

    const resetFormFields = useCallback(() => {
        // Make sure we populate all fields from thing.object
        if (thing?.object) {
            setLabel(thing.object.label || "");
            setFamily(thing.object.family || "");
            setType(thing.object.type || "");
            setPriceFull(thing.object.priceFull || "");
            setPrice(thing.object.price || "");
            setCode(thing.object.code || "");
            setQuantity(thing.object.quantity || "");
            setTaxes(thing.object.taxes || "");
            setAccount(thing.object.account || "");
            setDescription(thing.object.description || "");
            setState(thing.object.state || "");
        } else {
            setLabel("");
            setFamily("");
            setType("");
            setPriceFull("");
            setPrice("");
            setCode("");
            setQuantity("");
            setTaxes("");
            setAccount("");
            setDescription("");
            setState("");
        }
    }, [thing]);

    useEffect(() => {
        resetFormFields();
    }, [thing, resetFormFields]);

    // Additional effect to debug thing.object changes
    useEffect(() => {
        if (thing?.object) {
            console.log("Product data received:", thing.object);
        }
    }, [thing]);

    const fetchAndSetCode = useCallback(async () => {
        try {
            setLoading(true);
            const newCode = await userContext.apiReducer("getProductCode");
            setCode(newCode);
        } catch (error) {
            console.error("Error fetching product code:", error);
            userContext.setError({
                level: 'warning',
                message: 'Erreur lors de la récupération du code produit',
                btn1Text: 'Fermer',
                btn2hidden: true
            });
        } finally {
            setLoading(false);
        }
    }, [userContext]);

    useEffect(() => {
        if (action === "addProduct" || thing.new) {
            fetchAndSetCode();
        } else if (action === "modifyProduct") {
            resetFormFields();
        }
    }, [action, thing.new, fetchAndSetCode, resetFormFields]);

    useEffect(() => {
        if (thing?.new) {
            resetFormFields();
        }
    }, [thing, resetFormFields]);

    useEffect(() => {
        const numericPrice = parseFloat(price);
        const numericTaxes = parseFloat(taxes);
        if (!isNaN(numericPrice) && !isNaN(numericTaxes)) {
            const calculatedPriceFull = (numericPrice + (numericPrice * numericTaxes) / 100).toFixed(2);
            setPriceFull(calculatedPriceFull);
        }
    }, [price, taxes]);

    useEffect(() => {
        if (price && taxes) {
            const numericPrice = parseFloat(price);
            const numericTaxes = parseFloat(taxes);
            if (!isNaN(numericPrice) && !isNaN(numericTaxes)) {
                const calculatedPriceFull = (numericPrice + (numericPrice * numericTaxes) / 100).toFixed(2);
                if (priceFull && priceFull !== calculatedPriceFull) {
                    userContext.setError({
                        level: 'warning',
                        message: 'Attention : Le prix TTC ne correspond pas au calcul automatique',
                        btn1Text: 'Fermer',
                        btn2hidden: true
                    });
                }
            }
        }
    }, [price, taxes, priceFull, userContext]);

    const validateForm = () => {
        if (!label.trim()) {
            userContext.setError({
                level: 'warning',
                message: 'Le libellé du produit est requis',
                btn1Text: 'Fermer',
                btn2hidden: true
            });
            return false;
        }
        if (!family.trim()) {
            userContext.setError({
                level: 'warning',
                message: 'La famille du produit est requise',
                btn1Text: 'Fermer',
                btn2hidden: true
            });
            return false;
        }
        if (!type.trim()) {
            userContext.setError({
                level: 'warning',
                message: 'Le type du produit est requis',
                btn1Text: 'Fermer',
                btn2hidden: true
            });
            return false;
        }
        if (!priceFull.trim() || isNaN(parseFloat(priceFull))) {
            userContext.setError({
                level: 'warning',
                message: 'Le prix complet doit être un nombre valide',
                btn1Text: 'Fermer',
                btn2hidden: true
            });
            return false;
        }
        if (!price.trim() || isNaN(parseFloat(price))) {
            userContext.setError({
                level: 'warning',
                message: 'Le prix doit être un nombre valide',
                btn1Text: 'Fermer',
                btn2hidden: true
            });
            return false;
        }
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        if (!validateForm()) {
            setIsSubmitting(false);
            return;
        }

        // Validate price calculations
        const numericPrice = parseFloat(price);
        const numericTaxes = parseFloat(taxes);
        const calculatedPriceFull = (numericPrice + (numericPrice * numericTaxes) / 100).toFixed(2);

        if (calculatedPriceFull !== priceFull) {
            userContext.setError({
                level: 'warning',
                message: 'Le prix TTC calculé ne correspond pas au prix TTC saisi',
                btn1Text: 'Fermer',
                btn2hidden: true
            });
            setIsSubmitting(false);
            return;
        }

        const data = {
            code,
            label,
            family,
            type,
            price: price.toString(),
            priceFull: priceFull.toString(),
            quantity,
            taxes: taxes.toString(),
            account,
            description,
            state: state || "ACTIF"
        };

        if (action === "addProduct") {
            userContext.apiReducer("addProduct", data)
                .then((response) => {
                    setProducts((prevProducts) => [...prevProducts, response]);
                    userContext.setError({
                        level: 'success',
                        message: 'Produit ajouté avec succès',
                        btn1Text: 'Fermer',
                        btn2hidden: true
                    });
                    setShowPopUp(false);
                })
                .catch((error) => {
                    console.error("Error adding product:", error);
                    userContext.setError({
                        level: 'warning',
                        message: `Erreur lors de l'ajout du produit : ${error.message || 'Erreur inconnue'}`,
                        btn1Text: 'Fermer',
                        btn2hidden: true
                    });
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        } else if (action === "modifyProduct") {
            userContext.apiReducer("modifyProduct", data)
                .then((response) => {
                    setProducts((prevProducts) =>
                        prevProducts.map((product) =>
                            product.code === code ? response : product
                        )
                    );
                    userContext.setError({
                        level: 'success',
                        message: 'Produit modifié avec succès',
                        btn1Text: 'Fermer',
                        btn2hidden: true
                    });
                    setShowPopUp(false);
                })
                .catch((error) => {
                    console.error("Error modifying product:", error);
                    userContext.setError({
                        level: 'warning',
                        message: `Erreur lors de la modification du produit : ${error.message || 'Erreur inconnue'}`,
                        btn1Text: 'Fermer',
                        btn2hidden: true
                    });
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        }
    };


    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div>
                    <div className="mb-3 single-line">
                        <div className="d-flex line-flex">
                            <div className="me-3 w-25 position-relative">
                                <label htmlFor="code" className="form-label required">Code :</label>
                                <input
                                    required
                                    type="text"
                                    className="form-control"
                                    id="code"
                                    value={code.toString().padStart(8, '0')}
                                    onChange={(e) => setCode(e.target.value)}
                                    disabled={!code}
                                />
                                {!code && (
                                    <div className=" position-absolute top-50 end-0 translate-middle-y" role="status">
                                        <Spinner strokeWidth={6} width={20} color={"#003952"} />
                                    </div>
                                )}
                            </div>
                            <div className="me-3">
                                <label htmlFor="label" className="form-label">Libellé :</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="label"
                                    value={label}
                                    onChange={(e) => setLabel(e.target.value)} />
                            </div>
                            <div className="me-3 w-25">
                                <label htmlFor="family" className="form-label">Famille :</label>
                                <select
                                    className="form-control"
                                    id="family"
                                    value={family || "Particuliers"}
                                    onChange={(e) => setFamily(e.target.value)}
                                    required>
                                    <option selected value="Particuliers">Particuliers</option>
                                    <option value="Gestion Locative">Gestion Locative</option>
                                    <option value="Professionnels">Professionnels</option>
                                    <option value="Propriétaire">Propriétaire</option>
                                    <option value="Interne">Interne</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="mb-3 single-line">
                        <div className="d-flex line-flex">
                            <div className="me-3">
                                <label htmlFor="type" className="form-label">Type :</label>
                                <select
                                    className="form-control"
                                    id="type"
                                    value={type || "Produit"}
                                    onChange={(e) => setType(e.target.value)}
                                    required>
                                    <option selected value="Produit">Produit</option>
                                    <option value="Service">Service</option>
                                </select>
                            </div>
                            <div className="me-3">
                                <label htmlFor="price" className="form-label">Prix HT :</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="price"
                                    value={price}
                                    onChange={(e) => setPrice(e.target.value)}
                                    onBlur={() => {
                                        const numericValue = parseFloat(price);
                                        setPrice(isNaN(numericValue) ? '' : numericValue.toString());
                                    }} />
                            </div>
                            <div className="me-3">
                                <label htmlFor="priceFull" className="form-label">Prix TTC :</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="priceFull"
                                    value={priceFull}
                                    readOnly
                                    onChange={(e) => setPriceFull(e.target.value)} />
                            </div>
                            <div className="me-3">
                                <label htmlFor="quantity" className="form-label">Quantité:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="quantity"
                                    value={quantity}
                                    onChange={(e) => setQuantity(e.target.value)} />
                            </div>
                        </div>
                    </div>

                    <div className="mb-3 single-line">
                        <div className="d-flex line-flex">
                            <div className="me-3">
                                <label htmlFor="taxes" className="form-label">Taxes (%) :</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="taxes"
                                    value={taxes}
                                    onChange={(e) => setTaxes(e.target.value)} />
                            </div>
                            <div className="me-3">
                                <label htmlFor="account" className="form-label">Compte comptable :</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="account"
                                    value={account}
                                    onChange={(e) => setAccount(e.target.value)} />
                            </div>
                            <div className="me-3">
                                <label htmlFor="state" className="form-label">État :</label>
                                <select
                                    className="form-control"
                                    id="state"
                                    value={state || "ACTIF"}
                                    onChange={(e) => setState(e.target.value)}
                                    required>
                                    <option selected value="ACTIF">ACTIF</option>
                                    <option value="INACTIF">INACTIF</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3 single-line">
                        <div className="d-flex line-flex">
                            <div className="me-3">
                                <label htmlFor="description" className="form-label">Description :</label>
                                <textarea
                                    className="form-control"
                                    id="description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="mb-3 single-line">
                        <div className="d-flex line-flex">
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary">Envoyer</button>
                </div>
            </form>
        </div>
    )
}
