/**
 * Utility functions for formatting data in the billing system
 */

import { parseInvoiceDocument } from '../../../Utils/documentParser';

/**
 * Formats a date to the French locale format
 * @param {string|Date} date - The date to format
 * @returns {string} The formatted date string
 */
export const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    return d.toLocaleDateString('fr-FR');
};

/**
 * Calculates a validity date 30 days from the given date
 * @param {string|Date} date - The starting date
 * @param {boolean} isBill - Whether this is a bill (if true, sets to 5th of next month)
 * @returns {Date} The calculated validity date
 */
export const calculateValidityDate = (date, isBill = false) => {
    const validityDate = new Date(date);

    if (isBill) {
        // For bills: Set to 5th of next month
        validityDate.setMonth(validityDate.getMonth() + 1); // Move to next month
        validityDate.setDate(5); // Set to 5th of that month
    } else {
        // For other documents (e.g. invoices): Add 30 days
        validityDate.setDate(validityDate.getDate() + 30);
    }

    return validityDate;
};

/**
 * Attempts to parse a potential JSON string
 * @param {string|any} value - Value to parse
 * @returns {any} Parsed value or original if parsing fails
 */
const safeJSONParse = (value) => {
    if (typeof value !== 'string') return value;
    try {
        return JSON.parse(value);
    } catch (e) {
        return value;
    }
};

/**
 * Ensures value is an array, handling various input formats
 * @param {any} value - Value to convert to array
 * @returns {Array} Normalized array
 */
const ensureArray = (value) => {
    if (!value) return [];
    if (Array.isArray(value)) return value;
    return [value];
};

/**
 * Validates if an object is a valid product/item
 * @param {Object} item - Item to validate
 * @returns {boolean} Whether the item is valid
 */
const isValidItem = (item) => {
    if (!item || typeof item !== 'object') return false;
    // Check for minimum required properties
    return ['_id', 'code', 'label'].some(prop => prop in item);
};

/**
 * Formats a bill or invoice object to ensure consistent structure
 * @param {Object} document - The bill or invoice to format
 * @returns {Object} The formatted document
 */
export const formatDocument = (document) => {
    if (!document) return null;

    let parsedContents = [];
    const debugInfo = {
        originalType: typeof document.document,
        wasArray: Array.isArray(document.document),
        parseAttempts: 0,
        errors: []
    };

    try {
        // Use the enhanced document parser to handle all document formats
        parsedContents = parseInvoiceDocument(document);

        // Ensure reduction is properly formatted
        let reduction = {};
        try {
            reduction = typeof document.reduction === 'string'
                ? safeJSONParse(document.reduction)
                : document.reduction || {};
        } catch (e) {
            console.warn('Error parsing reduction:', e);
        }

        // Ensure totals are properly formatted
        let totals = {
            rawTotal: 0,
            taxesTotal: 0,
            totalPreTax: 0,
            total: 0
        };
        try {
            totals = typeof document.totals === 'string'
                ? safeJSONParse(document.totals)
                : document.totals || totals;
        } catch (e) {
            console.warn('Error parsing totals:', e);
        }

        // Return formatted document with all fields normalized
        return {
            ...document,
            billContents: parsedContents,
            reduction,
            totals,
            // Add any additional fields that need normalization
            documentState: document.documentState || 'Brouillon',
            date: document.date || new Date().toISOString(),
            validity: document.validity || calculateValidityDate(new Date(), true).toISOString(),
        };
    } catch (error) {
        console.error('Fatal error in formatDocument:', error, {
            document,
            debugInfo
        });
        // Return a safe default structure
        return {
            ...document,
            billContents: [],
            reduction: {},
            totals: {
                rawTotal: 0,
                taxesTotal: 0,
                totalPreTax: 0,
                total: 0
            },
            documentState: document.documentState || 'Brouillon',
            date: document.date || new Date().toISOString(),
            validity: document.validity || calculateValidityDate(new Date(), true).toISOString(),
        };
    }
};

/**
 * Formats a number as currency
 * @param {number} amount - The amount to format
 * @param {boolean} isCredit - Whether this is a credit amount
 * @returns {string} The formatted amount
 */
export const formatCurrency = (amount, isCredit = false) => {
    const absAmount = Math.abs(parseFloat(amount) || 0);
    if (isCredit && absAmount !== 0) {
        return `-${absAmount.toFixed(2)}€`;
    }
    return `${absAmount.toFixed(2)}€`;
};

/**
 * Sorts a list of items by a specified method and key
 * @param {Array} list - The list to sort
 * @param {string} method - The sorting method ('abc' for alphabetical)
 * @param {string} key - The key to sort by
 * @returns {Array} The sorted list
 */
export const sortItems = (list, method, key) => {
    // Return empty array if list is not an array or is empty
    if (!list || !Array.isArray(list)) {
        console.warn('sortItems received invalid list:', list);
        return [];
    }

    // If list is empty, return it as is
    if (list.length === 0) {
        return [];
    }

    const sortedList = [...list];

    switch (method) {
        case "abc":
            if (key) {
                sortedList.sort((a, b) => {
                    // Handle undefined or null values
                    const valueA = a[key] !== undefined && a[key] !== null ? a[key] : '';
                    const valueB = b[key] !== undefined && b[key] !== null ? b[key] : '';

                    if (valueA < valueB) return -1;
                    if (valueA > valueB) return 1;
                    return 0;
                });
            }
            break;
        // Add other sorting methods as needed
        default:
            break;
    }

    return sortedList;
};

/**
 * Sorts bills by code in descending order (newest first)
 * Groups credit notes with their parent bills
 * @param {Array} bills - The bills to sort
 * @returns {Array} The sorted bills
 */
export const sortBillsByCode = (bills) => {
    if (!bills || !Array.isArray(bills) || bills.length === 0) {
        return bills || [];
    }

    return [...bills].sort((a, b) => {
        const getBaseCode = (code) => {
            if (!code) return '';

            if (code.startsWith('AV-')) {
                const match = code.match(/AV-(.+)-\d+/);
                return match ? match[1] : '';
            }

            return code.replace(/^FAC-/, '');
        };

        const baseCodeA = getBaseCode(a.code);
        const baseCodeB = getBaseCode(b.code);

        if (baseCodeA !== baseCodeB) {
            const getYear = (code) => {
                const match = code.match(/^(\d{4})/);
                return match ? parseInt(match[1], 10) : 0;
            };

            const yearA = getYear(baseCodeA);
            const yearB = getYear(baseCodeB);

            if (yearB !== yearA) {
                return yearB - yearA; // Sort by year descending
            }

            const getNumber = (code) => {
                const match = code.match(/\d{4}(\d+)$/);
                return match ? parseInt(match[1], 10) : 0;
            };

            return getNumber(baseCodeB) - getNumber(baseCodeA); // Descending order
        }

        // Bill/credit note ordering: bills first, then credit notes by sequence
        const isAvoirA = a.code.startsWith('AV-');
        const isAvoirB = b.code.startsWith('AV-');

        if (!isAvoirA && isAvoirB) return -1; // Bill before credit note
        if (isAvoirA && !isAvoirB) return 1; // Credit note after bill

        if (isAvoirA && isAvoirB) {
            const getCreditNoteNumber = (code) => {
                const match = code.match(/-(\d+)$/);
                return match ? parseInt(match[1], 10) : 0;
            };

            return getCreditNoteNumber(a.code) - getCreditNoteNumber(b.code); // Ascending order for credit notes
        }

        return 0;
    });
}; 