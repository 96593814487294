import React, { useState, useEffect, useCallback, useRef, useContext, useMemo } from 'react';
import {
    BsThreeDots,
    BsArrowUp,
    BsArrowDown,
    BsPlusCircle,
    BsPersonFill,
    BsTrash
} from 'react-icons/bs';

import Spinner from "../../../../Components/Spinner";
import "../../../../assets/scss/Billing/popUp.scss";
import UserContext from '../../../../dataContext';
import ProductPopUpContent from './Products/ProductPopUpContent';
import ProductPopUpForm from './Products/ProductPopUpForm';
import { parseDocumentData, parseInvoiceDocument } from '../../../../Utils/documentParser';
import ClientPopUp from '../ClientPopUp';
import { calculateTotals } from '../../utils/calculations';
import { formatDate, calculateValidityDate } from '../../utils/formatters';
import { getDocumentCode } from '../../utils/api';

const styles = {
    codeCellStyle: {
        minWidth: '100px',
        maxWidth: '150px',
        overflow: 'visible',
        whiteSpace: 'normal',
        wordBreak: 'break-word'
    },
    descriptionDropdown: {
        position: 'fixed',
        zIndex: 9999,
        backgroundColor: 'white',
        border: '1px solid #ddd',
        borderRadius: '4px',
        padding: '10px',
        minWidth: '250px',
        boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
        maxHeight: '300px',
        overflow: 'auto'
    }
};

export default function PopUp({
    showPopUp, popUpContent, setShowPopUp, userContext, handleModif,
    formatDate, setShowClientPopUp, action, setShowProductPopUp,
    billContents, setBillContents,
    showProductPopUp, reference, setReference, siret = "", setSiret = () => { }, address,
    setAddress, type, payingMethod, setPayingMethod, billingAddress = {}, setBillingAddress = () => { },
    isTheSameAsFullAddress, setFullAddress, fullAddress, setFullSecondAddress, fullSecondAddress, setIsTheSameAsFullAddress,
    fullName, setFullName, clientMail, setClientMail, clientId, setClientId, setProducts, handleProductUpdate,
    exportToCsv, setPopUpContent, setAction, onUpdate
}) {
    const what = popUpContent?.what;
    let thing = popUpContent?.thing;

    const [documentState, setDocumentState] = useState("Brouillon");
    const [code, setCode] = useState("");
    const [codeLoading, setCodeLoading] = useState(false);
    const [date, setDate] = useState(new Date().toISOString());
    const [validity, setValidity] = useState(calculateValidityDate(new Date(), type === "bill").toISOString());
    const [description, setDescription] = useState("");
    const [, setRawTotal] = useState(0);
    const [, setTaxesTotal] = useState(0);
    const [, setTotalPreTax] = useState(0);
    const [, setTotal] = useState(0);
    const [currency, setCurrency] = useState("%");
    const [amount, setAmount] = useState("0");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showDescriptionDropdown, setShowDescriptionDropdown] = useState(false);
    const [_id, set_Id] = useState("");
    const [reduction, setReduction] = useState({ currency: "", amount: 0 });
    const [showReduction, setShowReduction] = useState(false);
    const [billLoading, setBillLoading] = useState(false);
    const [isValidDocument, setIsValidDocument] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [localShowClientPopUp, setLocalShowClientPopUp] = useState(false);
    const formRef = useRef(null);
    const [triggerSubmit, setTriggerSubmit] = useState(false);
    const [reset,] = useState(() => () => { });
    // const [showNewItemForm, setShowNewItemForm] = useState(false);
    const fieldsInitialized = useRef(false);

    const [productLabel, setProductLabel] = useState("");
    const [family, setFamily] = useState("");
    const [productType, setProductType] = useState("");
    const [priceFull, setPriceFull] = useState("");
    const [price, setPrice] = useState("");
    const [quantity, setQuantity] = useState("");
    const [taxes, setTaxes] = useState("");
    const [account, setAccount] = useState("");
    const [status, setStatus] = useState("");

    const contactPopUp = useRef(null);

    // Add a state to track if clients have been fetched
    const [clientsFetched, setClientsFetched] = useState(false);

    const [isDeleting, setIsDeleting] = useState(false);

    const resetFormStates = useCallback(() => {
        try {
            if (typeof setBillContents === 'function') setBillContents([]);
            if (typeof setReference === 'function') setReference("");
            if (typeof setClientId === 'function') setClientId("");
            if (typeof setSiret === 'function') setSiret("");
            if (typeof setFullName === 'function') setFullName("");
            if (typeof setClientMail === 'function') setClientMail("");
            if (typeof setFullAddress === 'function') setFullAddress({
                roadAndNumber: "",
                zipCode: "",
                city: "",
                country: "",
                complement: ""
            });
            if (typeof setFullSecondAddress === 'function') setFullSecondAddress({
                roadAndNumber: "",
                zipCode: "",
                city: "",
                country: "",
                complement: ""
            });
            if (typeof setAddress === 'function') setAddress({
                roadAndNumber: "",
                zipCode: "",
                city: "",
                country: "",
                complement: ""
            });
            if (typeof setBillingAddress === 'function') setBillingAddress({
                roadAndNumber: "",
                zipCode: "",
                city: "",
                country: "",
                complement: "",
                isTheSameAsFullAddress: false
            });
            if (typeof setIsTheSameAsFullAddress === 'function') setIsTheSameAsFullAddress(false);
            if (typeof setPayingMethod === 'function') setPayingMethod("");
            setDocumentState("Brouillon");
            setCode("");
            setDate(new Date().toISOString());
            setValidity(calculateValidityDate(new Date(), type === "bill").toISOString());
            setRawTotal(0);
            setTaxesTotal(0);
            setTotalPreTax(0);
            setTotal(0);
            setCurrency("%");
            setAmount("0");
            set_Id("");
            setReduction({ currency: "", amount: 0 }); // Initialize with default values
            setShowReduction(false);
            setBillLoading(false);
            fieldsInitialized.current = false;
        } catch (error) {
            console.error("Error resetting form states:", error);
        }
    }, [setBillContents, setReference, setClientId, setSiret, setFullName, setClientMail, setFullAddress, setFullSecondAddress, setAddress, setBillingAddress, setIsTheSameAsFullAddress, setPayingMethod]);

    const parseDocumentSafely = parseDocumentData;

    const initializeForm = useCallback(() => {
        if (!thing) return;

        try {
            // Common fields across different types
            if (typeof setBillContents === 'function') setBillContents(parseDocumentSafely(thing?.document || []));
            if (typeof setReference === 'function') setReference(thing?.reference || "");
            if (typeof setCode === 'function') setCode(thing?.code || "");

            // Handle different entity types differently
            if (type === "product") {
                // Product specific fields
                setProductLabel(thing?.label || "");
                setFamily(thing?.family || "");
                setProductType(thing?.type || "");
                setPriceFull(thing?.priceFull || "");
                setPrice(thing?.price || "");
                setQuantity(thing?.quantity || "");
                setTaxes(thing?.taxes || "");
                setAccount(thing?.account || "");
                setStatus(thing?.status || "");

            } else {
                // Bill/invoice related fields
                if (typeof setDate === 'function') setDate(thing?.date || new Date().toISOString());
                if (typeof setValidity === 'function') setValidity(thing?.validity || calculateValidityDate(thing?.date || new Date(), type === "bill").toISOString());
                if (typeof setFullName === 'function') setFullName(thing?.clientName || "");
                if (typeof setSiret === 'function') setSiret(thing?.siret || "");
                if (typeof setClientMail === 'function') setClientMail(thing?.clientMail || "");
                if (typeof setClientId === 'function') setClientId(thing?.clientId || "");
                if (typeof setAddress === 'function') setAddress(thing?.address || {});
                if (typeof setBillingAddress === 'function') setBillingAddress(thing?.billingAddress || {});
                if (typeof setFullAddress === 'function') setFullAddress(thing?.address?.fullAddress || "");
                if (typeof setFullSecondAddress === 'function') setFullSecondAddress(thing?.address?.fullSecondAddress || "");
                if (typeof setIsTheSameAsFullAddress === 'function') setIsTheSameAsFullAddress(thing?.isTheSameAsFullAddress || false);
                if (typeof setPayingMethod === 'function') setPayingMethod(thing?.payingMethod || "");
            }

            // Set the _id if available
            set_Id(thing?._id || "");

            // Additional initialization logic...
        } catch (error) {
            console.error("Error initializing form:", error);
        }
    }, [thing, calculateValidityDate, parseDocumentSafely, type, setAddress, setBillingAddress, setClientId,
        setClientMail, setCode, setDate, setBillContents, setFullAddress, setFullName,
        setFullSecondAddress, setIsTheSameAsFullAddress, setPayingMethod, setReference, setSiret,
        setValidity]);

    useEffect(() => {
        if (showPopUp && thing?.object) {
            setCode(thing.object.code || "");
            setDate(thing.object.date ? new Date(thing.object.date).toISOString() : new Date().toISOString());
            setValidity(thing.object.validity || calculateValidityDate(new Date(), type === "bill").toISOString());
            setDescription(thing.object.description || "");

            // Client information initialization
            if (typeof setFullName === 'function') {
                setFullName(thing.object.fullName || "");
            }
            if (setSiret && typeof setSiret === 'function') {
                setSiret(thing.object.siret || "");
            }
            if (setPayingMethod && typeof setPayingMethod === 'function') {
                setPayingMethod(thing.object.payingMethod || "");
            }
            if (setClientId && typeof setClientId === 'function') {
                setClientId(thing.object.clientId || "");
            }
            if (typeof setClientMail === 'function') {
                setClientMail(thing.object.clientMail || "");
            }
            setReference(thing.object.reference || "");

            if (thing.object.document) {
                let docs = thing.object.document;
                const parsedDocs = parseInvoiceDocument(docs);
                setBillContents(parsedDocs);
            } else {
                setBillContents([]);
            }

            // Initialize reduction
            let parsedReduction = {};
            try {
                if (thing.object.reduction) {
                    parsedReduction = typeof thing.object.reduction === 'string'
                        ? JSON.parse(thing.object.reduction)
                        : thing.object.reduction;
                }
                setReduction(parsedReduction);
                setShowReduction(!!parsedReduction && Object.keys(parsedReduction).length > 0);
            } catch (err) {
                console.error('Error parsing reduction:', err);
                setReduction({ currency: "", amount: 0 }); // Initialize with default values
                setShowReduction(false);
            }

            set_Id(thing.object._id || "");
            setDocumentState(thing.object.documentState || "Brouillon");

            // Handle addresses
            if (thing.object.fullAddress) setFullAddress(thing.object.fullAddress);
            if (thing.object.fullSecondAddress) setFullSecondAddress(thing.object.fullSecondAddress);
            if (thing.object.billingAddress) {
                setBillingAddress(thing.object.billingAddress);
                setIsTheSameAsFullAddress(thing.object.billingAddress.isTheSameAsFullAddress || false);
            }

            // Set fields as initialized
            fieldsInitialized.current = true;
        }
    }, [showPopUp, thing, calculateValidityDate, type, setFullName, setSiret, setPayingMethod, setClientId, setClientMail, setBillContents, setFullAddress, setFullSecondAddress, setBillingAddress]);

    useEffect(() => {
        if (!showPopUp && (type === "invoice" || type === "bill")) {
            resetFormStates();
        }
    }, [
        showPopUp,
        resetFormStates,
        type,
        setAddress,
        setBillingAddress,
        setClientId,
        setClientMail,
        setBillContents,
        setFullAddress,
        setFullName,
        setFullSecondAddress,
        setIsTheSameAsFullAddress,
        setPayingMethod,
        setReference,
        setSiret
    ]);

    useEffect(() => {
        if (type === "product") return null;

        const fetchData = async () => {
            if (!thing?.object && !action.startsWith('add') && (type === "invoice" || type === "bill")) {
                resetFormStates();
                return;
            }

            if (!fieldsInitialized.current || action.startsWith('add')) {
                if (action === "addBill" || action === "addInvoice") {
                    setCodeLoading(true);
                    try {
                        let response = await userContext.apiReducer(action === "addBill" ? "getBillCode" : "getInvoiceCode");
                        let code;

                        // Handle both response formats - object with code property or direct string
                        if (response?.code) {
                            code = response.code;
                        } else if (typeof response === 'string') {
                            code = response;
                        } else {
                            code = "00ERREUR";
                            console.error("Unexpected response format:", response);
                        }

                        setCode(code);
                    } catch (err) {
                        console.log(err, "error");
                        setCode("00ERREUR");
                    } finally {
                        setCodeLoading(false);
                    }
                } else {
                    setCode(thing?.object?.code || "");
                }

                if (thing?.object && (type === "invoice" || type === "bill")) {
                    let newState = {
                        documentState: thing.object.documentState || "Brouillon",
                        date: thing.object.date || new Date(),
                        validity: type === "bill" ? calculateValidityDate(new Date(), thing.object.validity) : thing.object.validity || new Date(new Date().setMonth(new Date().getMonth() + 2)),
                        clientId: thing.object.clientId || "",
                        reference: thing.object.reference || "",
                        siret: thing.object.siret || "",
                        payingMethod: thing.object.payingMethod || "",
                        currency: thing.object.acomptes?.currency || "%",
                        amount: thing.object.acomptes?.amount || "0",
                        description: thing.object.description || "",
                        address: thing.object.address || "",
                        rawTotal: thing.object.totals?.rawTotal || 0,
                        taxesTotal: thing.object.totals?.taxesTotal || 0,
                        totalPreTax: thing.object.totals?.totalPreTax || 0,
                        total: thing.object.totals?.total || 0,
                        _id: thing.object._id || "",
                        fullAddress: thing.object.fullAddress || { roadAndNumber: "", zipCode: "", city: "", country: "", complement: "" },
                        fullSecondAddress: thing.object.fullSecondAddress || { roadAndNumber: "", zipCode: "", city: "", country: "", complement: "" },
                        billingAddress: thing.object.billingAddress || { roadAndNumber: "", zipCode: "", city: "", country: "", complement: "", isTheSameAsFullAddress: false },
                        isTheSameAsFullAddress: thing.object.billingAddress?.isTheSameAsFullAddress || false,
                        fullName: thing.object.fullName || "",
                        clientMail: thing.object.clientMail || "",
                    }
                    try {
                        const tempDoc = JSON.parse(thing.object.document)
                        newState.document = Array.isArray(tempDoc) ? tempDoc : []
                    } catch (err) {
                        // console.log("Error parsing document:", err);
                        newState.document = [];
                    }

                    setDocumentState(newState.documentState);
                    setDate(newState.date);
                    setValidity(newState.validity);
                    if (setClientId && typeof setClientId === 'function') {
                        setClientId(newState.clientId);
                    }
                    setReference(newState.reference);
                    if (setSiret && typeof setSiret === 'function') {
                        setSiret(newState.siret);
                    }
                    if (setPayingMethod && typeof setPayingMethod === 'function') {
                        setPayingMethod(newState.payingMethod);
                    }
                    setDescription(newState.description);
                    setAddress(newState.address);
                    setRawTotal(newState.rawTotal);
                    setTaxesTotal(newState.taxesTotal);
                    setTotalPreTax(newState.totalPreTax);
                    setTotal(newState.total);
                    setCurrency(newState.currency);
                    setAmount(newState.amount);
                    setReduction(newState.reduction);
                    if (setFullAddress && typeof setFullAddress === 'function') {
                        setFullAddress(newState.fullAddress);
                    }
                    if (setFullSecondAddress && typeof setFullSecondAddress === 'function') {
                        setFullSecondAddress(newState.fullSecondAddress);
                    }
                    if (setBillingAddress && typeof setBillingAddress === 'function') {
                        setBillingAddress(newState.billingAddress);
                    }
                    if (setIsTheSameAsFullAddress && typeof setIsTheSameAsFullAddress === 'function') {
                        setIsTheSameAsFullAddress(newState.isTheSameAsFullAddress);
                    }
                    setBillContents(newState.document);
                    setFullName(newState.fullName);
                    setClientMail(newState.clientMail);
                    set_Id(newState._id);
                }

                fieldsInitialized.current = true;
            }
        };

        fetchData();

        return () => {
            if (!showPopUp) {
                fieldsInitialized.current = false;
            }
        };
    }, [
        thing,
        action,
        userContext,
        type,
        calculateValidityDate,
        resetFormStates,
        showPopUp,
        setAddress,
        setBillingAddress,
        setClientId,
        setClientMail,
        setBillContents,
        setFullAddress,
        setFullName,
        setFullSecondAddress,
        setIsTheSameAsFullAddress,
        setPayingMethod,
        setReference,
        setSiret
    ]);

    useEffect(() => {
        if (triggerSubmit) {
            formRef.current.requestSubmit()
            setTriggerSubmit(false)
        }
    }, [triggerSubmit, reset])

    // Define fetchClients outside of useEffect to make it accessible throughout the component
    const fetchClients = async () => {
        try {
            if (!userContext || !userContext.apiReducer) {
                console.error("userContext or apiReducer is undefined");
                return;
            }

            const response = await userContext.apiReducer("getContacts");
            if (Array.isArray(response)) {
                setContacts(response);
                setClientsFetched(true);
            } else {
                console.error("Unexpected response format from getContacts API:", response);
                setContacts([]);
                setClientsFetched(true); // Still set to true to prevent repeated fetching
            }
        } catch (error) {
            console.error("Error fetching clients:", error);
            setContacts([]);
            setClientsFetched(true); // Still set to true to prevent repeated fetching
        }
    };

    // Add useEffect to fetch clients
    useEffect(() => {
        if (showPopUp && !clientsFetched) {
            fetchClients();
        }
    }, [showPopUp, clientsFetched, userContext]); // userContext is needed for the API call

    const handleSubmit = async (e) => {
        if (e) e.preventDefault();
        setIsSubmitting(true);

        try {
            // Handle submission based on type
            if (type === "product") {
                const productData = {
                    _id,
                    label: productLabel,
                    family,
                    type: productType,
                    price,
                    priceFull,
                    quantity,
                    taxes,
                    account,
                    status
                };


                if (action === "add") {
                    // Call the appropriate add function
                    await handleProductUpdate(productData);
                } else {
                    // Call the appropriate update function
                    await handleProductUpdate(productData);
                }

                setShowPopUp(false);
            } else {
                // Existing code for other types like bills
                // ...existing submission code...
                e.preventDefault();

                if (isSubmitting) return;
                setIsSubmitting(true);

                try {
                    const formData = {
                        _id: _id || undefined,
                        documentState,
                        code,
                        date,
                        validity,
                        clientId,
                        reference,
                        siret,
                        payingMethod,
                        description,
                        fullName,
                        clientMail,
                        document: billContents, // Update this to use document
                        totals: {
                            rawTotal: parseFloat(totals.rawTotal),
                            taxesTotal: parseFloat(totals.taxesTotal),
                            totalPreTax: parseFloat(totals.totalPreTax),
                            total: parseFloat(totals.total)
                        },
                        reduction: showReduction ? reduction : {},
                        acomptes: {
                            currency,
                            amount
                        },
                        fullAddress,
                        fullSecondAddress,
                        billingAddress: {
                            ...billingAddress,
                            isTheSameAsFullAddress
                        }
                    };

                    const response = await handleModif(formData, action || (type === "bill" ? "modifyBill" : type === "invoice" ? "modifyInvoice" : ""));

                    if (response && onUpdate) {
                        onUpdate();
                    }

                    setShowPopUp(false);

                } catch (error) {
                    console.error('Error submitting form:', error);
                } finally {
                    setIsSubmitting(false);
                }
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        } finally {
            setIsSubmitting(false);
        }
    };


    useEffect(() => {
        if (showPopUp && thing?.object) {
            setCode(thing.object.code || "");
            setDate(thing.object.date ? new Date(thing.object.date).toISOString() : new Date().toISOString());
            setValidity(thing.object.validity || calculateValidityDate(new Date(), type === "bill").toISOString());
            setDescription(thing.object.description || "");

            // Client information initialization
            if (typeof setFullName === 'function') {
                setFullName(thing.object.fullName || "");
            }
            if (setSiret && typeof setSiret === 'function') {
                setSiret(thing.object.siret || "");
            }
            if (setPayingMethod && typeof setPayingMethod === 'function') {
                setPayingMethod(thing.object.payingMethod || "");
            }
            if (setClientId && typeof setClientId === 'function') {
                setClientId(thing.object.clientId || "");
            }
            if (typeof setClientMail === 'function') {
                setClientMail(thing.object.clientMail || "");
            }
            setReference(thing.object.reference || "");

            if (thing.object.document) {
                let docs = thing.object.document;
                const parsedDocs = parseInvoiceDocument(docs);
                setBillContents(parsedDocs);
            } else {
                setBillContents([]);
            }

            // Initialize reduction
            let parsedReduction = {};
            try {
                if (thing.object.reduction) {
                    parsedReduction = typeof thing.object.reduction === 'string'
                        ? JSON.parse(thing.object.reduction)
                        : thing.object.reduction;
                }
                setReduction(parsedReduction);
                setShowReduction(!!parsedReduction && Object.keys(parsedReduction).length > 0);
            } catch (err) {
                console.error('Error parsing reduction:', err);
                setReduction({ currency: "", amount: 0 }); // Initialize with default values
                setShowReduction(false);
            }

            set_Id(thing.object._id || "");
            setDocumentState(thing.object.documentState || "Brouillon");

            // Handle addresses
            if (thing.object.fullAddress) setFullAddress(thing.object.fullAddress);
            if (thing.object.fullSecondAddress) setFullSecondAddress(thing.object.fullSecondAddress);
            if (thing.object.billingAddress) {
                setBillingAddress(thing.object.billingAddress);
                setIsTheSameAsFullAddress(thing.object.billingAddress.isTheSameAsFullAddress || false);
            }

            // Set fields as initialized
            fieldsInitialized.current = true;
        }
    }, [showPopUp, thing, calculateValidityDate, type, setFullName, setSiret, setPayingMethod, setClientId, setClientMail, setBillContents, setFullAddress, setFullSecondAddress, setBillingAddress]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (showDescriptionDropdown && !event.target.closest('.description-dropdown')) {
                setShowDescriptionDropdown(false)
            }
            if (showProductPopUp && !event.target.closest('.billing-popup')) {
                setShowProductPopUp(false)
                reset()
            }
        };

        window.document.addEventListener('mousedown', handleClickOutside);

        return () => {
            window.document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showDescriptionDropdown, showProductPopUp, setShowProductPopUp, reset]);

    const handleDescriptionClick = (index) => {
        setShowDescriptionDropdown((prev) => (prev === index ? null : index));
    };

    const handleInputChange = (e, index, field) => {
        if (!billContents[index]) return;

        const updatedDocument = [...billContents];
        const item = { ...updatedDocument[index] };

        if (item.type === 'section' || item.type === 'comment') return;

        // For avoirs, keep quantities negative but allow editing magnitude
        if (field === 'quantity' && popUpContent?.what?.thing?.object?.documentState === 'Avoir') {
            const absValue = Math.abs(parseFloat(e.target.value) || 0);
            item[field] = -absValue;
        } else {
            item[field] = e.target.value;
            if (field === 'quantity' || field === 'price') {
                const value = parseFloat(e.target.value) || 0;
                item[field] = value;
            }
        }

        // Recalculate totals
        if (field === 'quantity' || field === 'price') {
            const price = parseFloat(item.price || 0);
            const quantity = parseFloat(item.quantity || 0);
            item.totalHT = price * quantity;
            item.totalTTC = item.totalHT * (1 + (parseFloat(item.taxes || 20) / 100));
        }

        updatedDocument[index] = item;
        setBillContents(updatedDocument);
    };

    const handleDelete = (index) => {
        setBillContents((prevProducts) => prevProducts.filter((_, i) => i !== index));
    };

    const addSection = (section) => {
        if (typeof section !== 'string') return;
        setBillContents([...billContents, {
            type: 'section',
            title: section
        }]);
    };

    const addComment = (comment) => {
        if (typeof comment !== 'string') return;
        setBillContents([...billContents, {
            type: 'comment',
            content: comment
        }]);
    };

    useEffect(() => {
        if (billContents) {
            const isValid = Array.isArray(billContents) && billContents.every(item =>
                item && (item._id || item.text || item.comment)
            );
            setIsValidDocument(isValid);
        }
    }, [billContents]);

    useEffect(() => {
        let newRawTotal = 0;
        let newTotalPreTax = 0;
        let newTaxesTotal = 0;

        // First calculate raw totals
        billContents?.forEach((product) => {
            if (!product) return
            if (!product._id) return;
            let priceNoTaxes = Math.round((parseFloat(product.quantity || 1) * parseFloat(product.price || 0)) * 100) / 100;
            newRawTotal += priceNoTaxes;
            newTotalPreTax += priceNoTaxes;
        });

        // Apply reduction to pre-tax amount if reduction exists
        if (reduction?.currency === "%") {
            newTotalPreTax -= newTotalPreTax * (parseFloat(reduction?.amount || 0) / 100);
        } else if (reduction?.currency === "€") {
            newTotalPreTax -= parseFloat(reduction?.amount || 0);
        }

        // Calculate taxes on reduced amount
        billContents?.forEach((product) => {
            if (!product) return
            if (!product._id) return;
            let productShare = newRawTotal > 0
                ? (parseFloat(product.quantity || 1) * parseFloat(product.price || 0)) / newRawTotal
                : 0;
            let reducedProductAmount = newTotalPreTax * productShare;
            let taxAmount = reducedProductAmount * (parseFloat(product.taxes || 0) / 100);
            newTaxesTotal += taxAmount;
        });

        setRawTotal(newRawTotal);
        setTaxesTotal(newTaxesTotal);
        setTotalPreTax(newTotalPreTax);
        setTotal(newTotalPreTax + newTaxesTotal);
    }, [billContents, reduction]);

    useEffect(() => {
        if (!showPopUp) {
            // Check if setBillContents is a function before calling it
            if (typeof setBillContents === 'function') {
                setBillContents([]);
            }
            setReduction({ currency: "", amount: 0 }); // Initialize with default values
            setShowReduction(false);
            setRawTotal(0);
            setTaxesTotal(0);
            setTotalPreTax(0);
            setTotal(0);
            fieldsInitialized.current = false;
        }
    }, [showPopUp]);

    const moveItem = (index, direction) => {
        const updatedDocument = [...billContents];
        if (direction === 'up') {
            if (index > 0) {
                let temp = updatedDocument[index];
                updatedDocument[index] = updatedDocument[index - 1];
                updatedDocument[index - 1] = temp;
            }
        } else if (direction === 'down') {
            if (index < updatedDocument.length - 1) {
                let temp = updatedDocument[index];
                updatedDocument[index] = updatedDocument[index + 1];
                updatedDocument[index + 1] = temp;
            }
        }
        setBillContents(updatedDocument);
    }

    // Use useMemo for totals calculation
    // Temporarily replacing useMemo with direct function call to fix "p is not a function" error
    const totals = calculateTotals(billContents, reduction);

    // Original code with useMemo (commented out until we can fix the underlying issue)
    // const totals = useMemo(() => {
    //     return calculateTotals(billContents, reduction);
    // }, [billContents, reduction]);

    useEffect(() => {
        if (what?.type === "Product" && thing?.object && !fieldsInitialized.current) {
            setCode(thing.object.code || "");
            setProductLabel(thing.object.label || "");
            setFamily(thing.object.family || "");
            setPrice(thing.object.price || "");
            setProductType(thing.object.type || "");
            setPriceFull(thing.object.priceFull || "");
            fieldsInitialized.current = true;
        }
    }, [what, thing]);

    useEffect(() => {
        if (showPopUp) {
            if (thing?.object?.document) {
                try {
                    const docData = typeof thing.object.document === 'string'
                        ? JSON.parse(thing.object.document)
                        : thing.object.document;
                    setBillContents(docData);
                } catch (error) {
                    console.error('Error parsing document:', error);
                    setBillContents([]);
                }
            } else {
                setBillContents([]);
            }
        }
    }, [showPopUp, thing, setBillContents]);

    useEffect(() => {
        if (billContents) {
            const isValid = Array.isArray(billContents) && billContents.every(item =>
                item && (item._id || item.text || item.comment)
            );
            setIsValidDocument(isValid);
        }
    }, [billContents]);

    // Initialize billing address if empty
    useEffect(() => {
        if (!billingAddress || !Object.keys(billingAddress).length) {
            setBillingAddress({
                roadAndNumber: "",
                zipCode: "",
                city: "",
                country: "",
                complement: "",
                isTheSameAsFullAddress: false
            });
        }
    }, [billingAddress, setBillingAddress]);

    const [startDate, setStartDate] = useState(thing?.startDate || new Date().toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState(thing?.endDate || new Date().toISOString().split('T')[0]);
    const [previewBills, setPreviewBills] = useState([]);

    useEffect(() => {
        if (what === "export") {
            // Filter bills for preview based on date range
            const filteredBills = billContents.filter(bill => {
                const billDate = new Date(bill.date);
                return billDate >= new Date(startDate) && billDate <= new Date(endDate);
            });
            setPreviewBills(filteredBills);
        }
    }, [what, billContents, startDate, endDate]);

    const handleExport = async () => {
        if (what === "export") {
            await exportToCsv(startDate, endDate);
        }
    };

    useEffect(() => {
        if (showPopUp && thing?.object) {
            try {
                let parsedDoc;
                if (thing.object.billContents !== undefined) {
                    parsedDoc = thing.object.billContents;
                } else if (Array.isArray(thing.object.document)) {
                    if (typeof thing.object.document[0] === 'string') {
                        parsedDoc = JSON.parse(thing.object.document[0]);
                    } else {
                        parsedDoc = thing.object.document;
                    }
                }
                setBillContents(Array.isArray(parsedDoc) ? parsedDoc : [parsedDoc]);
            } catch (err) {
                console.warn("Warning: Could not parse document", err);
                setBillContents([]);
            }
        }
    }, [showPopUp, thing, setBillContents]);

    useEffect(() => {
        if (billContents) {
            const totals = calculateTotals(billContents, reduction);
            setRawTotal(parseFloat(totals.rawTotal));
            setTaxesTotal(parseFloat(totals.taxesTotal));
            setTotalPreTax(parseFloat(totals.totalPreTax));
            setTotal(parseFloat(totals.total));
        }
    }, [billContents, reduction]);

    return (
        <div ref={contactPopUp} className={'billing-popup bills invoices' + (showPopUp ? " open" : "")} onClick={(e) => {
            if (e.target.classList.contains('billing-popup')) {
                e.target.classList.remove('open');
                setShowPopUp(false);
            }
        }}>
            <div className="container">
                <div className="billing-popup-header">
                    <h2>{popUpContent?.what?.title || "Nouvelle facture"}</h2>
                    <button className="close-button" onClick={() => setShowPopUp(false)}>×</button>
                </div>

                {what === "export" ? (
                    <div className="export-container">
                        <div className="date-range-selector">
                            <div className="form-group">
                                <label>Date de début</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={startDate ? new Date(startDate).toISOString().split('T')[0] : ''}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label>Date de fin</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={endDate ? new Date(endDate).toISOString().split('T')[0] : ''}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="preview-table">
                            <h4>Bills to Export ({previewBills.length})</h4>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Reference</th>
                                        <th>Client</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {previewBills.map(bill => (
                                        <tr key={bill._id}>
                                            <td>{formatDate(new Date(bill.date))}</td>
                                            <td>{bill.reference}</td>
                                            <td>{bill.fullName}</td>
                                            <td>{bill.total?.toFixed(2)} €</td>
                                            <td>{bill.documentState}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="popup-actions">
                            <button
                                className="btn btn-secondary"
                                onClick={() => setShowPopUp(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-primary"
                                onClick={handleExport}
                                disabled={previewBills.length === 0}
                            >
                                Export {previewBills.length} Bills
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="popup-main-content">
                        {action === "" ? null :
                            action === "uploadProducts" ? <ProductPopUpContent />
                                : action === "addProduct" || action === "modifyProduct" ? (
                                    <ProductPopUpForm action={action} userContext={userContext} thing={thing} reset={reset} setIsSubmitting={setIsSubmitting} setShowPopUp={setShowPopUp} setProducts={setProducts} handleProductUpdate={handleProductUpdate} />
                                ) : (
                                    <form ref={formRef} onSubmit={handleSubmit}>
                                        <div>
                                            <div className="mb-3 single-line">
                                                <div className="d-flex line-flex">
                                                    <div className="me-3">
                                                        <label htmlFor="documentState" className="form-label">État :</label>
                                                        <select disabled className="form-select" id="documentState" value={documentState} onChange={(e) => setDocumentState(e.target.value)}>
                                                            <option value="Brouillon">Brouillon</option>
                                                            <option value="Accepté">Accepté</option>
                                                            <option value="Annulé">Annulé</option>
                                                            <option value="Facturé">Facturé</option>
                                                            <option value="Refusé">Refusé</option>
                                                            <option value="Avoir">Avoir</option>
                                                        </select>
                                                    </div>
                                                    <div className="me-3 position-relative">
                                                        <label htmlFor="code" className="form-label required">Code :</label>
                                                        <input disabled={codeLoading || documentState === "Facturé" || documentState === "Validé"} required type="text" className="form-control" id="code" value={code.toString().padStart(8, '0')} onChange={(e) => setCode(e.target.value)} />
                                                        {codeLoading && (
                                                            <div className=" position-absolute top-50 end-0 translate-middle-y" role="status">
                                                                <Spinner strokeWidth={6} width={20} color={"#003952"} />
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="me-3">
                                                        <label htmlFor="date" className="form-label required">Date :</label>
                                                        <input
                                                            disabled={documentState === "Facturé" || documentState === "Validé"}
                                                            required
                                                            type="date"
                                                            className="form-control"
                                                            id="date"
                                                            value={date ? new Date(date).toISOString().split('T')[0] : ''}
                                                            onChange={(e) => setDate(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="me-3">
                                                        <label htmlFor="validity" className="form-label required">{"Date  " + (type === "invoice" ? "de validité du devis :" : "d'échéance de la facture :")}</label>
                                                        <input
                                                            disabled={documentState === "Facturé" || documentState === "Validé"}
                                                            required
                                                            type="date"
                                                            className="form-control"
                                                            id="validity"
                                                            value={validity ? new Date(validity).toISOString().split('T')[0] : ''}
                                                            onChange={(e) => setValidity(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3 single-line">
                                                <div className="d-flex line-flex">

                                                    <div className="me-3 dropdown">
                                                        <label htmlFor="clientId" className="form-label required">Code client :</label>
                                                        <div className="input-group-append">
                                                            <input disabled={documentState === "Facturé" || documentState === "Validé"} required type="text" className="form-control" id="clientId" value={clientId} onChange={(e) => setClientId(e.target.value)} />
                                                            <button
                                                                disabled={documentState === "Facturé" || documentState === "Validé"}
                                                                className="btn btn-outline-secondary dropdown-toggle"
                                                                type="button"
                                                                id="dropdownMenuButton"
                                                                onClick={() => {
                                                                    setLocalShowClientPopUp(true);
                                                                    if (!clientsFetched) {
                                                                        fetchClients();
                                                                    }
                                                                }}
                                                            >
                                                                <BsPersonFill />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="me-3">
                                                        <label htmlFor="fullName" className="form-label">Nom complet :</label>
                                                        <input disabled={documentState === "Facturé" || documentState === "Validé"} type="text" className="form-control" id="fullName" value={fullName} onChange={(e) => setFullName(e.target.value)} />
                                                    </div>
                                                    <div className="me-3">
                                                        <label htmlFor="clientMail" className="form-label">Email :</label>
                                                        <input disabled={documentState === "Facturé" || documentState === "Validé"} type="text" className="form-control" id="clientMail" value={clientMail} onChange={(e) => setClientMail(e.target.value)} />
                                                    </div>
                                                    <div className="me-3">
                                                        <label htmlFor="siret" className="form-label">N° Siret :</label>
                                                        <input disabled={documentState === "Facturé" || documentState === "Validé"} type="text" className="form-control" id="siret" value={siret} onChange={(e) => setSiret(e.target.value.replace(/\D/g, '').slice(0, 14))} />
                                                    </div>
                                                    <div className="me-3">
                                                        <label htmlFor="payingMethod" className="form-label required">Mode de paiement :</label>
                                                        <select disabled={documentState === "Facturé" || documentState === "Validé"} required className="form-select" id="payingMethod" value={payingMethod} onChange={(e) => setPayingMethod(e.target.value)}>
                                                            <option value="">Selectionner un mode de paiement</option>
                                                            <option value="PRE">PRE - Prélèvement</option>
                                                            <option value="VIR">VIR - Virement</option>
                                                            <option value="CB">CB - Carte bancaire</option>
                                                            <option value="CHQ">CHQ - Chèque</option>
                                                            <option value="ESP">ESP - Espèces</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3 single-line">
                                                <label htmlFor="reference" className="form-label">
                                                    Référence :
                                                </label>
                                                <input
                                                    disabled={documentState === "Facturé" || documentState === "Validé"}
                                                    type="text"
                                                    className="form-control"
                                                    id="reference"
                                                    value={reference}
                                                    onChange={(e) => setReference(e.target.value)}
                                                />
                                            </div>
                                            <div className="mb-3 single-line">
                                                <label htmlFor="description" className="form-label">
                                                    Description :
                                                </label>
                                                <textarea
                                                    disabled={documentState === "Facturé" || documentState === "Validé"}
                                                    className="form-control"
                                                    id="description"
                                                    value={description}
                                                    onChange={(e) => setDescription(e.target.value)}
                                                ></textarea>
                                            </div>
                                            <hr />
                                            <div className="mb-3 single-line">
                                                <h5>Adresse principale :</h5>
                                                <div className="d-flex line-flex">
                                                    <div className="me-3">
                                                        <label htmlFor="roadAndNumberPrincipal" className="form-label required">
                                                            Numéro et Rue :
                                                        </label>
                                                        <input
                                                            type="text"
                                                            required
                                                            className="form-control"
                                                            id="roadAndNumberPrincipal"
                                                            value={fullAddress.roadAndNumber}
                                                            disabled={documentState === "Facturé" || documentState === "Validé"}
                                                            onChange={(e) => setFullAddress({ ...fullAddress, roadAndNumber: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="me-3">
                                                        <label htmlFor="complementPrincipal" className="form-label">
                                                            Complément d'adresse :
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="complementPrincipal"
                                                            value={fullAddress.complement}
                                                            disabled={documentState === "Facturé" || documentState === "Validé"}
                                                            onChange={(e) => setFullAddress({ ...fullAddress, complement: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="me-3">
                                                        {" "}
                                                        <label htmlFor="zipCodePrincipal" className="form-label required">
                                                            Code postal :
                                                        </label>{" "}
                                                        <input
                                                            type="text"
                                                            required
                                                            className="form-control"
                                                            id="zipCodePrincipal"
                                                            value={fullAddress.zipCode}
                                                            disabled={documentState === "Facturé" || documentState === "Validé"}
                                                            onChange={(e) => setFullAddress({ ...fullAddress, zipCode: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="me-3">
                                                        <label htmlFor="cityPrincipal" className="form-label required">
                                                            Ville :
                                                        </label>
                                                        <input
                                                            type="text"
                                                            required
                                                            className="form-control"
                                                            id="cityPrincipal"
                                                            value={fullAddress.city}
                                                            disabled={documentState === "Facturé" || documentState === "Validé"}
                                                            onChange={(e) => setFullAddress({ ...fullAddress, city: e.target.value })}
                                                        />
                                                    </div>
                                                    <div>
                                                        <label htmlFor="countryPrincipal" className="form-label required">
                                                            Pays :
                                                        </label>
                                                        <input
                                                            type="text"
                                                            required
                                                            className="form-control"
                                                            id="countryPrincipal"
                                                            value={fullAddress.country}
                                                            disabled={documentState === "Facturé" || documentState === "Validé"}
                                                            onChange={(e) => setFullAddress({ ...fullAddress, country: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <h5>Adresse secondaire / Lieu d'intervention :</h5>{" "}
                                            <div className="mb-3 single-line">
                                                <div className="d-flex line-flex">
                                                    <div className="me-3">
                                                        <label htmlFor="roadAndNumberSecond" className="form-label required">
                                                            Numéro et Rue :
                                                        </label>
                                                        <input
                                                            type="text"
                                                            required
                                                            className="form-control"
                                                            id="roadAndNumberSecond"
                                                            value={fullSecondAddress.roadAndNumber}
                                                            disabled={documentState === "Facturé" || documentState === "Validé"}
                                                            onChange={(e) => setFullSecondAddress({ ...fullSecondAddress, roadAndNumber: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="me-3">
                                                        <label htmlFor="complementSecond" className="form-label">
                                                            Complément d'adresse :
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="complementSecond"
                                                            value={fullSecondAddress.complement}
                                                            disabled={documentState === "Facturé" || documentState === "Validé"}
                                                            onChange={(e) => setFullSecondAddress({ ...fullSecondAddress, complement: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="me-3">
                                                        {" "}
                                                        <label htmlFor="zipCodeSecond" className="form-label required">
                                                            Code postal :
                                                        </label>{" "}
                                                        <input
                                                            type="text"
                                                            required
                                                            className="form-control"
                                                            id="zipCodeSecond"
                                                            value={fullSecondAddress.zipCode}
                                                            disabled={documentState === "Facturé" || documentState === "Validé"}
                                                            onChange={(e) => setFullSecondAddress({ ...fullSecondAddress, zipCode: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="me-3">
                                                        <label htmlFor="citySecond" className="form-label required">
                                                            Ville :
                                                        </label>
                                                        <input
                                                            type="text"
                                                            required
                                                            className="form-control"
                                                            id="citySecond"
                                                            value={fullSecondAddress.city}
                                                            disabled={documentState === "Facturé" || documentState === "Validé"}
                                                            onChange={(e) => setFullSecondAddress({ ...fullSecondAddress, city: e.target.value })}
                                                        />
                                                    </div>
                                                    <div>
                                                        <label htmlFor="countrySecond" className="form-label required">
                                                            Pays :
                                                        </label>
                                                        <input
                                                            type="text"
                                                            required
                                                            className="form-control"
                                                            id="countrySecond"
                                                            value={fullSecondAddress.country}
                                                            disabled={documentState === "Facturé" || documentState === "Validé"}
                                                            onChange={(e) => setFullSecondAddress({ ...fullSecondAddress, country: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <h5>Adresse de facturation :</h5>
                                            <input
                                                type="checkbox"
                                                id="sameAddress"
                                                checked={isTheSameAsFullAddress}
                                                disabled={documentState === "Facturé" || documentState === "Validé"}
                                                onChange={(e) => {
                                                    const checked = e.target.checked;
                                                    setIsTheSameAsFullAddress(checked);
                                                    setBillingAddress((prev) => ({
                                                        ...prev,
                                                        isTheSameAsFullAddress: checked,
                                                    }));
                                                }}
                                            />
                                            <label htmlFor="sameAddress" className="form-label">
                                                -Même adresse que l'adresse principale
                                            </label>{" "}
                                            <div className="mb-4 single-line">

                                                <div className="d-flex line-flex">

                                                    <div className="me-3">

                                                        <label htmlFor="roadAndNumber" className="form-label required">
                                                            Numéro et Rue :
                                                        </label>
                                                        <input
                                                            disabled={isTheSameAsFullAddress || documentState === "Facturé" || documentState === "Validé"}
                                                            type="text"
                                                            required
                                                            className="form-control"
                                                            id="roadAndNumber"
                                                            value={
                                                                isTheSameAsFullAddress
                                                                    ? fullAddress.roadAndNumber
                                                                    : billingAddress.roadAndNumber
                                                            }
                                                            onChange={(e) =>
                                                                setBillingAddress({
                                                                    ...billingAddress,
                                                                    roadAndNumber: e.target.value,
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                    <div className="me-3">

                                                        <label htmlFor="complement" className="form-label">
                                                            Complément d'adresse :
                                                        </label>{" "}
                                                        <input
                                                            disabled={isTheSameAsFullAddress || documentState === "Facturé" || documentState === "Validé"}
                                                            type="text"
                                                            className="form-control"
                                                            id="complement"
                                                            value={
                                                                isTheSameAsFullAddress
                                                                    ? (fullAddress.complement)
                                                                    : billingAddress.complement
                                                            }
                                                            onChange={(e) =>
                                                                setBillingAddress({ ...billingAddress, complement: e.target.value })
                                                            }
                                                        />{" "}
                                                    </div>{" "}
                                                    <div className="me-3">
                                                        {" "}
                                                        <label htmlFor="zipCode" className="form-label required">
                                                            Code postal :
                                                        </label>{" "}
                                                        <input
                                                            disabled={isTheSameAsFullAddress || documentState === "Facturé" || documentState === "Validé"}
                                                            type="text"
                                                            required
                                                            className="form-control"
                                                            id="zipCode"
                                                            value={
                                                                isTheSameAsFullAddress ? fullAddress.zipCode : billingAddress.zipCode
                                                            }
                                                            onChange={(e) =>
                                                                setBillingAddress({ ...billingAddress, zipCode: e.target.value })
                                                            }
                                                        />
                                                    </div>
                                                    <div className="me-3">
                                                        <label htmlFor="city" className="form-label required">
                                                            Ville :
                                                        </label>
                                                        <input
                                                            type="text"
                                                            required
                                                            className="form-control"
                                                            id="city"
                                                            value={isTheSameAsFullAddress ? fullAddress.city : (billingAddress?.city || '')}
                                                            disabled={isTheSameAsFullAddress || documentState === "Facturé" || documentState === "Validé"}
                                                            onChange={(e) =>
                                                                setBillingAddress({ ...billingAddress, city: e.target.value })
                                                            }
                                                        />
                                                    </div>
                                                    <div>
                                                        <label htmlFor="country" className="form-label required">
                                                            Pays :
                                                        </label>
                                                        <input
                                                            disabled={isTheSameAsFullAddress || documentState === "Facturé" || documentState === "Validé"}
                                                            type="text"
                                                            required
                                                            className="form-control"
                                                            id="country"
                                                            value={
                                                                isTheSameAsFullAddress ? fullAddress.country : billingAddress.country
                                                            }
                                                            onChange={(e) =>
                                                                setBillingAddress({ ...billingAddress, country: e.target.value })
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                            <h2 className='single-line'>Construction du Document</h2>
                                            <div className="document-creation">
                                                <div className="buttons">
                                                    <button disabled={documentState === "Facturé" || documentState === "Validé"} type="button" className="btn btn-primary" onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowProductPopUp(true);
                                                    }}><BsPlusCircle /> Article</button>
                                                    <button disabled={documentState === "Facturé" || documentState === "Validé"} type="button" className="btn btn-primary" onClick={(e) => {
                                                        e.preventDefault();
                                                        addSection({ text: "Nouvelle rubrique" });
                                                    }}><BsPlusCircle /> Rubrique</button>
                                                    <button disabled={documentState === "Facturé" || documentState === "Validé"} type="button" className="btn btn-primary" onClick={(e) => {
                                                        e.preventDefault();
                                                        addComment({ text: "Nouveau commentaire" });
                                                    }}><BsPlusCircle /> Commentaire</button>
                                                </div>
                                                <div className="document">
                                                    <table className="table-maxxing" style={{ width: '100%', margin: '2rem auto' }}>
                                                        <thead>
                                                            <tr>
                                                                <th className="code" scope="col"><span>Code</span></th>
                                                                <th className="labl" scope="col"><span>Libellé</span></th>
                                                                <th className="desc" scope="col"><span>Desc.</span></th>
                                                                <th className="qty" scope="col"><span>Qté</span></th>
                                                                <th className="unit" scope="col"><span>Unité</span></th>
                                                                <th className="pric" scope="col"><span>PU HT</span></th>
                                                                <th className="ttc" scope="col"><span>T. HT</span></th>
                                                                <th className="tax" scope="col"><span>TVA</span></th>
                                                                <th className="ttc" scope="col"><span>T. TTC</span></th>
                                                                <th className='act' scope="col"><span>Actions</span></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {Array.isArray(billContents) && billContents.map((item, index) => {
                                                                // Skip rendering if item is undefined or null
                                                                if (!item) {
                                                                    return null;
                                                                }

                                                                if (item.type === 'section') {
                                                                    return (
                                                                        <tr key={index} className="section-row">
                                                                            <td colSpan={10}>
                                                                                <input
                                                                                    type="text"
                                                                                    className="product-input"
                                                                                    value={item.title || ''}
                                                                                    disabled={documentState === "Facturé" || documentState === "Validé"}
                                                                                    onChange={(e) => handleInputChange(e, index, 'title')}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }

                                                                if (item.type === 'comment') {
                                                                    return (
                                                                        <tr key={index} className="comment-row">
                                                                            <td colSpan={10}>
                                                                                <input
                                                                                    type="text"
                                                                                    className="product-input"
                                                                                    value={item.content || ''}
                                                                                    disabled={documentState === "Facturé" || documentState === "Validé"}
                                                                                    onChange={(e) => handleInputChange(e, index, 'content')}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }

                                                                // Default to safe values for calculations
                                                                const quantity = parseFloat(item.quantity || 1);
                                                                const price = parseFloat(item.price || 0);
                                                                const taxes = parseFloat(item.taxes || 0);
                                                                const priceNoTaxes = +(quantity * price).toFixed(2);
                                                                const taxed = parseFloat((priceNoTaxes * (taxes / 100)).toFixed(2));
                                                                const priceFull = +(priceNoTaxes + taxed).toFixed(2);

                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{item.code ? String(item?.code).padStart(7, '0') : ''}</td>
                                                                        <td>
                                                                            <input
                                                                                type="text"
                                                                                className="product-input"
                                                                                value={item.label || ''}
                                                                                disabled={documentState === "Facturé" || documentState === "Validé"}
                                                                                onChange={(e) => handleInputChange(e, index, 'label')}
                                                                            />
                                                                        </td>
                                                                        <td className="desc-btn">
                                                                            <button
                                                                                type="button"
                                                                                onClick={(e) => { e.preventDefault(); e.stopPropagation(); setShowDescriptionDropdown((prev) => (prev === index ? null : index)); }}
                                                                                disabled={documentState === "Facturé" || documentState === "Validé"}
                                                                            >
                                                                                <BsThreeDots />
                                                                            </button>
                                                                            {showDescriptionDropdown === index && (
                                                                                <div className="description-dropdown" onClick={(e) => e.stopPropagation()}>
                                                                                    <textarea
                                                                                        value={item.description || ''}
                                                                                        onChange={(e) => handleInputChange(e, index, 'description')}
                                                                                        disabled={documentState === "Facturé" || documentState === "Validé"}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                type="number"
                                                                                className="product-input"
                                                                                value={item.quantity || 0}
                                                                                disabled={documentState === "Facturé" || documentState === "Validé"}
                                                                                onChange={(e) => handleInputChange(e, index, 'quantity')}
                                                                            />
                                                                        </td>
                                                                        <td>{item.unity || ''}</td>
                                                                        <td>
                                                                            <div className="price-input-container" style={{ position: 'relative', width: '100%' }}>
                                                                                <input
                                                                                    type="number"
                                                                                    className="product-input"
                                                                                    style={{ paddingRight: '20px' }}
                                                                                    value={item.price || price}
                                                                                    disabled={documentState === "Facturé" || documentState === "Validé"}
                                                                                    onChange={(e) => {
                                                                                        const newPrice = parseFloat(e.target.value);
                                                                                        const quantity = parseFloat(item.quantity || 1);

                                                                                        const updatedDocument = [...billContents];
                                                                                        updatedDocument[index] = {
                                                                                            ...updatedDocument[index],
                                                                                            price: newPrice,
                                                                                            totalHT: newPrice * quantity,
                                                                                            totalTTC: newPrice * quantity * (1 + (parseFloat(item.taxes || 20) / 100))
                                                                                        };
                                                                                        setBillContents(updatedDocument);
                                                                                    }}
                                                                                />
                                                                                <span style={{ position: 'absolute', right: '5px', top: '50%', transform: 'translateY(-50%)' }}>€</span>
                                                                            </div>
                                                                        </td>
                                                                        <td>{priceNoTaxes.toFixed(2)}€</td>
                                                                        <td>{taxes}%</td>
                                                                        <td>{priceFull.toFixed(2)}€</td>
                                                                        <td>
                                                                            <div className="d-flex justify-content-end gap-2">
                                                                                {index > 0 && (
                                                                                    <button
                                                                                        disabled={documentState === "Facturé" || documentState === "Validé"}
                                                                                        onClick={() => moveItem(index, 'up')}
                                                                                    >
                                                                                        <BsArrowUp />
                                                                                    </button>
                                                                                )}
                                                                                {index < billContents.length - 1 && (
                                                                                    <button
                                                                                        disabled={documentState === "Facturé" || documentState === "Validé"}
                                                                                        onClick={() => moveItem(index, 'down')}
                                                                                    >
                                                                                        <BsArrowDown />
                                                                                    </button>
                                                                                )}
                                                                                <button
                                                                                    disabled={documentState === "Facturé" || documentState === "Validé"}
                                                                                    onClick={() => handleDelete(index)}
                                                                                >
                                                                                    <BsTrash />
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='d-flex line-flex gap-3 single-line'>
                                                <h2>Réductions ?</h2>
                                                <input
                                                    disabled={documentState === "Facturé" || documentState === "Validé"}
                                                    type="checkbox"
                                                    id="reduction"
                                                    name="reduction"
                                                    value="reduction"
                                                    checked={showReduction}
                                                    onChange={(e) => {
                                                        setShowReduction(e.target.checked);
                                                        if (!e.target.checked) {
                                                            setReduction({ currency: "", amount: 0 });
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div hidden={!showReduction} className="mb-3 single-line">
                                                <div className="d-flex line-flex">
                                                    <div className="me-3">
                                                        <label htmlFor="reductionCurrency" className="form-label">Devise :</label>
                                                        <select
                                                            disabled={documentState === "Facturé" || documentState === "Validé"}
                                                            className="form-select"
                                                            id="reductionCurrency"
                                                            value={reduction?.currency || ""}
                                                            onChange={(e) => {
                                                                setReduction(prev => ({ ...prev, currency: e.target.value }));
                                                            }}
                                                        >
                                                            <option value="">Selectionner une devise</option>
                                                            <option value="%">%</option>
                                                            <option value="€">€</option>
                                                        </select>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="reductionAmount" className="form-label">Montant :</label>
                                                        <input
                                                            disabled={documentState === "Facturé" || documentState === "Validé"}
                                                            type="number"
                                                            className="form-control"
                                                            id="reductionAmount"
                                                            value={reduction?.amount || 0}
                                                            onChange={(e) => {
                                                                setReduction(prev => ({ ...prev, amount: e.target.value }));
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <h2>Totaux</h2>
                                            <div className="mb-3 single-line">
                                                <div className="d-flex line-flex">
                                                    <div className="me-3">
                                                        <label htmlFor="rawTotal" className="form-label">Total HT avant réductions :</label>
                                                        <input disabled type="text" className="form-control" id="rawTotal" value={`${parseFloat(totals.rawTotal).toFixed(2)}€`} />
                                                    </div>
                                                    <div className="me-3">
                                                        <label htmlFor="reductionTotal" className="form-label">Total Réductions :</label>
                                                        <input
                                                            disabled
                                                            type="text"
                                                            className="form-control"
                                                            id="reductionTotal"
                                                            value={(() => {
                                                                if (!reduction?.currency || !reduction?.amount || !totals?.rawTotal) {
                                                                    return "0.00€";
                                                                }
                                                                const rawTotal = parseFloat(totals.rawTotal) || 0;
                                                                const amount = parseFloat(reduction.amount) || 0;
                                                                if (reduction.currency === "%") {
                                                                    return `${((rawTotal * amount) / 100).toFixed(2)}€`;
                                                                } else {
                                                                    return `${amount.toFixed(2)}€`;
                                                                }
                                                            })()}
                                                        />
                                                    </div>
                                                    <div className="me-3">
                                                        <label htmlFor="totalPreTax" className="form-label">Total HT :</label>
                                                        <input disabled type="text" className="form-control" id="totalPreTax" value={`${parseFloat(totals.totalPreTax).toFixed(2)}€`} />
                                                    </div>
                                                    <div className="me-3">
                                                        <label htmlFor="taxesTotal" className="form-label">Total TVA :</label>
                                                        <input disabled type="text" className="form-control" id="taxesTotal" value={`${parseFloat(totals.taxesTotal).toFixed(2)}€`} />
                                                    </div>
                                                    <div className="me-3">
                                                        <label htmlFor="total" className="form-label">Total TTC :</label>
                                                        <input disabled type="text" className="form-control" id="total" value={`${parseFloat(totals.total).toFixed(2)}€`} />
                                                    </div>
                                                </div>
                                            </div>

                                            <h2>Acomptes</h2>
                                            <div className="mb-3 single-line">
                                                <div className="d-flex line-flex">
                                                    <div className="me-3">
                                                        <label htmlFor="currency" className="form-label required">Devise :</label>
                                                        <select disabled={documentState === "Facturé" || documentState === "Validé"} required className="form-select" id="currency" value={currency} onChange={(e) => setCurrency(e.target.value)}>
                                                            <option value="">Selectionner une devise</option>
                                                            <option value="%">%</option>
                                                            <option value="€">€</option>
                                                        </select>
                                                    </div>
                                                    <div className="me-3">
                                                        <label htmlFor="amount" className="form-label required">Montant sur TTC :</label>
                                                        <input disabled={documentState === "Facturé" || documentState === "Validé"} type="text" className="form-control" id="amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mb-3 single-line gap-3 d-flex down-buttons">
                                                <button
                                                    disabled={documentState === "Facturé" || documentState === "Validé"}
                                                    type="submit"
                                                    className="btn btn-primary"
                                                >
                                                    {isSubmitting ? <Spinner strokeWidth={8} width={40} color={"#faba79"} /> : "Enregistrer"}
                                                </button>

                                                {_id !== "" && (
                                                    <div>
                                                        {documentState === "Brouillon" && (
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary status gray"
                                                                onClick={() => {
                                                                    if (what?.type === "Facture") {
                                                                        // For bills, use setError for confirmation and validateBill API
                                                                        userContext.setError({
                                                                            level: 'prompt',
                                                                            message: 'Êtes-vous sûr de vouloir valider cette facture ? Cette action est irréversible.',
                                                                            btn1Text: 'Valider',
                                                                            btn2Text: 'Annuler',
                                                                            btn1Action: async () => {
                                                                                try {
                                                                                    // Use validateBill API call
                                                                                    await userContext.apiReducer("validateBill", {
                                                                                        _id: thing.object._id,
                                                                                        documentState: 'Facturé',
                                                                                        validationDate: new Date().toISOString()
                                                                                    });

                                                                                    // Close the popup
                                                                                    setShowPopUp(false);

                                                                                    // Show success message
                                                                                    userContext.setError({
                                                                                        level: 'success',
                                                                                        message: `La facture ${thing.object.code} a été validée avec succès.`,
                                                                                        btn1Text: 'Fermer',
                                                                                        btn2hidden: true
                                                                                    });

                                                                                    // Refresh the data
                                                                                    if (onUpdate) {
                                                                                        onUpdate();
                                                                                    }
                                                                                } catch (error) {
                                                                                    console.error('Error validating bill:', error);
                                                                                    userContext.setError({
                                                                                        level: 'error',
                                                                                        message: `Erreur lors de la validation: ${error.message || 'Erreur inconnue'}`,
                                                                                        btn1Text: 'OK',
                                                                                        btn2hidden: true
                                                                                    });
                                                                                }
                                                                            }
                                                                        });
                                                                    } else {
                                                                        // For invoices, use setError for confirmation
                                                                        userContext.setError({
                                                                            level: 'prompt',
                                                                            message: `Êtes-vous sûr de vouloir facturer ce devis ?`,
                                                                            btn1Text: 'Facturer',
                                                                            btn2Text: 'Annuler',
                                                                            btn1Action: async () => {
                                                                                try {
                                                                                    // For invoices, call the transition API
                                                                                    const response = await userContext.apiReducer("invoiceTransition", { _id: thing.object._id });

                                                                                    if (response && response.invoice && response.bill) {
                                                                                        userContext.setError({
                                                                                            level: 'success',
                                                                                            message: `Le devis ${response.invoice.code} a été facturé avec succès. Facture ${response.bill.code} créée.`,
                                                                                            btn1Text: 'Fermer',
                                                                                            btn2hidden: true
                                                                                        });

                                                                                        // Close the popup
                                                                                        setShowPopUp(false);

                                                                                        // Refresh the data
                                                                                        if (onUpdate) {
                                                                                            onUpdate();
                                                                                        }
                                                                                    } else {
                                                                                        userContext.setError({
                                                                                            level: 'error',
                                                                                            message: 'Erreur lors de la facturation du devis. Veuillez réessayer.',
                                                                                            btn1Text: 'Fermer',
                                                                                            btn2hidden: true
                                                                                        });
                                                                                    }
                                                                                } catch (err) {
                                                                                    console.error("Error during transition:", err);
                                                                                    userContext.setError({
                                                                                        level: 'error',
                                                                                        message: 'Erreur lors de la facturation du devis: ' + (err.message || err),
                                                                                        btn1Text: 'Fermer',
                                                                                        btn2hidden: true
                                                                                    });
                                                                                }
                                                                            }
                                                                        });
                                                                    }
                                                                }}
                                                            >
                                                                {what?.type === "Facture" ? "Valider la facture" : "Facturer le devis"}
                                                            </button>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                    </form>
                                )
                        }
                    </div>
                )}
            </div>

            {/* Add ClientPopUp component */}
            {localShowClientPopUp && (
                <ClientPopUp
                    showClientPopUp={localShowClientPopUp}
                    setShowClientPopUp={setLocalShowClientPopUp}
                    contacts={contacts}
                    setShowPopUp={setShowPopUp}
                    setClientId={setClientId}
                    setSiret={setSiret}
                    setAddress={setAddress}
                    setPayingMethod={setPayingMethod}
                    setFullAddress={setFullAddress}
                    setFullSecondAddress={setFullSecondAddress}
                    setBillingAddress={setBillingAddress}
                    setIsTheSameAsFullAddress={setIsTheSameAsFullAddress}
                    setFullName={setFullName}
                    setClientMail={setClientMail}
                    handleModif={handleModif}
                    userContext={userContext}
                />
            )}
        </div>
    );
}