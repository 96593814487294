const header = [
    'ID du client',
    'Client',
    'Date de la facture',
    'Libellé',
    'Code',
    'Quantité',
    'Remise',
    'Prix de la nuité HT',
    'Prix de la nuité TTC',
    'PU HT',
    'Total HT',
    'TVA (20%)',
    'Total TTC',
    '% de commission HT',
    'Prix du linge',
    'Prix du ménage HT',
    'Prix du ménage TTC',
]

export function toInputDate(date, toSub) {
    if (!date) return null
    return new Date(date).toISOString().substring(0, toSub ? date.length - toSub : 10)
}

export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

export function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeDeep(target, ...sources);
}

export function DescriptionConditional(props) {
    if (props.value) {
        return null
    }
    return (<div className="form-text danger">
        {props.text ? props.text : "Ce champ est nécessaire et émettra une erreur tant qu'il ne sera pas rempli."}
    </div>)
}

export function minutesToHours(n) {
    const num = n;
    const hours = (num / 60);
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    return rhours + "H " + Math.round(minutes) + "m";
}

export function billing(client) {

    const csvCreator = function (item) {
        const clientOBJ = {
            id: client.clientId,
            name: client.lastName + " " + client.name,
            percentTax: client.subscription.renting.rate
        }
        const data = []

        data.push(header)
        //delete console logs after further improvements
        // console.log("ITEMMM",item)
        for (const booking of item.bookings) {
            if (booking.prices?.night !== undefined
                && booking.laundry?.price !== undefined
                && booking.prices?.cleaning !== undefined) {
                // console.log("booook", booking)
                // Commission
                const taxCommission = round(toTTC(booking.data.took * booking.dates.days) - booking.data.took * booking.dates.days)
                data.push([
                    clientOBJ.id, //id client
                    clientOBJ.name, // nom client
                    getFormattedDate(item.end), // Date de facturation
                    "Location : " + booking.platform + " : " + getFormattedDate(new Date(booking.dates.start)) + "-" + getFormattedDate(new Date(booking.dates.end)) + "(" + booking.dates.days + " nuits)",
                    "- 0000020", //Code produit
                    '1', // Quantité
                    '0', //remise
                    booking.data.night, // prix de la nuité HT
                    toTTC(booking.data.night), // prix de la nuité TTC
                    numberToComas(booking.data.took * booking.dates.days), // prix unitaire
                    numberToComas(booking.data.took * booking.dates.days), // total ht
                    numberToComas(taxCommission), //TVA
                    numberToComas(booking.data.took * booking.dates.days + taxCommission), // total TTC
                    clientOBJ.percentTax, //Taux de commission HT
                    booking.data.laundry, // prix du linge
                    booking.data.cleaning, // prix du ménage
                    toTTC(booking.data.cleaning), // prix du ménage


                ])

                // Ménage
                const taxCleaning = round(toTTC(booking.data.cleaning) - booking.data.cleaning)
                data.push([
                    clientOBJ.id, //id client
                    clientOBJ.name, // nom client
                    getFormattedDate(item.end), // Date de facturation
                    "Récupération du ménage après location : " + booking.platform + " : " + getFormattedDate(new Date(booking.dates.start)) + "-" + getFormattedDate(new Date(booking.dates.end)) + "(" + booking.dates.days + " nuits)",
                    "0000021", //libellé
                    '1', // Quantité
                    '0', //remise
                    '0',
                    '0',
                    numberToComas(booking.data.cleaning), // prix unitaire
                    numberToComas(booking.data.cleaning), // total ht
                    numberToComas(taxCleaning), //TVA
                    numberToComas(booking.data.cleaning + taxCleaning), // total TTC
                    clientOBJ.percentTax, //Taux de commission HT
                    booking.laundry?.price, // prix du linge
                    booking.data.cleaning, // prix du ménage
                    toTTC(booking.data.cleaning), // prix du ménage
                    // console.log("bookingmenage",booking.data.cleaning)
                ])

                // Linge
                const taxLaundry = round(toTTC(booking.data.laundry) - booking.data.laundry)
                data.push([
                    clientOBJ.id, //id client
                    clientOBJ.name, // nom client
                    getFormattedDate(item.end), // Date de facturation
                    "Récupération du linge après location : " + booking.platform + " : " + getFormattedDate(new Date(booking.dates.start)) + "-" + getFormattedDate(new Date(booking.dates.end)) + "(" + booking.dates.days + " nuits)",
                    "0000022", //libellé
                    '1', // Quantité
                    '0', //remise
                    '0',
                    '0',
                    numberToComas(booking.data.laundry), // prix unitaire
                    numberToComas(booking.data.laundry), //total ht
                    numberToComas(taxLaundry), //TVA
                    numberToComas(booking.data.laundry + taxLaundry), //total TTC
                    clientOBJ.percentTax, //Taux de commission HT
                    booking.laundry?.price, // prix du linge
                    booking.data.cleaning, // prix du ménage
                    toTTC(booking.data.cleaning), // prix du ménage
                    // console.log("bookinglinge",booking.data.cleaning)
                ])
            }
        }


        if (item.additionalServices.length > 0) {
            item.additionalServices.forEach(additionalService => {
                try {
                    additionalService.code = additionalService.service.split("-")[0].trim()
                    additionalService.libellé = additionalService.service.split("-")[1].trim()
                } catch (error) {
                    console.log(error)
                }
                additionalService.price = parseFloat(additionalService.price)
                additionalService.quantity = parseInt(additionalService.quantity)
                const taxAdditionalServices = round(toTTC(additionalService.price * additionalService.quantity) - additionalService.price * additionalService.quantity)
                data.push([
                    clientOBJ.id, //id client
                    clientOBJ.name, // nom client
                    getFormattedDate(item.end), // Date de facturation
                    additionalService.libellé ? additionalService.libellé : additionalService.service, // Libellé
                    additionalService.code ? additionalService.code : "0", // code produit
                    additionalService.quantity, // Quantité
                    '0', //remise
                    '0',
                    '0',
                    numberToComas(additionalService.price), // prix unitaire
                    numberToComas(additionalService.price * additionalService.quantity), //total ht
                    numberToComas(taxAdditionalServices), //TVA
                    numberToComas(additionalService.price * additionalService.quantity + taxAdditionalServices), //total TTC
                    clientOBJ.percentTax, //Taux de commission HT

                ])
            });

        }

        if (item.subscription) {
            const taxSub = round(toTTC(item.subscription.price) - item.subscription.price)
            data.push([
                clientOBJ.id, //id client
                clientOBJ.name, // nom client
                getFormattedDate(item.end), // Date de facturation
                "Abonnement personnalisé MYPacôme Engagement 1an",
                "0000001", //libellé
                '1', // Quantité
                '0', //remise
                '0',
                '0',
                numberToComas(item.subscription.price), // prix unitaire
                numberToComas(item.subscription.price), //total ht
                numberToComas(taxSub), //TVA
                numberToComas(item.subscription.price + taxSub), //total TTC
                clientOBJ.percentTax, //Taux de commission HT
            ])
        }
        return data
    }

    const totalCsvCreator = function (list) {
        let data = []
        data.push(header)

        for (const item of list) {
            const toPush = item.csv.slice()
            toPush.splice(0, 1)
            data = data.concat(toPush)
        }

        return data
    }

    const toContractBrief = function (subscription) {
        let subRefined = { ...subscription }
        delete subRefined.modified
        return subRefined
    }

    const findSubscriptionByDate = function (date, list) {
        let subscription = list[0]

        for (let i = 0; i < list.length; i++) {
            if (list[i].start <= date && list[i + 1] ? list[i + 1].start >= date : true) {
                subscription = list[i]
            }
        }
        return subscription
    }

    const findInterventionsByDate = function (start, end, subscription) {
        const interventions = []
        const bookings = []

        let bookingTotal = 0
        let interventionTotal = 0

        for (const property of client.properties) {
            for (const intervention of property.interventions) {
                if (new Date(intervention.date.dateStart) >= start && new Date(intervention.date.dateStart) < end && ["P", "I"].includes(intervention.type) && !intervention.cancelled) {
                    interventions.push(intervention)
                }
            }
            for (const booking of property.bookings) {
                if (new Date(booking.dates.end) >= start && new Date(booking.dates.end) < end && ["airbnb", "booking", "homeaway", "gdf", "rent"].includes(booking.platform) && !booking.cancelled) {

                    const item = booking
                    const rate = subscription.renting.rate ? subscription.renting.rate : 0

                    item.dates.days = getDatesBetween(new Date(booking.dates.start), new Date(booking.dates.end))

                    let total = "-"

                    if (booking.prices?.night !== undefined
                        && booking.laundry?.price !== undefined
                        && booking.prices?.cleaning !== undefined) {
                        const commission = (booking.prices?.night * (rate / 100)) * booking.dates?.days
                        total = round(commission + booking.laundry?.price + booking.prices?.cleaning)
                        bookingTotal += total
                        bookingTotal = round(bookingTotal)
                    }

                    item.data = {
                        title: booking.platform + " : " + getFormattedDate(new Date(booking.dates.start)) + " - " + getFormattedDate(new Date(booking.dates.end)),
                        night: booking.prices?.night,
                        laundry: booking.laundry?.price,
                        cleaning: booking.prices?.cleaning,
                        took: round(booking.prices?.night * (rate / 100)),
                        total: total
                    }
                    bookings.push(item)
                }
            }
        }
        return [interventions, bookings, interventionTotal, bookingTotal]
    }

    const findAdditionalServicesByDate = function (date, end) {
        const additionalServices = client.additionalServices
        let monthlyAdditionalServices = [], totalAdditionalServices = 0

        additionalServices.forEach(additionalService => {
            const additionalServiceDate = new Date(additionalService.date)
            if (additionalServiceDate <= end && additionalServiceDate >= date) {
                monthlyAdditionalServices.push(additionalService)
                totalAdditionalServices += parseFloat(additionalService.price) * parseInt(additionalService.quantity)
            }
        });
        return [monthlyAdditionalServices, totalAdditionalServices]
    }


    // Create list of contracts
    const contractList = [toContractBrief(client.subscription)]

    for (const el of client.subscription.modified) {
        if (!contractList.some(item => item.start === el.modification.start)) contractList.push(el.modification)
    }

    // order contracts by start date (from the oldest to the newer)
    contractList.sort((a, b) => new Date(a.start) > new Date(b.start) ? 1 : -1)

    // Create list of months
    // Each month contain a date, an intervention list, and a booking list
    const monthList = []
    let date = new Date(contractList[0].start)
    if (date < new Date(2020, 0, 1)) date = new Date(2020, 0, 1)
    date.setHours(0)

    while (date < new Date()) {
        date.setMonth(date.getMonth() + 1)
        date.setDate(1)

        const end = new Date(date.getFullYear(), date.getMonth() + 1, date.getDate())
        const subscription = findSubscriptionByDate(date, contractList)
        const [interventions, bookings, interventionTotal, bookingTotal] = findInterventionsByDate(date, end, subscription)
        const [additionalServices, totalAdditionalServices] = findAdditionalServicesByDate(date, end)
        end.setDate(end.getDate() - 1)

        const monthItemTemp = {
            start: new Date(date), end: end, collapse: true, interventions: interventions, bookings: bookings, prices: {
                booking: bookingTotal ? bookingTotal : 0,
                intervention: interventionTotal,
                subscription: subscription.price,
                additionalServices: totalAdditionalServices,
                total: interventionTotal + bookingTotal + subscription.price + totalAdditionalServices
            }, subscription: {
                intervention: subscription.intervention,
                management: subscription.management,
                price: subscription.price,
                rate: subscription.renting?.renting ? subscription.renting.rate : 0
            }, additionalServices
        }
        monthList.push({ csv: csvCreator(monthItemTemp), ...monthItemTemp })
    }
    monthList.pop()
    monthList.reverse()
    if (monthList[0]) monthList[0].collapse = false

    return { list: monthList, csvTotal: totalCsvCreator(monthList) }
}

export function csvMultipleClients(clients) {
    let csv = [header]
    for (const client of clients) {

        const toPush = client.billing.csv.slice()
        toPush.splice(0, 1)
        csv = csv.concat(toPush)
    }

    return csv
}

export function getFormattedDate(date) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    return day + '/' + month + '/' + year;
}

export function nameOfMonth(date) {
    return date.toLocaleString('default', {
        month: 'long',
    })

}

export function toMonthYear(date) {
    const dateTemp = new Date(date)
    return nameOfMonth(dateTemp) + " - " + dateTemp.getFullYear()
}

export function round(value) {
    /**
     * Return value rounded at 2 digits
     * @param {number} value The number you want to round
     * @return {number} The rounded value
     * */
    return +(Math.round(value + "e+2") + "e-2")
}

export function getDatesBetween(start, end) {
    const difference = end.getTime() - start.getTime();
    return Math.abs(Math.ceil(difference / (1000 * 3600 * 24)));
}

export function changeTaxes(value, rate, target) {
    const input = document.getElementById(target);
    input.value = +(Math.round(value / rate + "e+2") + "e-2")
}

export function toTTC(value) {
    return +(Math.round(value / 0.8333333 + "e+2") + "e-2")
}

export function numberToComas(value) {
    return round(value).toString().replace(".", ",");
}
// Cette fonction permet de formater une date au format français jj/mm/aaaa hh'h'mm ou jj/mm/aaaa
export function formatDate(dateString, includeTime = true) {
    if (!dateString) return '';
    const date = new Date(dateString);

    if (includeTime) {
        const day = date.getUTCDate();
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const year = date.getUTCFullYear();
        const hours = date.getUTCHours().toString().padStart(2, '0');
        const minutes = date.getUTCMinutes().toString().padStart(2, '0');
        return `${day}/${month}/${year} ${hours}h${minutes}`;
    } else {
        return date.toLocaleDateString('fr-FR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    }
}

export function keyPerformanceIndicators(selectedClient, selectedDate, returnNumbers) {
    let totalSchedules = 0,
        totalSchedulesHours = 0,
        totalSchedulesMinutes = 0,
        plannedSchedules,
        plannedSchedulesHours = 0,
        plannedSchedulesMinutes = 0,
        validateSchedules = 0,
        validateSchedulesHours = 0,
        validateSchedulesMinutes = 0,
        regularSchedules = 0,
        regularSchedulesHours = 0,
        regularSchedulesMinutes = 0,
        cancelledSchedules = 0,
        cancelledSchedulesHours = 0,
        cancelledSchedulesMinutes = 0,
        current_year = selectedDate.year(),
        current_month = selectedDate.month() + 1;

    selectedClient?.eventsList?.forEach((schedule) => {
        let start = schedule.start
        let year = parseInt(start.split("-")[0]);
        let month = parseInt(start.split("-")[1]);

        if (current_year === year && current_month === month) {
            if (schedule.raw.type === "intervention") {
                if (schedule.raw.type === "intervention" && schedule.raw.item.type === "R" && schedule.raw.item.cancelled === false) {
                    regularSchedulesHours += parseInt(schedule.raw.item.date?.duration?.split(":")[0]);
                    regularSchedulesMinutes += parseInt(schedule.raw.item.date?.duration?.split(":")[1]);

                    if (regularSchedulesMinutes === 60) {
                        regularSchedulesMinutes = 0;
                        regularSchedulesHours += 1;
                    } else if (regularSchedulesMinutes > 60) {
                        regularSchedulesMinutes -= 60;
                        regularSchedulesHours += 1;
                    }
                }
                if (!schedule.raw.item.cancelled && schedule.raw.item.valid) {
                    // Divide by the number of people on it
                    validateSchedulesHours += (parseInt(schedule.raw.item.date?.duration?.split(":")[0]) / schedule.raw.item.people.length);
                    validateSchedulesMinutes += (parseInt(schedule.raw.item.date?.duration?.split(":")[1]) / schedule.raw.item.people.length);

                    if (validateSchedulesMinutes === 60) {
                        validateSchedulesMinutes = 0;
                        validateSchedulesHours += 1;
                    } else if (validateSchedulesMinutes > 60) {
                        validateSchedulesMinutes -= 60;
                        validateSchedulesHours += 1;
                    }
                } else if (!schedule.raw.item.cancelled && !schedule.raw.item.valid) {
                    plannedSchedulesHours += parseInt(schedule.raw.item.date?.duration?.split(":")[0]);
                    plannedSchedulesMinutes += parseInt(schedule.raw.item.date?.duration?.split(":")[1]);

                    if (plannedSchedulesMinutes === 60) {
                        plannedSchedulesMinutes = 0;
                        plannedSchedulesHours += 1;
                    } else if (plannedSchedulesMinutes > 60) {
                        plannedSchedulesMinutes -= 60;
                        plannedSchedulesHours += 1;
                    }


                }

                if (!schedule.raw.item.cancelled && schedule.raw.item.date?.duration) {
                    // Does it have more than one person on it?
                    if (schedule.raw.item.people.length > 1) {
                        // 3 hours by two people is 1.5h/p and we cant have 8.5 hours and 15 minutes can we? 
                        // So we check if there is a rest after the division and add that to minutes instead
                        if ((parseInt(schedule.raw.item.date?.duration?.split(":")[0]) / schedule.raw.item.people.length) % 1 !== 0) {
                            totalSchedulesHours += (parseInt(schedule.raw.item.date?.duration?.split(":")[0]) / schedule.raw.item.people.length) - (parseInt(schedule.raw.item.date?.duration?.split(":")[0]) / schedule.raw.item.people.length) % 1;
                            totalSchedulesMinutes += (parseInt(schedule.raw.item.date?.duration?.split(":")[0]) / schedule.raw.item.people.length) % 1 * (60)
                        } else {
                            totalSchedulesHours += (parseInt(schedule.raw.item.date?.duration?.split(":")[0]) / schedule.raw.item.people.length);
                            totalSchedulesMinutes += (parseInt(schedule.raw.item.date?.duration?.split(":")[1]) / schedule.raw.item.people.length);
                        }
                    } else {
                        totalSchedulesHours += parseInt(schedule.raw.item.date?.duration?.split(":")[0]);
                        totalSchedulesMinutes += parseInt(schedule.raw.item.date?.duration?.split(":")[1]);
                    }
                    if (totalSchedulesMinutes === 60) {
                        totalSchedulesMinutes = 0;
                        totalSchedulesHours += 1;
                    } else if (totalSchedulesMinutes > 60) {
                        totalSchedulesMinutes -= 60;
                        totalSchedulesHours += 1;
                    }
                }
                // here new kpi for cancelled schedules
                if (schedule.raw.item.cancelled) {
                    cancelledSchedulesHours += parseInt(schedule.raw.item.date?.duration?.split(":")[0]);
                    cancelledSchedulesMinutes += parseInt(schedule.raw.item.date?.duration?.split(":")[1]);

                    if (cancelledSchedulesMinutes === 60) {
                        cancelledSchedulesMinutes = 0;
                        cancelledSchedulesHours += 1;
                    }
                    else if (cancelledSchedulesMinutes > 60) {
                        cancelledSchedulesMinutes -= 60;
                        cancelledSchedulesHours += 1;
                    }
                }
            }
        }

    });

    if (String(regularSchedulesMinutes).length <= 1) {
        regularSchedulesMinutes = "0" + regularSchedulesMinutes;
    }
    if (String(validateSchedulesMinutes).length <= 1) {
        validateSchedulesMinutes = "0" + validateSchedulesMinutes;
    }
    if (String(plannedSchedulesMinutes).length <= 1) {
        plannedSchedulesMinutes = "0" + plannedSchedulesMinutes;
    }
    if (String(totalSchedulesMinutes).length <= 1) {
        totalSchedulesMinutes = "0" + totalSchedulesMinutes;
    }
    if (String(cancelledSchedulesMinutes).length <= 1) {
        cancelledSchedulesMinutes = "0" + cancelledSchedulesMinutes;
    }

    if (returnNumbers) {
        validateSchedules = parseInt(validateSchedulesHours) * 60 + parseInt(validateSchedulesMinutes);
        plannedSchedules = parseInt(plannedSchedulesHours) * 60 + parseInt(plannedSchedulesMinutes);
        totalSchedules = parseInt(totalSchedulesHours) * 60 + parseInt(totalSchedulesMinutes);
        regularSchedules = parseInt(regularSchedulesHours) * 60 + parseInt(regularSchedulesMinutes);
        cancelledSchedules = parseInt(cancelledSchedulesHours) * 60 + parseInt(cancelledSchedulesMinutes);
    } else {
        validateSchedules = validateSchedulesHours + "h" + validateSchedulesMinutes;
        plannedSchedules = plannedSchedulesHours + "h" + plannedSchedulesMinutes;
        totalSchedules = totalSchedulesHours + "h" + totalSchedulesMinutes;
        regularSchedules = regularSchedulesHours + "h" + regularSchedulesMinutes;
        cancelledSchedules = cancelledSchedulesHours + "h" + cancelledSchedulesMinutes;
    }



    return [validateSchedules, plannedSchedules, totalSchedules, regularSchedules, cancelledSchedules]
}

export function slugify(str) {
    if (str) {
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();

        // remove accents, swap ñ for n, etc
        var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
        var to = "aaaaeeeeiiiioooouuuunc------";
        for (var i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '-') // collapse whitespace and replace by -
            .replace(/-+/g, '-'); // collapse dashes

        return str;
    }
}


export function compareTwoStringsToInt(property) {
    return function (a, b) {
        a = parseInt(a[property])
        b = parseInt(b[property])
        if (a < b) {
            return -1;
        } else if (a > b) {
            return 1;
        } else {
            return 0;
        }
    };
}