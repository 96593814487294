import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// Icons
import { BsTrash } from "react-icons/bs";
import { FaArrowLeft, FaArrowRight, FaArrowsAlt } from "react-icons/fa";
import FullscreenGallery from '../../Gallery/FullscreenGallery';

export default function ImagesFits(props) {
    const [showLightbox, setShowLightbox] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    if (!props.data || !props.onError || !props.errorText) {
        return (
            <div className="images-fits-container">
                {props.data && props.data.map((photo, index) => (
                    <div key={`imagefit-${index}`}>
                        <img
                            src={getImageUrl(photo)}
                            alt={`Image ${index}`}
                            onLoad={(e) => {
                                if (e.target.naturalWidth > e.target.naturalHeight) {
                                    e.target.parentElement.classList.add("landscape");
                                } else {
                                    e.target.parentElement.classList.add("portrait");
                                }
                                e.target.parentElement.lastChild.style.width = e.target.naturalWidth;
                            }}
                            onError={handleImageError}
                            onClick={() => openLightbox(index)}
                            style={{ cursor: 'pointer' }}
                        />

                    </div>
                ))}

                {showLightbox && props.photos && (
                    <Lightbox
                        mainSrc={getImageUrl(props.photos[photoIndex])}
                        nextSrc={props.photos.length > 1 ? getImageUrl(props.photos[(photoIndex + 1) % props.photos.length]) : undefined}
                        prevSrc={props.photos.length > 1 ? getImageUrl(props.photos[(photoIndex + props.photos.length - 1) % props.photos.length]) : undefined}
                        onCloseRequest={() => setShowLightbox(false)}
                        onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + props.photos.length - 1) % props.photos.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % props.photos.length)
                        }
                        imageTitle={`Image ${photoIndex + 1} of ${props.photos.length}`}
                        reactModalStyle={{
                            overlay: { zIndex: 1500 }
                        }}
                    />
                )}
            </div>
        );
    }

    if (props.data.length === 0) {
        return (
            <p>
                {props.ErrorText}
            </p>
        )
    }

    const handleImageError = (e) => {
        const imgUrl = e.target.src;
        if (!imgUrl || imgUrl.includes('undefined') || imgUrl.includes('null')) {
            e.target.src = "https://i.ibb.co/3NxyCrY/img-Not-Found.png";
        }
    };

    const getImageUrl = (imagePath) => {
        if (!imagePath) return "https://i.ibb.co/3NxyCrY/img-Not-Found.png";

        if (imagePath.startsWith('http')) {
            return imagePath;
        }

        const useLocalApi = false;

        const apiUrl = useLocalApi
            ? "http://localhost:3010"
            : "https://api2.mypacome-manager.fr";

        const cleanPath = imagePath.startsWith('/') ? imagePath : `/${imagePath}`;
        return `${apiUrl}${cleanPath}`;
    };

    const openLightbox = (index) => {
        setPhotoIndex(index);
        setShowLightbox(true);
    };

    return (
        <div className="images-fits-container">
            {props.data.map((photo, index) => (
                <div key={`imagefit-${index}`}>
                    <img
                        src={getImageUrl(photo)}
                        alt={`Image ${index}`}
                        onLoad={(e) => {
                            if (e.target.naturalWidth > e.target.naturalHeight) {
                                e.target.parentElement.classList.add("landscape");
                            } else {
                                e.target.parentElement.classList.add("portrait");
                            }
                            e.target.parentElement.lastChild.style.width = e.target.naturalWidth;
                        }}
                        onError={handleImageError}
                        onClick={() => openLightbox(index)}
                        style={{ cursor: 'pointer' }}
                    />
                </div>
            ))}

            {showLightbox && (
                <Lightbox
                    mainSrc={getImageUrl(props.data[photoIndex])}
                    nextSrc={props.data.length > 1 ? getImageUrl(props.data[(photoIndex + 1) % props.data.length]) : undefined}
                    prevSrc={props.data.length > 1 ? getImageUrl(props.data[(photoIndex + props.data.length - 1) % props.data.length]) : undefined}
                    onCloseRequest={() => setShowLightbox(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + props.data.length - 1) % props.data.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % props.data.length)
                    }
                    imageTitle={`Image ${photoIndex + 1} of ${props.data.length}`}
                    reactModalStyle={{
                        overlay: { zIndex: 1500 }
                    }}
                />
            )}
        </div>
    );
}