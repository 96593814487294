import React, { useState, useEffect, useContext, useMemo } from 'react';
import Spinner from "../../Components/Spinner";
import UserContext from '../../dataContext';
import "../../assets/scss/Billing/bills.scss";
import "../../assets/scss/Billing/billingPopup.scss";
import "../../assets/scss/Billing/billingsPages.scss";
import "../../assets/scss/Billing/table.scss";
import {
    BsCloudDownloadFill,
    BsFillPencilFill,
    BsSearch,
    BsFileEarmarkSpreadsheet,
    BsMailbox,
    BsMailbox2,
    BsArrowRepeat,
    BsFilesAlt,
    BsEye,
    BsFilter,
    BsDownload,
    BsPlus
} from "react-icons/bs";
import PopUp from './Common/PopUp/PopUp';
import ProductsPopUp from './Common/ProductsPopUp';
import ClientPopUp from './Common/ClientPopUp';
import MyDocument from './PDFs/CommonPDF';
import { PDFDownloadLink, pdf, Document } from '@react-pdf/renderer';
import CustomPDFViewer from './PDFs/PDFViewer';
import ReactDOM from 'react-dom';
import BillSingle from './BillSingle';
import { formatDate, calculateValidityDate, sortItems, sortBillsByCode } from './utils/formatters';
import { fetchBills, fetchClients } from './utils/api';
import { Parser } from 'json2csv';
import { parseInvoiceDocument } from '../../Utils/documentParser';
import { PDFDocument } from 'pdf-lib';

const ITEMS_PER_PAGE = 15;

const CACHE_DURATION = 5 * 60 * 1000;
let billsCache = {
    data: null,
    timestamp: 0
};

const shouldRefetchBills = () => {
    // Always refetch if refresh=true is in the URL
    const urlParams = new URLSearchParams(window.location.search);
    const refresh = urlParams.get('refresh');

    if (refresh === 'true') {
        return true;
    }

    return !billsCache.data || (Date.now() - billsCache.timestamp) > CACHE_DURATION;
};

const BillsTable = ({ bills = [], setPreviewBill, setPopUpContent, setAction, formatDate, setShowPopUp, onUpdate, setBills }) => {
    return (
        <table className='table-maxxing'>
            <thead>
                <tr>
                    <th scope="col"><span>État</span></th>
                    <th scope="col"><span>Numéro</span></th>
                    <th scope="col"><span>Date</span></th>
                    <th scope="col"><span>Validité</span></th>
                    <th scope="col"><span>Client</span></th>
                    <th scope="col"><span>Référence</span></th>
                    <th scope="col"><span>Montant HT</span></th>
                    <th scope="col"><span>Montant TTC</span></th>
                    <th scope="col"><span>Actions</span></th>
                </tr>
            </thead>
            <tbody>
                {bills.map((bill) => (
                    <BillSingle
                        key={bill._id}
                        bill={bill}
                        setPreviewBill={setPreviewBill}
                        setPopUpContent={setPopUpContent}
                        setAction={setAction}
                        formatDate={formatDate}
                        setShowPopUp={setShowPopUp}
                        onUpdate={onUpdate}
                        setBills={setBills}
                    />
                ))}
            </tbody>
        </table>
    );
};

const PDFPreviewModal = ({ bill, onClose }) => {
    if (!bill) return null;

    return (
        <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1">
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content" style={{ height: '90vh' }}>
                    <div className="modal-header">
                        <h5 className="modal-title">Aperçu de la facture {bill.code}</h5>
                        <button type="button" className="btn-close" onClick={onClose}></button>
                    </div>
                    <div className="modal-body p-0" style={{ height: 'calc(100% - 60px)' }}>
                        <CustomPDFViewer bill={bill} />
                    </div>
                </div>
            </div>
        </div>
    );
};

function Bills() {
    const [loading, setLoading] = useState(true);
    const [bills, setBills] = useState([]);
    const [rateLimiter, setRateLimiter] = useState(0);
    const [search, setSearch] = useState("");
    const [showPopUp, setShowPopUp] = useState(false);
    const [popUpContent, setPopUpContent] = useState({});
    const [contacts, setContacts] = useState([]);
    const [showClientPopUp, setShowClientPopUp] = useState(false);
    const [clientId, setClientId] = useState("");
    const [reference, setReference] = useState("");
    const [action, setAction] = useState("");
    const [showProductPopUp, setShowProductPopUp] = useState(false);
    const [productsList, setProductsList] = useState([]);
    const [siret, setSiret] = useState("");
    const [payingMethod, setPayingMethod] = useState("");
    const [clientMail, setClientMail] = useState("");
    const [fullName, setFullName] = useState("");
    const [csvData, setCsvData] = useState(null);
    const [csvLoading, setCsvLoading] = useState(false);
    const [exportStartDate, setExportStartDate] = useState('');
    const [exportEndDate, setExportEndDate] = useState('');
    const [showExportDialog, setShowExportDialog] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [address, setAddress] = useState({
        roadAndNumber: "",
        complement: "",
        zipCode: "",
        city: "",
        country: ""
    });
    const [fullAddress, setFullAddress] = useState({
        roadAndNumber: "",
        zipCode: "",
        city: "",
        country: ""
    });
    const [fullSecondAddress, setFullSecondAddress] = useState({
        roadAndNumber: "",
        zipCode: "",
        city: "",
        country: ""
    });
    const [billingAddress, setBillingAddress] = useState({
        roadAndNumber: "",
        zipCode: "",
        city: "",
        country: "",
        isTheSameAsFullAddress: false
    });
    const [isTheSameAsFullAddress, setIsTheSameAsFullAddress] = useState(false);
    const [previewBill, setPreviewBill] = useState(null);
    const [stateDocument, setStateDocument] = useState([]);
    const userContext = useContext(UserContext);
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState({
        documentState: "",
        clientId: "",
        dateRange: {
            start: "",
            end: ""
        }
    });
    const [clientSearch, setClientSearch] = useState("");

    const onUpdate = async () => {
        try {
            console.log("Bills component: onUpdate called - refreshing bills");

            // Clear the cache immediately
            billsCache = {
                data: null,
                timestamp: 0
            };

            // Show loading state
            setLoading(true);

            // Immediately fetch fresh data
            const freshBills = await fetchBills(userContext, {
                bypassCache: true,
                filterDeleted: true
            });

            console.log(`Fetched ${freshBills?.length || 0} bills after update`);

            if (freshBills && freshBills.length > 0) {
                const sortedBills = sortBillsByCode(freshBills);

                // Update the state with new bills
                setBills(sortedBills);

                // Update the cache with fresh data
                billsCache = {
                    data: sortedBills,
                    timestamp: Date.now()
                };
            } else {
                setBills([]);
            }

            // Hide loading state
            setLoading(false);

            // Increment rateLimiter to trigger any other effects
            setRateLimiter(prev => prev + 1);
        } catch (error) {
            console.error('Error updating bills:', error);
            setLoading(false);
        }
    };

    const handleModif = async (formData, action) => {
        try {
            // Validate action type
            if (typeof action === 'object') {
                console.warn('Received object instead of action string, attempting to handle as legacy format');
                // If action is an object, we're likely getting the old format
                return await handleModif(action, 'modifyBill');
            }

            if (!action || typeof action !== 'string') {
                throw new Error('Invalid action type provided');
            }

            let response;
            switch (action.toLowerCase()) {
                case 'modifybill':
                case 'modifyBill':
                    response = await userContext.apiReducer('modifyBill', formData);
                    break;
                case 'addbill':
                case 'addBill':
                    response = await userContext.apiReducer('addBill', formData);
                    break;
                case 'deletebill':
                case 'deleteBill':
                    response = await userContext.apiReducer('deleteBill', formData);
                    break;
                case 'addclient':
                case 'addClient':
                    response = await userContext.apiReducer('addClient', formData);
                    break;
                case 'modifyclient':
                case 'modifyClient':
                    response = await userContext.apiReducer('modifyClient', formData);
                    break;
                default:
                    throw new Error(`Unsupported action type: ${action}`);
            }

            if (response) {
                // Update bills list only for bill-related actions
                if (action.toLowerCase().includes('bill')) {
                    const newBills = sortBillsByCode(
                        bills.map(bill => bill._id === response._id ? response : bill)
                    );
                    setBills(newBills);
                    onUpdate();
                }
            }

            return response;
        } catch (error) {
            console.error('Error handling modification:', error);
            userContext.setError({
                level: 'error',
                message: `Erreur lors de la modification: ${error.message}`,
                btn1Text: 'Fermer',
                btn2hidden: true
            });
            throw error;
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                const refresh = urlParams.get('refresh');
                const forceRefresh = refresh === 'true';

                if (!shouldRefetchBills() && !rateLimiter && !forceRefresh) {
                    setBills(billsCache.data);
                    setLoading(false);
                    return;
                }

                const billsData = await fetchBills(userContext, {
                    bypassCache: true, // Always bypass server cache when fetching
                    filterDeleted: true
                });

                if (!billsData || billsData.length === 0) {
                    console.warn('No bills data received or empty array');
                    setBills([]);
                    setLoading(false);
                    return;
                }

                const sortedBills = sortBillsByCode(billsData);

                billsCache = {
                    data: sortedBills,
                    timestamp: Date.now()
                };

                setBills(sortedBills);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching bills:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [userContext, rateLimiter]);

    useEffect(() => {
        setCurrentPage(1);
    }, [search]);

    const exportToCsv = async () => {
        setCsvLoading(true);
        try {
            // Filter bills by date range if provided and exclude drafts
            let billsToExport = [...bills].filter(bill => bill.documentState !== "Brouillon");

            if (exportStartDate && exportEndDate) {
                const startDate = new Date(exportStartDate);
                const endDate = new Date(exportEndDate);
                endDate.setHours(23, 59, 59, 999); // Set to end of day

                billsToExport = billsToExport.filter(bill => {
                    const billDate = new Date(bill.date);
                    return billDate >= startDate && billDate <= endDate;
                });
            }

            // If no bills to export after filtering
            if (billsToExport.length === 0) {
                userContext.setError({
                    level: 'warning',
                    message: 'Aucune facture valide à exporter dans la période sélectionnée',
                    btn1Text: 'OK',
                    btn2hidden: true
                });
                setCsvLoading(false);
                return;
            }

            // Format numbers with commas for decimal places and quote them for Excel
            const formatNumber = (num) => {
                return '"' + num.toFixed(2).replace('.', ',') + '"';
            };

            // Create CSV content with proper number formatting
            const csvContent = billsToExport.flatMap(bill => {
                const entries = [];
                const totalTTC = parseFloat(bill.totals?.total || 0);
                const totalHT = parseFloat(bill.totals?.totalPreTax || 0);
                const totalTVA = parseFloat(bill.totals?.taxesTotal || 0);
                const clientCode = bill.clientId || 'C0000000';
                const clientName = bill.fullName || '';
                const billDate = new Date(bill.date).toLocaleDateString('fr-FR');
                const billNumber = bill.code;

                // Add client debit entry
                entries.push(`${clientCode};VE;${billDate};${clientName};${formatNumber(totalTTC)};0;${billNumber}`);

                // Add TVA credit entry
                entries.push(`4457130;VE;${billDate};${clientName};0;${formatNumber(totalTVA)};${billNumber}`);

                // Process each product
                const products = bill.document?.products || bill.billContents || bill.items || [];
                products.forEach(product => {
                    const productHT = parseFloat(product.totalHT || product.priceNoTaxes || product.price || product.total || 0);
                    const accountCode = product.account || product.accountingCode || '70600000';
                    entries.push(`${accountCode};VE;${billDate};${clientName};0;${formatNumber(productHT)};${billNumber}`);
                });

                return entries;
            }).join('\n');

            // Create download link for CSV
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute('href', url);
            link.setAttribute('download', `factures_export_${new Date().toISOString().slice(0, 10)}.csv`);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);

            setCsvLoading(false);
        } catch (error) {
            console.error("Error exporting to CSV:", error);
            setCsvLoading(false);
            userContext.setError({
                level: 'error',
                message: `Erreur lors de l'export CSV: ${error.message || 'Erreur inconnue'}`,
                btn1Text: 'OK',
                btn2hidden: true
            });
        }
    };

    // Function to export detailed bill information to CSV
    const exportDetailedToCsv = async () => {
        try {
            setCsvLoading(true);

            // Filter bills by date range if provided and exclude drafts
            let billsToExport = [...bills].filter(bill => bill.documentState !== "Brouillon");

            if (exportStartDate && exportEndDate) {
                const startDate = new Date(exportStartDate);
                const endDate = new Date(exportEndDate);
                endDate.setHours(23, 59, 59, 999); // Set to end of day

                billsToExport = billsToExport.filter(bill => {
                    const billDate = new Date(bill.date);
                    return billDate >= startDate && billDate <= endDate;
                });
            }

            // Check if there are bills to export
            if (billsToExport.length === 0) {
                userContext.setError({
                    level: 'warning',
                    message: 'Aucune facture valide à exporter dans la période sélectionnée',
                    btn1Text: 'OK',
                    btn2hidden: true
                });
                setCsvLoading(false);
                return;
            }

            // Format numbers with commas for decimal places and quote them for Excel
            const formatNumber = (num) => {
                if (num === undefined || num === null || isNaN(num)) return '"0,00"';
                return '"' + parseFloat(num).toFixed(2).replace('.', ',') + '"';
            };

            // Format date to French format DD/MM/YYYY
            const formatDate = (dateString) => {
                if (!dateString) return "";
                const date = new Date(dateString);
                return date.toLocaleDateString('fr-FR');
            };

            // Ensure client code has "C" prefix
            const formatClientCode = (code) => {
                if (!code) return "C0000000";
                return code.startsWith("C") ? code : `C${code}`;
            };

            // Define headers in French
            const headers = [
                "Numéro", "Date", "Date Échéance", "Code Client", "Nom Client",
                "Adresse", "Code Postal", "Ville", "Pays", "Email", "Téléphone",
                "État", "Total HT", "Total TVA", "Total TTC",
                "Mode de Paiement", "Date de Paiement", "Réglée", "Notes"
            ];

            // Create CSV content
            let csvRows = [headers.join(';')];

            billsToExport.forEach(bill => {
                const clientCode = formatClientCode(bill.client?.code || bill.clientId);
                const clientName = bill.client?.name || bill.fullName || "";
                const address = bill.client?.address || "";
                const zipCode = bill.client?.zipCode || "";
                const city = bill.client?.city || "";
                const country = bill.client?.country || "France";
                const email = bill.client?.email || "";
                const phone = bill.client?.phone || "";

                const status = bill.documentState === "Payée" ? "Payée" :
                    (bill.documentState === "En attente" ? "En attente" :
                        (bill.documentState === "Envoyée" ? "Envoyée" : bill.documentState || ""));

                const totalHT = formatNumber(bill.totalHT || bill.totals?.totalPreTax || 0);
                const totalTVA = formatNumber(bill.totalTVA || bill.totals?.taxesTotal || 0);
                const totalTTC = formatNumber(bill.totalTTC || bill.totals?.total || 0);

                const paymentMethod = bill.paymentMethod || "";
                const paymentDate = bill.paymentDate ? formatDate(bill.paymentDate) : "";
                const isPaid = bill.documentState === "Payée" ? "Oui" : "Non";
                const notes = (bill.notes || "").replace(/;/g, ',').replace(/\n/g, ' ');

                const row = [
                    bill.number || bill.code || "",
                    formatDate(bill.date),
                    formatDate(bill.dueDate),
                    clientCode,
                    clientName,
                    address.replace(/;/g, ','),
                    zipCode,
                    city.replace(/;/g, ','),
                    country,
                    email,
                    phone,
                    status,
                    totalHT,
                    totalTVA,
                    totalTTC,
                    paymentMethod,
                    paymentDate,
                    isPaid,
                    notes
                ];

                csvRows.push(row.join(';'));
            });

            const csvContent = csvRows.join('\n');

            // Create date range string for filename
            const dateRangeStr = exportStartDate && exportEndDate ?
                `${exportStartDate.replace(/-/g, '')}_${exportEndDate.replace(/-/g, '')}` :
                new Date().toISOString().slice(0, 10).replace(/-/g, '');

            // Create download link
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute('href', url);
            link.setAttribute('download', `factures_details_${dateRangeStr}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);

            setCsvLoading(false);
            userContext.setError({
                level: 'success',
                message: `Export détaillé terminé avec succès pour ${billsToExport.length} factures`,
                btn1Text: 'OK',
                btn2hidden: true
            });

        } catch (error) {
            console.error("Error exporting detailed CSV:", error);
            setCsvLoading(false);
            userContext.setError({
                level: 'error',
                message: `Erreur lors de l'export CSV détaillé: ${error.message}`,
                btn1Text: 'OK',
                btn2hidden: true
            });
        }
    };

    // Function to generate a combined PDF of all bills in the selected date range
    const exportCombinedPDF = async () => {
        try {
            setCsvLoading(true);

            // Filter bills by date range if provided and exclude drafts
            let billsToExport = [...bills].filter(bill => bill.documentState !== "Brouillon");

            if (exportStartDate && exportEndDate) {
                const startDate = new Date(exportStartDate);
                const endDate = new Date(exportEndDate);
                endDate.setHours(23, 59, 59, 999); // Set to end of day

                billsToExport = billsToExport.filter(bill => {
                    const billDate = new Date(bill.date);
                    return billDate >= startDate && billDate <= endDate;
                });
            }

            // Check if there are bills to export
            if (billsToExport.length === 0) {
                userContext.setError({
                    level: 'warning',
                    message: 'Aucune facture valide à exporter dans la période sélectionnée',
                    btn1Text: 'OK',
                    btn2hidden: true
                });
                setCsvLoading(false);
                return;
            }

            // Show a progress message
            userContext.setError({
                level: 'info',
                message: `Génération du PDF combiné pour ${billsToExport.length} factures...`,
                btn1Text: 'Patienter...',
                btn1hidden: true,
                btn2hidden: true
            });

            // Sort bills by date
            billsToExport.sort((a, b) => new Date(a.date) - new Date(b.date));

            try {
                // Create a new PDF document with standard font
                const mergedPdf = await PDFDocument.create();

                // Process each bill individually to avoid memory issues
                for (let i = 0; i < billsToExport.length; i++) {
                    const bill = billsToExport[i];

                    try {
                        // Generate PDF for the current bill
                        const pdfBlob = await pdf(<MyDocument bill={bill} />).toBlob();

                        // Convert blob to array buffer
                        const arrayBuffer = await new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.onloadend = () => resolve(reader.result);
                            reader.onerror = reject;
                            reader.readAsArrayBuffer(pdfBlob);
                        });

                        // Load the PDF document with the arrayBuffer
                        const pdfDoc = await PDFDocument.load(arrayBuffer, {
                            ignoreEncryption: true,
                            throwOnInvalidObject: false
                        });

                        // Copy pages from the loaded PDF to the merged PDF
                        const pages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());

                        // Add each page to the merged PDF
                        pages.forEach(page => {
                            mergedPdf.addPage(page);
                        });

                        // Update progress message every 5 bills
                        if (i % 5 === 0 || i === billsToExport.length - 1) {
                            userContext.setError({
                                level: 'info',
                                message: `Génération du PDF: ${i + 1}/${billsToExport.length} factures traitées...`,
                                btn1Text: 'Patienter...',
                                btn1hidden: true,
                                btn2hidden: true
                            });
                        }
                    } catch (billError) {
                        console.error(`Erreur lors du traitement de la facture ${bill.code}:`, billError);
                        // Continue with next bill
                    }
                }

                // Save the merged PDF
                const mergedPdfBytes = await mergedPdf.save();

                // Convert to blob and download
                const mergedBlob = new Blob([mergedPdfBytes], { type: 'application/pdf' });

                // Create date range string for filename
                const dateRangeStr = exportStartDate && exportEndDate ?
                    `${exportStartDate.replace(/-/g, '')}_${exportEndDate.replace(/-/g, '')}` :
                    new Date().toISOString().slice(0, 10).replace(/-/g, '');

                // Create download link
                const url = URL.createObjectURL(mergedBlob);
                const link = document.createElement('a');
                link.setAttribute('href', url);
                link.setAttribute('download', `factures_combinees_${dateRangeStr}.pdf`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);

                userContext.setError({
                    level: 'success',
                    message: `Export PDF combiné terminé pour ${billsToExport.length} factures.`,
                    btn1Text: 'OK',
                    btn2hidden: true
                });
            } catch (mergeError) {
                console.error("Erreur lors de la fusion des PDF:", mergeError);
                throw new Error(`Erreur lors de la fusion des PDF: ${mergeError.message}`);
            }

            setCsvLoading(false);

        } catch (error) {
            console.error("Error exporting combined PDF:", error);
            setCsvLoading(false);
            userContext.setError({
                level: 'error',
                message: `Erreur lors de l'export PDF combiné: ${error.message || 'Erreur inconnue'}`,
                btn1Text: 'OK',
                btn2hidden: true
            });
        }
    };

    const addProduct = (product) => {
        setStateDocument(prev => [...prev, product]);
    };

    // Reset filters function
    const resetFilters = () => {
        setFilters({
            documentState: "",
            clientId: "",
            dateRange: {
                start: "",
                end: ""
            }
        });
        setClientSearch("");
    };

    // Get unique clients from bills
    const uniqueClients = useMemo(() => {
        const clientMap = new Map();

        bills.forEach(bill => {
            if (bill.clientId && bill.fullName) {
                clientMap.set(bill.clientId, {
                    id: bill.clientId,
                    name: bill.fullName
                });
            }
        });

        return Array.from(clientMap.values());
    }, [bills]);

    // Filter clients based on search
    const filteredClients = useMemo(() => {
        return uniqueClients.filter(client =>
            !clientSearch ||
            client.name.toLowerCase().includes(clientSearch.toLowerCase())
        );
    }, [uniqueClients, clientSearch]);

    // Optimize the filteredBills memo
    const filteredBills = useMemo(() => {
        const searchLower = search.toLowerCase();
        return bills.filter(bill => {
            // Quick return if no filters are applied
            if (!search && !filters.documentState && !filters.clientId && !filters.dateRange.start && !filters.dateRange.end) {
                return true;
            }

            // Search filter
            const matchesSearch = !search || (
                bill.code?.toLowerCase().includes(searchLower) ||
                bill.fullName?.toLowerCase().includes(searchLower) ||
                bill.reference?.toLowerCase().includes(searchLower) ||
                bill.documentState?.toLowerCase().includes(searchLower)
            );

            if (!matchesSearch) return false;

            // Document state filter
            if (filters.documentState && bill.documentState !== filters.documentState) {
                return false;
            }

            // Client filter
            if (filters.clientId && bill.clientId !== filters.clientId) {
                return false;
            }

            // Date range filter
            if (filters.dateRange.start || filters.dateRange.end) {
                const billDate = new Date(bill.date);

                if (filters.dateRange.start) {
                    const startDate = new Date(filters.dateRange.start);
                    if (billDate < startDate) return false;
                }

                if (filters.dateRange.end) {
                    const endDate = new Date(filters.dateRange.end);
                    endDate.setHours(23, 59, 59, 999);
                    if (billDate > endDate) return false;
                }
            }

            return true;
        });
    }, [bills, search, filters]);

    const totalPages = Math.ceil(filteredBills.length / ITEMS_PER_PAGE);

    const paginatedBills = useMemo(() => {
        return filteredBills.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            currentPage * ITEMS_PER_PAGE
        );
    }, [filteredBills, currentPage]);

    // Replace the export dialog with inline date range filters
    const openExportDialog = () => {
        // Instead of showing a modal, just make the export filters visible
        setShowExportDialog(true);
    }

    if (loading) {
        return (
            <div className={"col cardsContainer mx-1 p-2 mt-3 mt-md-0"}>
                <div className={"row justify-content-between"}>
                    <div>
                        <h1 className="blue fw-bold text-center">Factures</h1>
                    </div>
                </div>
                <Spinner strokeWidth={9} width={40} color={"#003952"} />
                <h2 className="text-center">Chargement des factures...</h2>
            </div>
        );
    }

    return (
        <div className="col border cardsContainer mx-1 p-2 mt-3 mt-md-0">
            {/* Header section */}
            <div className="row justify-content-between align-items-center mb-3">
                <div className="col-auto">
                    <h1>Factures</h1>
                </div>
                <div className="col-auto">
                    <div className="btn-group">
                        <button
                            className="btn btn-outline-primary"
                            onClick={() => {
                                setAction("addBill");
                                setPopUpContent({
                                    what: {
                                        type: "Facture",
                                        title: "Créer une nouvelle facture"
                                    }
                                });
                                setShowPopUp(true);
                            }}
                        >
                            <BsPlus /> Créer une facture
                        </button>
                        <button
                            className="btn btn-outline-secondary ms-2"
                            onClick={() => setShowFilters(!showFilters)}
                        >
                            <BsFilter /> Filtres {showFilters ? '▲' : '▼'}
                        </button>
                        <button
                            className="btn btn-outline-success ms-2"
                            onClick={() => setShowExportDialog(!showExportDialog)}
                        >
                            <BsDownload /> Export {showExportDialog ? '▲' : '▼'}
                        </button>
                    </div>
                </div>
            </div>

            {/* Search box */}
            <div className="row mb-3">
                <div className="col">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Rechercher une facture par numéro, nom client, référence..."
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                    />
                </div>
            </div>

            {/* Export date range filter section */}
            {showExportDialog && (
                <div className="filters-panel mt-3 p-3 border rounded bg-light mb-3">
                    <div className="row align-items-end">
                        <div className="col-12">
                            <h5>Exporter les factures</h5>
                            <p className="small text-muted">Sélectionnez une plage de dates pour filtrer les factures à exporter. Les factures en brouillon sont exclues.</p>
                        </div>
                        <div className="col-md-4 mb-3">
                            <label className="form-label">Du</label>
                            <input
                                type="date"
                                className="form-control"
                                value={exportStartDate}
                                onChange={(e) => setExportStartDate(e.target.value)}
                            />
                        </div>
                        <div className="col-md-4 mb-3">
                            <label className="form-label">Au</label>
                            <input
                                type="date"
                                className="form-control"
                                value={exportEndDate}
                                onChange={(e) => setExportEndDate(e.target.value)}
                            />
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="d-flex flex-column gap-2">
                                <button
                                    className="btn btn-primary w-100"
                                    onClick={exportToCsv}
                                    disabled={csvLoading}
                                >
                                    {csvLoading ? (
                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                    ) : (
                                        <BsDownload className="me-2" />
                                    )}
                                    Exporter en CSV
                                </button>
                                <button
                                    className="btn btn-outline-primary w-100"
                                    onClick={exportDetailedToCsv}
                                    disabled={csvLoading}
                                >
                                    {csvLoading ? (
                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                    ) : (
                                        <BsFilesAlt className="me-2" />
                                    )}
                                    Exporter en CSV détaillé
                                </button>
                                <button
                                    className="btn btn-outline-primary w-100"
                                    onClick={exportCombinedPDF}
                                    disabled={csvLoading}
                                >
                                    {csvLoading ? (
                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                    ) : (
                                        <BsFilesAlt className="me-2" />
                                    )}
                                    Exporter en PDF combiné
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Regular filters section */}
            {showFilters && (
                <div className="filters-panel mt-3 p-3 border rounded bg-light">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-between mb-3">
                            <h5>Filtres avancés</h5>
                            <button
                                className="btn btn-sm btn-outline-secondary"
                                onClick={resetFilters}
                            >
                                Réinitialiser
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 mb-3">
                            <label className="form-label">État du document</label>
                            <select
                                className="form-select"
                                value={filters.documentState}
                                onChange={(e) => setFilters({ ...filters, documentState: e.target.value })}
                            >
                                <option value="">Tous</option>
                                <option value="Brouillon">Brouillon</option>
                                <option value="Facturé">Facturé</option>
                                <option value="Avoir">Avoir</option>
                            </select>
                        </div>
                        <div className="col-md-3 mb-3">
                            <label className="form-label">Client</label>
                            <div className="position-relative">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={clientSearch}
                                    onChange={(e) => setClientSearch(e.target.value)}
                                    placeholder="Rechercher un client..."
                                />
                                {clientSearch && filteredClients.length > 0 && (
                                    <div className="position-absolute w-100 mt-1 bg-white border rounded shadow-sm z-index-dropdown" style={{ maxHeight: '200px', overflowY: 'auto', zIndex: 1000 }}>
                                        <div className="list-group">
                                            {filteredClients.map(client => (
                                                <button
                                                    key={client.id}
                                                    className={`list-group-item list-group-item-action ${filters.clientId === client.id ? 'active' : ''}`}
                                                    onClick={() => {
                                                        setFilters({
                                                            ...filters,
                                                            clientId: client.id
                                                        });
                                                        setClientSearch(client.name);
                                                    }}
                                                >
                                                    {client.name}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Plage de dates</label>
                            <div className="d-flex gap-2">
                                <input
                                    type="date"
                                    className="form-control"
                                    value={filters.dateRange.start}
                                    onChange={(e) => setFilters({
                                        ...filters,
                                        dateRange: { ...filters.dateRange, start: e.target.value }
                                    })}
                                    placeholder="Date de début"
                                />
                                <input
                                    type="date"
                                    className="form-control"
                                    value={filters.dateRange.end}
                                    onChange={(e) => setFilters({
                                        ...filters,
                                        dateRange: { ...filters.dateRange, end: e.target.value }
                                    })}
                                    placeholder="Date de fin"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 d-flex justify-content-end">
                            <button
                                className="btn btn-primary"
                                onClick={() => setShowFilters(false)}
                            >
                                Appliquer les filtres
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {filteredBills.length > 0 ? (
                <>
                    <BillsTable
                        bills={paginatedBills}
                        setPreviewBill={setPreviewBill}
                        setPopUpContent={setPopUpContent}
                        setAction={setAction}
                        formatDate={formatDate}
                        setShowPopUp={setShowPopUp}
                        onUpdate={onUpdate}
                        setBills={setBills}
                    />

                    {totalPages > 1 && (
                        <div className="pagination d-flex justify-content-center align-items-center gap-2 mt-3">
                            <button
                                className="btn btn-primary"
                                disabled={currentPage === 1}
                                onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
                            >
                                Précédent
                            </button>
                            <span>Page {currentPage} sur {totalPages}</span>
                            <button
                                className="btn btn-primary"
                                disabled={currentPage === totalPages}
                                onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
                            >
                                Suivant
                            </button>
                        </div>
                    )}
                </>
            ) : (
                <div className="text-center mt-5">
                    <h3>Aucune facture trouvée</h3>
                    {search && <p>Essayez de modifier votre recherche</p>}
                    {!search && bills.length === 0 && (
                        <div>
                            <p>Aucune facture n'est disponible dans le système.</p>
                            <button
                                className="btn btn-primary mt-3"
                                onClick={() => {
                                    setShowPopUp(true);
                                    setAction("addBill");
                                    setPopUpContent({ what: { type: "Facture", title: "Créer une facture" }, thing: { object: {} } });
                                }}
                            >
                                Créer votre première facture
                            </button>
                            <button
                                className="btn btn-secondary mt-3 ms-2"
                                onClick={() => setRateLimiter(prev => prev + 1)}
                            >
                                Rafraîchir les données
                            </button>
                        </div>
                    )}
                </div>
            )}

            {previewBill && (
                <PDFPreviewModal
                    bill={previewBill}
                    onClose={() => setPreviewBill(null)}
                />
            )}

            <PopUp
                showPopUp={showPopUp}
                setShowPopUp={setShowPopUp}
                popUpContent={popUpContent}
                userContext={userContext}
                handleModif={handleModif}
                formatDate={formatDate}
                setShowClientPopUp={setShowClientPopUp}
                action={action}
                setShowProductPopUp={setShowProductPopUp}
                billContents={stateDocument}
                setBillContents={setStateDocument}
                showProductPopUp={showProductPopUp}
                reference={reference}
                setReference={setReference}
                siret={siret}
                setSiret={setSiret}
                address={address}
                setAddress={setAddress}
                payingMethod={payingMethod}
                setPayingMethod={setPayingMethod}
                billingAddress={billingAddress}
                setBillingAddress={setBillingAddress}
                isTheSameAsFullAddress={isTheSameAsFullAddress}
                setIsTheSameAsFullAddress={setIsTheSameAsFullAddress}
                fullAddress={fullAddress}
                setFullAddress={setFullAddress}
                fullSecondAddress={fullSecondAddress}
                setFullSecondAddress={setFullSecondAddress}
                fullName={fullName}
                setFullName={setFullName}
                clientMail={clientMail}
                setClientMail={setClientMail}
                clientId={clientId}
                setClientId={setClientId}
                setPopUpContent={setPopUpContent}
                setAction={setAction}
                onUpdate={onUpdate}
            />

            <ClientPopUp
                showClientPopUp={showClientPopUp}
                setShowClientPopUp={setShowClientPopUp}
                contacts={contacts}
                setClientId={setClientId}
                setReference={setReference}
                setSiret={setSiret}
                setAddress={setAddress}
                setPayingMethod={setPayingMethod}
                setBillingAddress={setBillingAddress}
                setFullAddress={setFullAddress}
                setFullSecondAddress={setFullSecondAddress}
                setIsTheSameAsFullAddress={setIsTheSameAsFullAddress}
                setFullName={setFullName}
                setClientMail={setClientMail}
            />

            <ProductsPopUp
                showProductPopUp={showProductPopUp}
                setShowProductPopUp={setShowProductPopUp}
                addProduct={addProduct}
                userContext={userContext}
            />
        </div>
    );
}

export default Bills;