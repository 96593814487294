import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import MyDocument from './CommonPDF';

export default function PDFWrapper({ bill, type }) {
    return (
        <div style={{ width: '100%', height: '100%', minHeight: '80vh' }}>
            <PDFViewer width="100%" height="100%" style={{ border: 'none' }}>
                <MyDocument bill={bill} type={type} />
            </PDFViewer>
        </div>
    );
}