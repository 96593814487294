// filepath: c:\Users\mmous\Documents\Work\MYPacôme\PM-2\src\Utils\documentParser.js
/**
 * Utility function to deeply parse and normalize document data from various formats
 * 
 * This handles multiple nested JSON string formats that can be found in invoice/bill documents
 */
export const parseDocumentData = (documentData) => {
    if (!documentData) return [];

    // If already an array of valid objects, return as is
    if (Array.isArray(documentData) &&
        documentData.every(item => item && typeof item === 'object' && !Array.isArray(item))) {
        return documentData.filter(item => item !== null && item !== undefined);
    }

    // Recursive function to unnest JSON strings
    const unnestJSON = (data) => {
        // Base case - not a string
        if (typeof data !== 'string') {
            return data;
        }

        try {
            const parsed = JSON.parse(data);

            // If we got another string, recursively parse it
            if (typeof parsed === 'string') {
                return unnestJSON(parsed);
            }

            // If we parsed an array, recursively parse each item in the array
            if (Array.isArray(parsed)) {
                return parsed.map(item => {
                    if (typeof item === 'string') {
                        try {
                            return unnestJSON(item);
                        } catch (err) {
                            return item;
                        }
                    }
                    return item;
                }).filter(item => item !== null && item !== undefined);
            }

            return parsed;
        } catch (err) {
            // If parsing fails, return the original string
            return data;
        }
    };

    try {
        // Handle array with nested JSON strings
        if (Array.isArray(documentData)) {
            if (documentData.length === 0) {
                return [];
            }

            // Special case for transitioned invoices with a single string element
            if (documentData.length === 1 && typeof documentData[0] === 'string') {
                try {
                    const parsed = unnestJSON(documentData[0]);
                    if (Array.isArray(parsed)) {
                        return parsed.filter(item => item !== null && item !== undefined);
                    }
                } catch (err) {
                    console.warn("Warning: Could not parse document array string", err);
                }
            }

            // Flatten the result in case we have arrays within arrays
            const parsedItems = documentData.flatMap(item => {
                if (!item) return [];

                if (typeof item === 'string') {
                    try {
                        const parsed = unnestJSON(item);
                        return Array.isArray(parsed) ? parsed : [parsed];
                    } catch (err) {
                        console.warn("Warning: Could not parse document item string", err);
                        return [];
                    }
                }

                return [item];
            });

            return parsedItems.filter(item => item !== null && item !== undefined);
        }

        // Handle single string
        if (typeof documentData === 'string') {
            try {
                const parsed = unnestJSON(documentData);
                return Array.isArray(parsed) ? parsed : [parsed];
            } catch (err) {
                console.warn("Warning: Could not parse document string", err);
                return [];
            }
        }

        // Handle single object - wrap in array
        if (documentData && typeof documentData === 'object') {
            return [documentData];
        }

    } catch (error) {
        console.error("Error parsing document data:", error);
    }

    return []; // Return empty array if all else fails
};

/**
 * Enhanced document parser specifically for invoices and bills
 * Handles the various formats that can be encountered in the application
 * @param {Object} document - The document object (invoice or bill)
 * @returns {Array} Normalized array of document items
 */
export const parseInvoiceDocument = (document) => {
    if (!document) return [];

    try {
        // First try billContents (new format)
        if (document.billContents) {
            if (Array.isArray(document.billContents)) {
                return document.billContents.filter(item => item !== null && item !== undefined);
            }
            if (typeof document.billContents === 'string') {
                try {
                    return parseDocumentData(JSON.parse(document.billContents));
                } catch (e) {
                    console.warn('Error parsing billContents string:', e);
                }
            }
        }

        // Then try document (old format)
        if (document.document) {
            // Handle case where document is an array with a single string (common in transitioned invoices)
            if (Array.isArray(document.document) && document.document.length === 1 && typeof document.document[0] === 'string') {
                try {
                    return parseDocumentData(JSON.parse(document.document[0]));
                } catch (e) {
                    console.warn('Error parsing document array string:', e);
                }
            }

            // Handle other array formats
            if (Array.isArray(document.document)) {
                return parseDocumentData(document.document);
            }

            // Handle string format
            if (typeof document.document === 'string') {
                try {
                    return parseDocumentData(JSON.parse(document.document));
                } catch (e) {
                    console.warn('Error parsing document string:', e);
                }
            }

            // Handle direct object
            return parseDocumentData(document.document);
        }
    } catch (error) {
        console.error('Fatal error in parseInvoiceDocument:', error);
    }

    return []; // Return empty array if all else fails
};