/**
 * Utility functions for API operations in the billing system
 */

/**
 * Gets a new code for a document (bill or invoice)
 * @param {Object} userContext - The user context with API functions
 * @param {string} documentType - The type of document ('bill' or 'invoice')
 * @returns {Promise<string>} The generated code
 */
export const getDocumentCode = async (userContext, documentType) => {
    try {
        const endpoint = documentType === 'bill' ? 'getBillCode' : 'getInvoiceCode';
        const response = await userContext.apiReducer(endpoint);
        return response?.code || '';
    } catch (error) {
        console.error(`Error getting ${documentType} code:`, error);
        return '';
    }
};

/**
 * Fetches bills from the API
 * @param {Object} userContext - The user context with API functions
 * @param {Object} options - Options for fetching bills
 * @param {boolean} options.bypassCache - Whether to bypass the cache
 * @param {boolean} options.filterDeleted - Whether to filter out deleted bills
 * @returns {Promise<Array>} The fetched bills
 */
export const fetchBills = async (userContext, options = {}) => {
    try {
        const response = await userContext.apiReducer('getBills', options);

        // Check if response exists and is valid
        if (!response) {
            console.error('Empty response from getBills API');
            return [];
        }

        // If response is an array, return it directly
        if (Array.isArray(response)) {
            return response;
        }

        // If response has a data property that is an array, return that
        if (response.data && Array.isArray(response.data)) {
            return response.data;
        }

        // If response is an object with bills property that is an array
        if (response.bills && Array.isArray(response.bills)) {
            return response.bills;
        }

        // Log the unexpected response format for debugging
        console.error('Unexpected response format from getBills API:', response);
        return [];
    } catch (error) {
        console.error('Error fetching bills:', error);
        return [];
    }
};

/**
 * Fetches invoices from the API
 * @param {Object} userContext - The user context with API functions
 * @returns {Promise<Array>} The fetched invoices
 */
export const fetchInvoices = async (userContext) => {
    try {
        const response = await userContext.apiReducer('getInvoices');
        return Array.isArray(response) ? response : [];
    } catch (error) {
        console.error('Error fetching invoices:', error);
        return [];
    }
};

/**
 * Fetches clients from the API
 * @param {Object} userContext - The user context with API functions
 * @returns {Promise<Array>} The fetched clients
 */
export const fetchClients = async (userContext) => {
    try {
        const response = await userContext.apiReducer('getContacts');
        return Array.isArray(response) ? response : [];
    } catch (error) {
        console.error('Error fetching clients:', error);
        return [];
    }
};

/**
 * Fetches products from the API
 * @param {Object} userContext - The user context with API functions
 * @returns {Promise<Array>} The fetched products
 */
export const fetchProducts = async (userContext) => {
    try {
        const response = await userContext.apiReducer('getProducts');
        return Array.isArray(response) ? response : [];
    } catch (error) {
        console.error('Error fetching products:', error);
        return [];
    }
};

/**
 * Sends an email with a document
 * @param {Object} userContext - The user context with API functions
 * @param {Object} document - The document to send
 * @param {string} documentType - The type of document ('bill' or 'invoice')
 * @returns {Promise<Object>} The API response
 */
export const sendDocumentEmail = async (userContext, document, documentType) => {
    try {
        // Validate that document has a clientMail property
        if (!document.clientMail) {
            throw new Error('Email recipient is required');
        }

        // Ensure the document has all required fields
        const documentToSend = {
            ...document,
            // Make sure these fields are explicitly set
            clientMail: document.clientMail,
            fullName: document.fullName || 'Client',
            code: document.code || '',
            documentType: documentType
        };

        const endpoint = documentType === 'bill' ? 'sendBillEmail' : 'sendInvoiceEmail';
        return await userContext.apiReducer(endpoint, documentToSend);
    } catch (error) {
        console.error(`Error sending ${documentType} email:`, error);
        throw error;
    }
};

/**
 * Validates a document
 * @param {Object} userContext - The user context with API functions
 * @param {Object} document - The document to validate
 * @param {string} documentType - The type of document ('bill' or 'invoice')
 * @returns {Promise<Object>} The API response
 */
export const validateDocument = async (userContext, document, documentType) => {
    try {
        const endpoint = documentType === 'bill' ? 'validateBill' : 'validateInvoice';
        return await userContext.apiReducer(endpoint, document);
    } catch (error) {
        console.error(`Error validating ${documentType}:`, error);
        throw error;
    }
}; 