import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import TableRow from './TableRow';
import numberToWords from './NumInWords';
import Logo from './logo.png'
import MontserratBold from './fonts/MontserratBold.ttf';
import MontserratRegular from './fonts/Montserrat-Regular.ttf';
import { parseInvoiceDocument } from '../../../Utils/documentParser';
Font.register({
    family: 'monts',
    fonts: [
        { src: MontserratRegular, fontWeight: 'normal' },
        { src: MontserratBold, fontWeight: 'bold' }
    ]
});

const blue = "#003952"

const styles = StyleSheet.create({
    page: {
        padding: 30,
        paddingBottom: 60,
        backgroundColor: '#ffffff',
        fontFamily: 'monts',
        display: 'flex',
        flexDirection: 'column',
    },
    footer: {
        position: 'absolute',
        bottom: 30,
        left: 30,
        right: 30,
        textAlign: 'center',
        fontSize: 8,
    },
    footerText: {
        fontSize: 7, // Reduced font size (from 8)
        marginBottom: 2,
        fontFamily: 'monts'
    },
    earlyPaymentText: {
        fontSize: 7, // Reduced font size (from 8)
        marginBottom: 6, // Add space between this text and company info
        fontFamily: 'monts'
    },
    pageNumber: {
        position: 'absolute',
        bottom: 10,
        right: 30,
        fontSize: 8,
        fontFamily: 'monts',
        fontWeight: 700,
        color: blue,
        zIndex: 99
    },
    header: {
        position: 'absolute',
        top: 30,
        left: 30,
        right: 30,
        zIndex: 2
    },
    headerRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    headerText: {
        fontSize: 10,
        color: blue,
        fontFamily: 'monts',
        fontWeight: 700
    },
    content: {
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between', // Restore this
    },
    mainContent: {
        flexDirection: 'column',
        flex: 1
    },
    tableWrapper: {
        flexShrink: 1,    // Allow shrinking
        flexGrow: 0,      // Don't grow
        minHeight: 100,   // Ensure minimum height
    },
    tableSection: {
        marginTop: 10,
        marginBottom: 10,
    },
    totalsSection: {
        width: '100%',
        marginTop: 'auto', // This helps push it to the bottom
        paddingTop: 10, // Increased from 20 to push it down more
        breakInside: 'avoid',
        breakBefore: 'auto',
    },
    upperSection: {
        flexGrow: 0,
    },
    upperContent: {
        flexDirection: 'column',
        marginBottom: 20,
    },
    topSection: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20
    },
    logo: {
        width: 150,
        height: 'auto'
    },
    docType: {
        fontSize: 24,
        color: blue,
        fontFamily: 'monts',
        fontWeight: 700
    },
    docTypeDraft: {
        fontSize: 20, // Smaller font size for draft documents
        color: blue,
        fontFamily: 'monts',
        fontWeight: 700
    },
    section: {
        padding: 0,
        width: '100%'
    },
    topLeftText: {
        color: blue,
        fontSize: 20,
        fontFamily: 'monts',
        fontWeight: 700,
        textAlign: 'right',
        marginBottom: 15,
        marginTop: 15,
        width: 'auto'
    },
    topRightLogo: {
        width: 150,
        position: 'absolute',
        left: 0,
        top: 0,
        marginBottom: 10
    },
    bold: {
        fontFamily: 'monts',
        fontWeight: 700,
        fontSize: 10,
        color: blue
    },
    boldDown: {
        fontFamily: 'monts',
        fontWeight: 700
    },
    textTopIsh: {
        fontSize: 9
    },
    mt: {
        fontFamily: 'monts',
        fontWeight: 'normal'  // Changed from 'normal' to ensure regular weight
    },
    av: {
        fontFamily: 'avenir'
    },
    datesAndStuff: {
        marginBottom: 0,
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        gap: 20
    },
    clientInfo: {
        width: '50%',
        fontFamily: 'monts',
        marginLeft: '50%',
        marginTop: 20,
        marginBottom: 20
    },
    table: {
        display: 'table',
        width: '100%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        marginVertical: 10,
        minHeight: 40,
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#000',
        borderBottomStyle: 'solid',
        minHeight: 25
    },
    tableCol: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCell: {
        fontSize: 10,
        padding: 5,
        fontFamily: 'monts'
    },
    footerPageText: {
        position: 'absolute',
        fontSize: 8,
        bottom: 0,
        right: 0,
        textAlign: 'right',
        color: '#000',
        padding: 10
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: 5
    },
    padd: {
        // paddingHorizontal: 20,
        paddingVertical: 5,
        width: '100%'
    },
    details: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%'
    },
    detail: {
        width: '50%',
        fontFamily: 'monts',
        fontSize: 8
    },
    detailRight: {
        width: '30%'
    },
    headerDetails: {
        backgroundColor: blue,
        color: '#fff',
        width: '100%',
        padding: 5,
        marginBottom: 5,
        fontFamily: 'monts',
        fontWeight: 700
    },
    headerDetailsTotals: {
        backgroundColor: blue,
        color: '#fff',
        width: '50%',
        padding: 5,
        marginBottom: 5,
        fontFamily: 'monts',
        fontWeight: 700
    },
    totalsTotal: {
        width: '50%',
    },
    tr: {
        textAlign: 'right'
    },
    ar: {
        textAlign: 'right'
    },
    legal1: {
        fontSize: 7 // Decreased from 8
    },
    legal2: {
        fontSize: 6
    },
    detailsDiv: {
        height: 'auto',
        alignToBottom: true
    },
    signature: {
        height: 80,
        border: '1px solid #ccc',
        marginTop: 10,
        borderRadius: 5
    },
    reductionRow: {
        backgroundColor: '#F0F0F0',
        paddingVertical: 4
    }
});

const PageNumber = () => (
    <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `Page ${pageNumber} / ${totalPages}`}
        fixed
    />
);

const Footer = ({ type, documentState }) => {
    const isCredit = documentState === 'Avoir' || (documentState && documentState.startsWith && documentState.startsWith('AV-'));

    return (
        <View style={styles.footer} fixed>
            {type !== "invoice" && documentState !== 'Avoir' && !isCredit && (
                <Text style={styles.earlyPaymentText}>
                    Aucun escompte accordé en cas de paiement anticipé. En cas de retard de paiement, se référer à l'article 7 des conditions générales de vente.
                </Text>
            )}
            <Text style={styles.footerText}>
                CF CONCIERGERIE- 3 impasse de Grieu Parc d'activité de Launay 14130 PONT L EVEQUE France- Code NAF (APE) 8299Z
            </Text>
            <Text>
                - SARL au capital social de 1000 €- Siret : 84864867100024- N° TVA FR23848648671
            </Text>
        </View>
    );
};

const Header = ({ type, number }) => {
    const isCredit = number && (number.startsWith('AV-') || number.startsWith('AV'));

    let documentTypeText;
    if (isCredit) {
        documentTypeText = `Avoir N°${number}`;
    } else if (type === 'invoice') {
        documentTypeText = `Devis N°${number}`;
    } else {
        documentTypeText = `Facture N°${number}`;
    }

    return (
        <View style={styles.header} fixed render={({ pageNumber }) => (
            pageNumber === 1 ? null : (
                <View style={styles.headerText}>
                    <Text style={styles.headerText}>MYPacôme</Text>
                    <Text style={styles.headerText}>Client facturé</Text>
                    <Text style={styles.headerText}>{documentTypeText}</Text>
                    <Text style={styles.headerText}>Date : 18/11/2024</Text>
                </View>
            )
        )} />
    );
};

export default function MyDocument({ bill, type }) {
    if (!bill || !bill.date) return null;

    const date = new Date(bill.date);
    const validity = new Date(bill.validity || date);

    const isCredit = bill.documentState === 'Avoir' ||
        bill.type === 'avoir' ||
        (bill.code && bill.code.startsWith('AV-'));

    // Ensure bill.totals has the correct values
    // For the rawTotal (pre-reduction) and totalPreTax (post-reduction)
    const rawTotal = isCredit ?
        -(Math.abs(parseFloat(bill.totals?.rawTotal || 0))) :
        parseFloat(bill.totals?.rawTotal || 0);

    let totalPreTax = rawTotal;
    let reductionAmount = 0;

    // Apply reduction if present
    if (bill.reduction && bill.reduction.amount > 0) {
        if (bill.reduction.currency === "%") {
            reductionAmount = Math.abs(rawTotal) * (bill.reduction.amount / 100);
        } else if (bill.reduction.currency === "€") {
            reductionAmount = parseFloat(bill.reduction.amount || 0);
        }

        // For credit notes, the reduction should have the opposite sign
        if (isCredit) {
            reductionAmount = -reductionAmount;
        }

        // Apply the reduction to the total
        totalPreTax = rawTotal - reductionAmount;
    }

    // Calculate taxes based on the post-reduction amount
    const taxRate = 0.20; // 20% VAT
    const taxesTotal = totalPreTax * taxRate;
    const total = totalPreTax + taxesTotal;

    // Store calculated values
    bill.totals = {
        rawTotal: rawTotal,
        totalPreTax: totalPreTax,
        taxesTotal: taxesTotal,
        total: total
    };

    const formatLineItemNumber = (num) => {
        const absNum = Math.abs(parseFloat(num) || 0);
        if (isCredit && absNum !== 0) {
            return `-${absNum.toFixed(2)}€`;
        }
        return `${absNum.toFixed(2)}€`;
    };

    const formatTotalNumber = (num) => {
        const absNum = Math.abs(parseFloat(num) || 0);
        if (isCredit && absNum !== 0) {
            return `-${absNum.toFixed(2)}€`;
        }
        return `${absNum.toFixed(2)}€`;
    };

    // Convert payment method abbreviations to full text
    const getFullPaymentMethod = (method) => {
        if (!method) return '';

        const methodMap = {
            'PRE': 'Prélèvement',
            'VIR': 'Virement',
            'CB': 'Carte bancaire',
            'CHQ': 'Chèque',
            'ESP': 'Espèces'
        };

        return methodMap[method] || method;
    };

    const billingAddress = bill.billingAddress?.isTheSameAsFullAddress
        ? bill.fullAddress
        : bill.billingAddress;

    const document = parseInvoiceDocument(bill);

    // For credit notes, ensure all items have negative quantities
    if (isCredit && Array.isArray(document)) {
        document.forEach(item => {
            if (item && item.type === 'product' && item.quantity) {
                // Store quantity as negative for credit notes, but keep price positive
                item.quantity = -Math.abs(parseFloat(item.quantity) || 0);
            }
        });
    }

    const calculatePriceNoTaxes = (items) => {
        if (!Array.isArray(items)) return 0;
        return items.reduce((total, item) => {
            if (item && item.quantity && item.price) {
                // For credit notes, quantity will be negative but we want a negative total
                const quantity = parseFloat(item.quantity);
                const price = parseFloat(item.price);
                return total + (quantity * price);
            }
            return total;
        }, 0);
    };

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Header type={type} number={bill.code} />
                <View style={styles.content}>
                    <View style={styles.upperContent}>
                        {/* Upper Section */}
                        <View style={styles.upperSection}>
                            {/* Logo and document type */}
                            <View style={styles.topSection}>
                                <Image style={styles.logo} src={Logo} />
                                <Text style={bill.documentState === "Brouillon" ? styles.docTypeDraft : styles.docType}>
                                    {bill.documentState === "Brouillon" ?
                                        (type === 'invoice' ? "DEVIS BROUILLON" :
                                            isCredit ? "AVOIR BROUILLON" : "FACTURE BROUILLON") :
                                        isCredit ? 'AVOIR' :
                                            type === 'invoice' && bill.documentState !== 'Facturé' ? 'DEVIS' :
                                                'FACTURE'}
                                </Text>
                            </View>

                            {/* Company & Client Info */}
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <View style={{ width: "50%" }}>
                                    <Text style={[styles.bold]}>CF Conciergerie</Text>
                                    <Text style={[styles.mt, styles.textTopIsh]}>14 Bis Rue Georges Clemenceau</Text>
                                    <Text style={[styles.mt, styles.textTopIsh]}>14130 Pont-l'Évêque</Text>
                                </View>
                                <View style={{ width: "28%" }}>
                                    <View style={[styles.datesAndStuff]}>
                                        <Text style={[styles.bold]}>
                                            {isCredit ? "Avoir N°" : bill.documentState === 'Avoir' ? 'Avoir N°' : 'Facture N°'}
                                        </Text>
                                        <Text style={[styles.mt, styles.textTopIsh]}>{bill.code}</Text>
                                    </View>
                                    {isCredit && bill.originalBillCode && (
                                        <View style={[styles.datesAndStuff]}>
                                            <Text style={[styles.bold]}>Facture d'origine N°</Text>
                                            <Text style={[styles.mt, styles.textTopIsh]}>{bill.originalBillCode}</Text>
                                        </View>
                                    )}
                                    <View style={[styles.datesAndStuff]}>
                                        <Text style={[styles.bold]}>Client N°</Text>
                                        <Text style={[styles.mt, styles.textTopIsh]}>{bill.clientId}</Text>
                                    </View>
                                    <View style={[styles.datesAndStuff]}>
                                        <Text style={[styles.bold]}>Date</Text>
                                        <Text style={[styles.mt, styles.textTopIsh]}>{date.toLocaleDateString('fr-FR')}</Text>
                                    </View>
                                    {bill.documentState !== 'Avoir' && (
                                        <View style={[styles.datesAndStuff]}>
                                            <Text style={[styles.bold]}>Échéance</Text>
                                            <Text style={[styles.mt, styles.textTopIsh]}>{validity.toLocaleDateString('fr-FR')}</Text>
                                        </View>
                                    )}
                                </View>
                            </View>

                            <View style={styles.clientInfo}>
                                <Text style={[styles.bold]}>{bill.fullName}</Text>
                                <Text style={[styles.mt, styles.textTopIsh]}>
                                    {billingAddress?.isTheSameAsFullAddress
                                        ? bill?.fullAddress?.roadAndNumber
                                        : billingAddress?.roadAndNumber}
                                </Text>
                                <Text style={[styles.mt, styles.textTopIsh]}>
                                    {billingAddress?.isTheSameAsFullAddress
                                        ? `${bill?.fullAddress?.zipCode} ${bill?.fullAddress?.city}`
                                        : `${billingAddress?.zipCode} ${billingAddress?.city}`}
                                </Text>
                                <Text style={[styles.mt, styles.textTopIsh]}>
                                    {billingAddress?.isTheSameAsFullAddress
                                        ? bill?.fullAddress?.country
                                        : billingAddress?.country}
                                </Text>
                            </View>

                            {/* Display original bill reference for credit notes */}
                            {isCredit && bill.originalBillCode && (
                                <View style={{ marginTop: 20, padding: 10, backgroundColor: '#fff3cd', borderRadius: 5, borderWidth: 1, borderColor: '#f0ad4e', marginBottom: 15 }}>
                                    <Text style={[styles.bold, { color: '#f0ad4e' }]}>
                                        Avoir pour la facture N° {bill.originalBillCode}
                                    </Text>
                                </View>
                            )}
                        </View>
                    </View>

                    <View>
                        <Text style={[styles.bold]}>
                            {bill.reference}
                        </Text>
                        <Text style={[styles.mt, styles.textTopIsh]}>
                            {bill.description}
                        </Text>
                    </View>

                    <View style={styles.tableSection}>
                        {/* Wrap table in flex growing container */}
                        <View style={styles.tableWrapper}>
                            <TableRow
                                data={['Libellé', 'Quantité', 'PU HT', 'Total HT']}
                                widths={['65%', '10%', '12%', '13%']}
                                isHeader={true}
                                tableStyle="header"
                            />
                            {document && document.map((item, index) => {
                                if (!item || typeof item !== 'object') return null;

                                // Ensure required fields exist with fallbacks
                                const safeItem = {
                                    type: item.type || 'product',
                                    label: item.label || '',
                                    quantity: parseFloat(item.quantity) || 0,
                                    price: Math.abs(parseFloat(item.price) || 0),
                                    taxes: Math.abs(parseFloat(item.taxes) || 20)
                                };

                                if (safeItem.type === 'comment' || safeItem.type === 'section') {
                                    return (
                                        <TableRow
                                            key={index}
                                            data={[safeItem.label, '', '', '']}
                                            widths={['65%', '10%', '12%', '13%']}
                                        />
                                    );
                                }

                                // For product rows, show negative signs for credit notes
                                const quantity = safeItem.quantity;
                                const price = safeItem.price;
                                const totalHT = quantity * price;

                                return (
                                    <TableRow
                                        key={index}
                                        data={[
                                            safeItem.label,
                                            isCredit ? `-${Math.abs(quantity)}` : quantity.toString(),
                                            `${price.toFixed(2)}€`,
                                            formatLineItemNumber(totalHT)
                                        ]}
                                        widths={['65%', '10%', '12%', '13%']}
                                    />
                                );
                            })}

                            {/* Add reduction as a line item row if applicable */}
                            {bill.reduction && bill.reduction.amount > 0 && (
                                <TableRow
                                    data={[
                                        `Réduction${bill.reduction.currency === "%" ? ` (${bill.reduction.amount}%)` : ''}`,
                                        '',
                                        '',
                                        `${isCredit ? "+" : "-"}${Math.abs(reductionAmount).toFixed(2)}€`
                                    ]}
                                    widths={['65%', '10%', '12%', '13%']}
                                    tableStyle="comment"
                                />
                            )}

                            {bill.type === 'invoice' && (
                                <Text style={[styles.legal2, styles.ar, styles.padd]}>Devis gratuit</Text>
                            )}
                        </View>
                    </View>

                    <View style={styles.totalsSection} break>
                        {/* Single wrapper for totals */}
                        <View wrap={false}>
                            {/* Totals Section */}
                            <View style={styles.totalsSection} wrap={false}>
                                <View style={[styles.padd, styles.details]}>
                                    <View style={styles.detail}>
                                        <Text style={[styles.headerDetails]}>Règlement de la facture</Text>
                                        <View style={[styles.row]}>
                                            <Text style={[styles.mt, styles.boldDown]}>Règlement :</Text>
                                            <Text style={[styles.mt]}>{getFullPaymentMethod(bill.payingMethod)}</Text>
                                        </View>
                                        {type !== 'invoice' && bill.acomptes && bill.acomptes.amount > 0 ? (
                                            <>
                                                <View style={[styles.row]}>
                                                    <Text style={[styles.mt]}>Échéance (Acompte) :</Text>
                                                    <Text style={[styles.mt]}>{`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`}</Text>
                                                </View>
                                                <View style={[styles.row]}>
                                                    <Text style={[styles.mt]}>Montant TTC Acompte :</Text>
                                                    <Text style={[styles.mt]}>{bill.acomptes.currency === '€' ? `${bill.acomptes.amount}€` : `${(bill.acomptes.amount / 100 * bill.totals.total).toFixed(2)}€`}</Text>
                                                </View>
                                                <View style={[styles.row]}>
                                                    <Text style={[styles.mt]}>Échéance (Solde) :</Text>
                                                    <Text style={[styles.mt]}>{`${validity.getDate()}/${validity.getMonth() + 1}/${validity.getFullYear()}`}</Text>
                                                </View>
                                                <View wrap={false} style={[styles.row]}>
                                                    <Text style={[styles.mt]}>Montant TTC Solde :</Text>
                                                    <Text style={[styles.mt]}>{bill.acomptes.currency === '€' ? `${(bill.totals.total - bill.acomptes.amount).toFixed(2)}€` : `${(bill.totals.total - (bill.acomptes.amount / 100 * bill.totals.total)).toFixed(2)}€`}</Text>
                                                </View>
                                            </>
                                        ) : (
                                            <View style={[styles.row]}>
                                            </View>
                                        )}
                                    </View>
                                    <View style={[styles.detail, styles.detailRight]}>
                                        <View style={[styles.row]}>
                                            <Text style={[styles.mt, styles.boldDown]}>Total HT :</Text>
                                            <Text style={[styles.tr, styles.totalsTotal, styles.mt]}>
                                                {formatTotalNumber(bill.totals.totalPreTax)}
                                            </Text>
                                        </View>
                                        <View style={[styles.row]}>
                                            <Text style={[styles.mt, styles.boldDown]}>Total TVA à 20% :</Text>
                                            <Text style={[styles.tr, styles.totalsTotal, styles.mt]}>
                                                {formatTotalNumber(bill.totals.taxesTotal)}
                                            </Text>
                                        </View>
                                        <View style={[styles.row]}>
                                            <Text style={[styles.headerDetailsTotals]}>Total TTC: </Text>
                                            <Text style={[styles.tr, styles.mt, styles.headerDetailsTotals, styles.totalsTotal]}>
                                                {formatTotalNumber(bill.totals.total)}
                                            </Text>
                                        </View>
                                        <View style={[styles.row]}>
                                            <Text style={[styles.legal1, styles.ar]}>
                                                {numberToWords(bill.totals?.total || 0, isCredit)}
                                            </Text>
                                        </View>
                                    </View>
                                </View>



                                <View style={[styles.padd, styles.details]}>
                                    <View style={[styles.detail]}>
                                        <Text style={[styles.headerDetails]}>Coordonnées bancaires</Text>
                                        <View style={[styles.row]}>
                                            <Text style={[styles.mt, styles.boldDown]}>Nom :</Text>
                                            <Text style={[styles.mt]}>CF CONCIERGERIE</Text>
                                        </View>
                                        <View style={[styles.row]}>
                                            <Text style={[styles.mt, styles.boldDown]}>IBAN :</Text>
                                            <Text style={[styles.mt]}>FR76 1830 6002 2536 1085 4624 186</Text>
                                        </View>
                                        <View style={[styles.row]}>
                                            <Text style={[styles.mt, styles.boldDown]}>BIC :</Text>
                                            <Text style={[styles.mt]}>AGRIFRPP883</Text>
                                        </View>
                                    </View>

                                    {type === 'invoice' && bill.documentState !== "Facturé" && (
                                        <View style={[styles.detail, styles.detailRight]}>
                                            <View style={[styles.detail, { width: "100%" }]}>
                                                <Text style={[styles.headerDetails]}>Bon pour accord</Text>
                                                <Text style={[styles.mt, styles.boldDown]}>Date et signature</Text>
                                                <View style={styles.signature} />
                                            </View>
                                        </View>
                                    )}

                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                <Footer type={type} documentState={bill.documentState} />
                <PageNumber />
            </Page>
        </Document>
    );
}
