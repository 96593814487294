import { useContext, useEffect, useRef, useState } from 'react'
import UserContext from '../../dataContext'
import Spinner from '../../Components/Spinner'
import { Parser } from 'json2csv';
// Rename the import to avoid naming conflicts
import CommonPopUp from "./Common/PopUp/PopUp";

import "../../assets/scss/Billing/contacts.scss"
import "../../assets/scss/Billing/billingPopup.scss"
import "../../assets/scss/Billing/table.scss"
import "../../assets/scss/Billing/billingsPages.scss"

import { BsCloudDownloadFill, BsCloudUploadFill, BsFillPencilFill, BsTrashFill, BsXLg } from 'react-icons/bs'

const ITEMS_PER_PAGE = 15;

export default function Contacts() {
    const userContext = useContext(UserContext)
    const [loading, setLoading] = useState(true)
    const [showPopUp, setShowPopUp] = useState(false)
    const [popUpContent, setPopUpContent] = useState(null)
    const [contacts, setContacts] = useState([])
    const [activeSidebar, setActiveSidebar] = useState(false)
    const [sidebarContent, setSidebarContent] = useState(null)
    const [search, setSearch] = useState("")
    const [action, setAction] = useState("")
    const [showExternalPopUp, setShowExternalPopUp] = useState(false)
    const [, setExternalPopUpContent] = useState(null)
    const [, setClientId] = useState("");
    const [currentPage, setCurrentPage] = useState(1);

    function convertToCSV(data) {
        const fields = [
            { label: 'Adresse', value: 'address' },
            { label: 'Archive', value: 'archive' },
            { label: 'Civilité', value: 'civility' },
            { label: 'ID Client', value: 'clientId' },
            { label: 'Commentaires', value: 'comments' },
            { label: 'Contacts', value: 'contacts' },
            { label: 'Email', value: 'email' },
            { label: 'Forme Juridique', value: 'formeJuridique' },
            { label: 'Numéro et Route', value: 'fullAddress.roadAndNumber' },
            { label: 'Code Postal', value: 'fullAddress.zipCode' },
            { label: 'Ville', value: 'fullAddress.city' },
            { label: 'Pays', value: 'fullAddress.country' },
            { label: 'Adresse de Facturation', value: 'invoiceAddress' },
            { label: 'Nom de Famille', value: 'lastName' },
            { label: 'Prénom', value: 'name' },
            { label: 'Téléphone', value: 'phone' },
            { label: 'Téléphone 2', value: 'phone2' },
            { label: 'Professionnel', value: 'professional' },
            { label: 'SIRET', value: 'siret' },
            { label: 'Raison Sociale', value: 'socialReason' },
            { label: 'Statut', value: 'status' },
            { label: 'Infos TVA', value: 'taxesInfo' }
        ];
        const json2csvParser = new Parser({ fields, withBOM: true });
        return json2csvParser.parse(data);
    }

    function downloadCSV(csv, filename) {
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }


    // Custom route to get only unarchived users
    useEffect(() => {
        if (contacts.length > 0) return
        userContext.apiReducer("getRealClients")
            .then((response) => {
                // remove all the false values in the response:
                let filteredResponse = response.clients.filter((client) => client !== false)
                let sortedContacts = sortContacts(filteredResponse, "abc", "clientId")
                sortedContacts = sortContacts(sortedContacts, "abc", "lastName")
                setContacts(sortedContacts)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err, "error")
            });
    }, [contacts, userContext]);

    function sortContacts(list, method, key, log) {
        switch (method) {
            case "abc":
                if (key) {
                    list.sort((a, b) => {
                        if (a[key] < b[key]) return -1;
                        if (a[key] > b[key]) return 1;
                        return 0;
                    });
                }
                break;

            default:
                break;
        }
        return list
    }

    function handleModif(newThing, oldThing, type) {
        switch (type) {
            case "modifyClient":
                // Find the old client in the list, replace it with the new one
                let newClients = contacts.map((client) => {
                    if (client.email === oldThing.email) {
                        return newThing
                    }
                    return client
                })
                setContacts(newClients)
                setSidebarContent({ thing: newThing, what: "Contact" })
                break;
            case "addClient":
                let newClientsList = [...contacts, newThing]
                setContacts(newClientsList)
                // Open sidebar with the new client
                setSidebarContent({ thing: newThing, what: "Contact" })
                setActiveSidebar(true)
                break;
            case "deleteClient":
                let newClientsList2 = contacts.filter((client) => client.email !== oldThing.email)
                setContacts(newClientsList2)
                break;
            default:
                break;
        }
    }

    // Enhanced search with multiple fields
    const filteredContacts = contacts?.filter(contact =>
        search ? (
            contact.lastName?.toLowerCase().includes(search.toLowerCase()) ||
            contact.firstName?.toLowerCase().includes(search.toLowerCase()) ||
            contact.clientId?.toLowerCase().includes(search.toLowerCase()) ||
            contact.email?.toLowerCase().includes(search.toLowerCase()) ||
            contact.phoneNumber?.includes(search) ||
            contact.company?.toLowerCase().includes(search.toLowerCase())
        ) : true
    );

    const totalPages = Math.ceil(filteredContacts.length / ITEMS_PER_PAGE);
    const paginatedContacts = filteredContacts.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    );

    if (loading) {
        return (
            <div className={"col cardsContainer mx-1 p-2 mt-3 mt-md-0"}>
                <div className={"row justify-content-between"}>
                    <div>
                        <h1 className="blue fw-bold text-center">Contacts</h1>
                    </div>
                </div>
                <Spinner strokeWidth={9} width={40} color={"#003952"} />
                <h2 className="text-center">Chargement des contacts...</h2>
            </div>
        )
    }
    else if (!loading) {
        return (
            <div className={"contacts col cardsContainer mx-1 p-2 mt-3 mt-md-0"}>
                <div className={"row justify-content-between"}>
                    <div>
                        <h1 className="blue fw-bold text-center">Contacts</h1>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className="search-and-stuff d-flex justify-content-between align-items-center">
                            <button className="btn btn-primary nice-button ms-2" onClick={(e) => {
                                e.preventDefault();
                                const csv = convertToCSV(contacts);
                                downloadCSV(csv, 'clients.csv');
                            }}><BsCloudDownloadFill /></button>
                            <button className="btn btn-primary nice-button ms-2" onClick={(e) => {
                                e.preventDefault();
                                setShowExternalPopUp(true)
                                setAction("uploadClients")
                                setExternalPopUpContent({ what: { type: "Clients", title: "Importer des clients" }, thing: {} })
                            }}><BsCloudUploadFill /></button>
                            <div className="search">
                                <input type="text" id="formControlLg" className="form-control form-control-lg" placeholder='Rechercher un produit...' onInput={(e) => setSearch(e.target.value)} />
                            </div>
                            <button className="btn btn-primary nice-button ms-2" onClick={(e) => {
                                e.preventDefault();
                                setShowPopUp(true);
                                setPopUpContent({ what: { type: "Contact", title: "Créer un contact" }, thing: { object: {} } });
                            }}>+</button>
                        </div>
                        <table className='table-maxxing'>
                            <thead>
                                <tr>

                                    <th scope='col' className="code-column">
                                        <span>Code</span>
                                    </th>
                                    <th scope='col' className="full">
                                        <span>Nom complet</span>
                                    </th>
                                    <th scope='col' className="less">
                                        <span>Personne</span>
                                    </th>
                                    <th scope='col' className="less">
                                        <span>Téléphone</span>
                                    </th>
                                    <th scope='col' className="full">
                                        <span>Mail</span>
                                    </th>
                                    <th scope='col' className="btn-column">
                                        <span></span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedContacts?.map((contact, index) => {
                                    const label = (contact.status === "Personne physique" || typeof contact.status === "undefined") ? `${contact.name} ${contact.lastName}` : contact.socialReason
                                    if (contacts.archive) return null
                                    else if (search && !label.toLowerCase().includes(search?.toLowerCase())) return null
                                    return (
                                        <tr data-num-ligne="0" className="" key={index} onClick={() => {
                                            setActiveSidebar(true)
                                            setSidebarContent({ thing: contact, what: "Contact" })
                                            setClientId(contact.clientId)
                                        }} >

                                            <td data-label="Code" className="code-column" title="Afficher l'aperçu de l'article">
                                                <span className="recherche-selected  j-dont-close-sliding-panel"></span>{(contact.clientId ? contact.clientId : "")}
                                            </td>
                                            <td data-label="Nom complet" className="full" title="Afficher l'aperçu de l'article">
                                                <strong><span className="recherche-selected "></span><span className='name'>{(contact.status === "Personne physique" || typeof contact.status === "undefined") ? contact.lastName : ""}</span> {(contact.status === "Personne physique" || typeof contact.status === "undefined") ? contact.name : contact.socialReason}</strong>
                                            </td>
                                            <td data-label="Personne" className="less capitalize-text" title="Afficher l'aperçu de l'article">
                                                <span className="recherche-selected  j-dont-close-sliding-panel"></span>{(typeof contact.status === "undefined" || contact.status === "Personne physique") ? "Physique" : "Morale"}
                                            </td>
                                            <td data-label="Téléphone" className="less" title="Afficher l'aperçu de l'article">
                                                <span className="recherche-selected  j-dont-close-sliding-panel"></span>{contact.phone}
                                            </td>
                                            <td data-label="Mail" className="full" title="Afficher l'aperçu de l'article">
                                                <span className="recherche-selected  j-dont-close-sliding-panel"></span>{contact.email}
                                            </td>
                                            <td className="btn-column">
                                                <button onClick={(e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    setShowPopUp(true);
                                                    setPopUpContent({ what: { type: "Contact", title: "Modifier un contact" }, thing: { name: (contact.status === "Personne physique" || typeof contact.status === "undefined") ? `${contact.name} ${contact.lastName}` : contact.socialReason, object: contact } })
                                                }}><BsFillPencilFill /></button>
                                                <button className="delete-sidebar" onClick={(e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    userContext.setError({
                                                        level: 'danger',
                                                        message: 'Êtes vous sur de vouloir supprimer ce client ?',
                                                        btn1Text: 'Revenir',
                                                        btn2Text: 'Supprimer',
                                                        btn2Action: () => {
                                                            userContext.apiReducer("modifyClient", { archive: true }, { email: sidebarContent.thing.email })
                                                                .then((response) => {
                                                                    handleModif(response, sidebarContent.thing, "deleteClient")
                                                                    setActiveSidebar(false)
                                                                })
                                                                .catch((err) => {
                                                                    console.log(err, "error")
                                                                });
                                                        }
                                                    })
                                                }}><BsTrashFill /></button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>

                        {/* Pagination Controls */}
                        <div className="pagination d-flex justify-content-center align-items-center gap-2 mt-3">
                            <button
                                className="btn btn-primary"
                                disabled={currentPage === 1}
                                onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
                            >
                                Précédent
                            </button>
                            <span>Page {currentPage} of {totalPages}</span>
                            <button
                                className="btn btn-primary"
                                disabled={currentPage === totalPages}
                                onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
                            >
                                Suivant
                            </button>
                        </div>
                    </div>
                </div>
                <div id='contact-sidebar' className={'col' + (activeSidebar ? " active" : "")}>
                    <div className='header'>
                        <button className="close-sidebar" onClick={() => setActiveSidebar(false)}><BsXLg /></button>
                        <button className="edit-sidebar" onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setShowPopUp(true);
                            setPopUpContent({ what: { type: "Contact", title: "Modifier un contact" }, thing: { name: (sidebarContent.thing.status === "Personne physique" || typeof sidebarContent.thing.status === "undefined") ? `${sidebarContent.thing.name} ${sidebarContent.thing.lastName}` : sidebarContent.thing.socialReason, object: sidebarContent.thing } })
                        }}><BsFillPencilFill /></button>
                        <button className="delete-sidebar" onClick={(e) => {
                            userContext.setError({
                                level: 'danger',
                                message: 'Êtes vous sur de vouloir supprimer ce client ?',
                                btn1Text: 'Revenir',
                                btn2Text: 'Supprimer',
                                btn2Action: () => {
                                    userContext.apiReducer("modifyClient", { archive: true }, { email: sidebarContent.thing.email })
                                        .then((response) => {
                                            handleModif(response, sidebarContent.thing, "deleteClient")
                                            setActiveSidebar(false)
                                        })
                                        .catch((err) => {
                                            console.log(err, "error")
                                        });
                                }
                            })
                        }}><BsTrashFill /></button>

                        {sidebarContent ? <h2>{sidebarContent.what}</h2> : ""}
                    </div>
                    {sidebarContent ?
                        <div className='content'>
                            <div className='row'>
                                <div className='col-12'>
                                    <h2>
                                        {`${sidebarContent.thing.clientId ? sidebarContent.thing.clientId : "?"} - `}
                                        {sidebarContent.thing.status === "Personne physique" || typeof sidebarContent.thing.status === "undefined"
                                            ? `${sidebarContent.thing.name} ${sidebarContent.thing.lastName}`
                                            : sidebarContent.thing.socialReason}
                                    </h2>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <ul>
                                        <div hidden={!(sidebarContent.thing.status === "Personne physique" || typeof sidebarContent.thing.status === "undefined")}>
                                            <li><span>Civilité :</span> {sidebarContent.thing.civility}</li>
                                        </div>
                                        <li><span>Email : </span>{sidebarContent.thing.email}</li>
                                        <li><span>Personne :</span> {(sidebarContent.thing.status === "Personne physique" || typeof sidebarContent.thing.status === "undefined") ? "Physique" : "Morale"}</li>
                                        <li><span>Téléphone 1 :</span> {sidebarContent.thing.phone}</li>
                                        <li><span>Téléphone 2 :</span> {sidebarContent.thing.phone2}</li>
                                        <li>
                                            <span>Adresse : </span>
                                            {sidebarContent.thing.fullAddress?.roadAndNumber ? (
                                                <ul>
                                                    <li>Numéro et route: {sidebarContent.thing.fullAddress.roadAndNumber}</li>
                                                    <li>Code postal : {sidebarContent.thing.fullAddress.zipCode}</li>
                                                    <li>Ville : {sidebarContent.thing.fullAddress.city}</li>
                                                    <li>Pays : {sidebarContent.thing.fullAddress.country}</li>
                                                </ul>
                                            ) : sidebarContent.thing.address ? sidebarContent.thing.address : (
                                                /\D/.test(sidebarContent?.invoiceAddress?.zip)
                                                    ? `${sidebarContent.thing.invoiceAddress.number} ${sidebarContent.thing.invoiceAddress.road} ${sidebarContent.thing.invoiceAddress.zip} ${sidebarContent.thing.invoiceAddress.city}`
                                                    : sidebarContent.thing.invoiceAddress.zip
                                            )}
                                        </li>


                                        <li><span>Commentaires :</span> {sidebarContent.thing.comments}</li>
                                        <div hidden={sidebarContent.thing.status === "Personne physique" || typeof sidebarContent.thing.status === "undefined"}>
                                            <li><span>Infos TVA :</span> {sidebarContent.thing.taxesInfo} </li>
                                            <li><span>Raison sociale :</span> {sidebarContent.thing.socialReason} </li>
                                            <li><span>Forme juridique :</span> {sidebarContent.thing.formeJuridique} </li>
                                            <li><span>N° Siret :</span> {sidebarContent.thing.siret} </li>
                                        </div>
                                    </ul>
                                    {sidebarContent.thing.contacts?.length > 0 ? (
                                        <div>
                                            <h2>Contacts associés :</h2>
                                            <ul>
                                                {sidebarContent.thing.contacts.map((contact, index) => {
                                                    return (
                                                        <li key={index}><span>{contact.title}:</span> <br /> <span>Email :</span> {contact.email} <br /> <span>Description :</span> {contact.description} <br /> <span>Téléphone :</span> {contact.phone}</li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    ) : ""}
                                </div>
                            </div>
                        </div>
                        : ""}
                </div>
                <PopUp
                    showPopUp={showPopUp}
                    popUpContent={popUpContent}
                    setShowPopUp={setShowPopUp}
                    userContext={userContext}
                    handleModif={handleModif}
                />
                <CommonPopUp
                    showPopUp={showExternalPopUp}
                    setShowPopUp={setShowExternalPopUp}
                    popUpContent={popUpContent}
                    userContext={userContext}
                    handleModif={handleModif}
                    action={action}
                    setClientId={setClientId}
                    document={[]}
                    setDocument={(val) => {/* Empty array is fine */ }}
                    formatDate={() => { }}
                    setShowClientPopUp={() => { }}
                    setShowProductPopUp={() => { }}
                    showProductPopUp={false}
                    reference=""
                    setReference={() => { }}
                    siret=""
                    setSiret={() => { }}
                    address=""
                    setAddress={() => { }}
                    type=""
                    payingMethod=""
                    setPayingMethod={() => { }}
                    billingAddress={{}}
                    setBillingAddress={() => { }}
                    isTheSameAsFullAddress={false}
                    setFullAddress={() => { }}
                    fullAddress={{}}
                    setFullSecondAddress={() => { }}
                    fullSecondAddress={{}}
                    setIsTheSameAsFullAddress={() => { }}
                    fullName=""
                    setFullName={() => { }}
                    clientMail=""
                    setClientMail={() => { }}
                    setProducts={() => { }}
                    handleProductUpdate={() => { }}
                    exportToCsv={() => { }}
                    setPopUpContent={() => { }}
                    setAction={() => { }}
                    onUpdate={() => { }}
                />
            </div>
        )
    }
}

export function PopUp({ showPopUp, popUpContent, setShowPopUp, userContext, handleModif, apiActionOut, setShowClientPopUp, handleClientSelection }) {
    const what = popUpContent?.what;
    const thing = popUpContent?.thing;
    const [theStatus, setTheStatus] = useState("Personne physique");
    const [professional, setProfessional] = useState(false);
    const [clientId, setClientId] = useState("");
    const [socialReason, setSocialReason] = useState("");
    const [siret, setSiret] = useState("");
    const [civility, setCivility] = useState("Monsieur");
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [phone2, setPhone2] = useState("");
    const [address, setAddress] = useState({
        roadAndNumber: "",
        zipCode: "",
        city: "",
        country: ""
    });
    const [comments, setComments] = useState("");
    const [taxesInfo, setTaxesInfo] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formeJuridique, setFormeJuridique] = useState("");
    const [paymentMethod, setpaymentMethod] = useState("");
    const [fullSecondAddress, setFullSecondAddress] = useState({
        roadAndNumber: "",
        zipCode: "",
        city: "",
        country: ""
    });
    const [billingAddress, setBillingAddress] = useState({
        roadAndNumber: "",
        zipCode: "",
        city: "",
        country: "",
        complement: "",
        isTheSameAsFullAddress: false
    });
    const [isTheSameAsFullAddress, setIsTheSameAsFullAddress] = useState(false);

    const contactPopUp = useRef(null);

    useEffect(() => {
        if (thing?.object) {
            setTheStatus(thing.object.status || "Personne physique")
            setProfessional(thing.object.professional || false)
            setClientId(thing.object.clientId || "")
            setSocialReason(thing.object.socialReason || "")
            setSiret(thing.object.siret || "")
            setCivility(thing.object.civility || "Monsieur")
            setName(thing.object.name || "")
            setLastName(thing.object.lastName || "")
            setEmail(thing.object.email || "")
            setPhone(thing.object.phone || "")
            setPhone2(thing.object.phone2 || "")
            setAddress({
                roadAndNumber: thing.object.fullAddress?.roadAndNumber || "",
                zipCode: thing.object.fullAddress?.zipCode || "",
                city: thing.object.fullAddress?.city || "",
                country: thing.object.fullAddress?.country || "",
                complement: thing.object.fullAddress?.complement || ""
            });
            setComments(thing.object.comments || "")
            setTaxesInfo(thing.object.taxesInfo || "")
            setFormeJuridique(thing.object.formeJuridique || "")
            setpaymentMethod(thing.object.paymentMethod || "")
            setFullSecondAddress({
                roadAndNumber: thing.object.fullSecondAddress?.roadAndNumber || "",
                zipCode: thing.object.fullSecondAddress?.zipCode || "",
                city: thing.object.fullSecondAddress?.city || "",
                country: thing.object.fullSecondAddress?.country || "",
                complement: thing.object.fullSecondAddress?.complement || ""
            });
            setBillingAddress({
                roadAndNumber: thing.object.billingAddress?.roadAndNumber || "",
                zipCode: thing.object.billingAddress?.zipCode || "",
                city: thing.object.billingAddress?.city || "",
                country: thing.object.billingAddress?.country || "",
                complement: thing.object.billingAddress?.complement || false,
                isTheSameAsFullAddress: thing.object.billingAddress?.isTheSameAsFullAddress || false
            });



        }
    }, [thing]);

    useEffect(() => {
        if (clientId === "") {
            userContext.apiReducer("getNextClientId")
                .then((response) => {
                    setClientId(response)
                })
                .catch((err) => {
                    console.log(err, "error")
                });
        }
    }, [clientId, userContext]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isSubmitting) return; // Prevent double submission
        setIsSubmitting(true);

        // Validate required fields based on status
        const validationErrors = [];

        // Common required fields
        if (!clientId) validationErrors.push("Code client");
        if (!email) validationErrors.push("Email");
        if (!phone) validationErrors.push("Téléphone");

        // Status-specific validation
        if (theStatus === "Personne morale") {
            if (!socialReason) validationErrors.push("Raison sociale");
            if (!siret) validationErrors.push("N° Siret");
            if (!formeJuridique || formeJuridique === "Sélectionner une forme juridique") {
                validationErrors.push("Forme juridique");
            }
        } else {
            if (!name) validationErrors.push("Prénom");
            if (!lastName) validationErrors.push("Nom");
        }

        if (validationErrors.length > 0) {
            setIsSubmitting(false);
            userContext.setError({
                level: 'warning',
                message: `Veuillez remplir les champs obligatoires suivants : ${validationErrors.join(", ")}`,
                btn1Text: 'OK',
                btn2hidden: true
            });
            return;
        }

        const modifications = {
            status: theStatus,
            professional,
            clientId,
            socialReason,
            siret,
            civility,
            name,
            lastName,
            email,
            phone,
            phone2,
            fullAddress: address,
            comments,
            taxesInfo,
            formeJuridique,
            fullSecondAddress,
            billingAddress: isTheSameAsFullAddress ? address : billingAddress, // Use main address if same
            paymentMethod,
        };

        let apiAction = apiActionOut || (thing.object && Object.keys(thing.object).length > 0 ? 'modifyClient' : 'addClient');
        const apiParams = Object.keys(thing).length > 0 ? { email: thing.object.email } : {};

        userContext.apiReducer(apiAction, modifications, apiParams)
            .then((response) => {
                // Call handleModif with the response
                if (typeof handleModif === 'function') {
                    handleModif(response, thing?.object, apiActionOut || "addClient");
                }

                // Close popups and clean up
                if (typeof setShowPopUp === 'function') {
                    setShowPopUp(false);
                }

                // Reset form state
                setIsSubmitting(false);

                // Show success message
                userContext.setError({
                    level: 'success',
                    message: apiAction === 'addClient' ? 'Client créé avec succès' : 'Client modifié avec succès',
                    btn1Text: 'OK',
                    btn2hidden: true
                });
            })
            .catch((err) => {
                console.error("API Error:", err);
                setIsSubmitting(false);
                userContext.setError({
                    level: 'error',
                    message: `Erreur lors de la ${apiAction === 'addClient' ? 'création' : 'modification'} du client : ${err.message || 'Erreur inconnue'}`,
                    btn1Text: 'OK',
                    btn2hidden: true
                });
            });
    };

    return (
        <div ref={contactPopUp} className={'billing-popup' + (showPopUp ? " open" : "")} onClick={
            (e) => {
                if (e.target.classList.contains('billing-popup')) {
                    e.target.classList.remove('open')
                    setShowPopUp(false);
                }
            }
        }>
            <div className="container">
                <h3>{what ? what?.title : "Modifier un contact :"}</h3>
                <h4>{thing ? thing?.name : "Nom du contact"}</h4>

                {what?.type === "Contact" ? (
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3 single-line">
                            <div className="d-flex line-flex">
                                <div className="me-3">
                                    <label htmlFor="status" className="form-label required">Type de contact :</label>
                                    <select required className="form-select" id="status" value={theStatus} onChange={(e) => setTheStatus(e.target.value)}>
                                        <option value="Personne physique">Personne physique</option>
                                        <option value="Personne morale">Personne morale</option>
                                    </select>
                                </div>
                                <div className="me-3">
                                    <label htmlFor="professional" className="form-label required">Professionnel :</label>
                                    <select
                                        required
                                        className="form-select"
                                        id="professional"
                                        value={professional.toString()} // Convert boolean to string for display
                                        onChange={(e) => setProfessional(e.target.value === 'true')}
                                    >
                                        <option value="true">Oui</option>
                                        <option value="false">Non</option>
                                    </select>
                                </div>
                                <div className="me-3">
                                    <label htmlFor="payingMethod" className="form-label required">Mode de paiement :</label>
                                    <select disabled={theStatus === "Facturé" || theStatus === "Validé"} required className="form-select" id="payingMethod" value={paymentMethod} onChange={(e) => setpaymentMethod(e.target.value)}>
                                        <option value="">Selectionner un mode de paiement</option>
                                        <option value="PRE">PRE - Prélèvement</option>
                                        <option value="VIR">VIR - Virement</option>
                                        <option value="CB">CB - Carte bancaire</option>
                                        <option value="CHQ">CHQ - Chèque</option>
                                        <option value="ESP">ESP - Espèces</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3 single-line">
                            <div className="d-flex line-flex">
                                <div className="me-3">
                                    <label htmlFor="clientId" className="form-label required">Code client :</label>
                                    <input required type="text" className="form-control" id="clientId" value={clientId} onChange={(e) => setClientId(e.target.value)} />
                                </div>
                                <div className="me-3" required={theStatus === "Personne morale"} hidden={theStatus === "Personne physique"}>
                                    <label htmlFor='socialReason' className="form-label required">Raison sociale :</label>
                                    <input type="text" className="form-control" id="socialReason" value={socialReason} onChange={(e) => setSocialReason(e.target.value)} />
                                </div>
                                <div className="me-3" required={theStatus === "Personne morale"} hidden={theStatus === "Personne physique"}>
                                    <label htmlFor="formeJuridique" className="form-label required">Forme juridique :</label>
                                    <select className="form-select" id="formeJuridique" value={formeJuridique} onChange={(e) => setFormeJuridique(e.target.value)}>
                                        <option value="Sélectionner une forme juridique">Sélectionner une forme juridique</option>
                                        <option value="Association">Association</option>
                                        <option value="Auto-entrepreneur">Auto-entrepreneur</option>
                                        <option value="Coopérative">Coopérative</option>
                                        <option value="CUMA">CUMA</option>
                                        <option value="EARL">EARL</option>
                                        <option value="EI">EI</option>
                                        <option value="EIRL">EIRL</option>
                                        <option value="EURL">EURL</option>
                                        <option value="GAEC">GAEC</option>
                                        <option value="GEIE">GEIE</option>
                                        <option value="GFA">GFA</option>
                                        <option value="GIE">GIE</option>
                                        <option value="Groupement">Groupement</option>
                                        <option value="Indivision">Indivision</option>
                                        <option value="Micro-entreprise">Micro-entreprise</option>
                                        <option value="SARL">SARL</option>
                                        <option value="SA">SA</option>
                                        <option value="SAS">SAS</option>
                                        <option value="SASU">SASU</option>
                                        <option value="SC">SC</option>
                                        <option value="SCA">SCA</option>
                                        <option value="SCEA">SCEA</option>
                                        <option value="SCEV">SCEV</option>
                                        <option value="SCI">SCI</option>
                                        <option value="SCIC">SCIC</option>
                                        <option value="SCM">SCM</option>
                                        <option value="SCL">SCL</option>
                                        <option value="SCOP">SCOP</option>
                                        <option value="SCP">SCP</option>
                                        <option value="SCS">SCS</option>
                                        <option value="SDF">SDF</option>
                                        <option value="SEL">SEL</option>
                                        <option value="SELAFA">SELAFA</option>
                                        <option value="SELARL">SELARL</option>
                                        <option value="SELAS">SELAS</option>
                                        <option value="SELCA">SELCA</option>
                                        <option value="SEM">SEM</option>
                                        <option value="SEML">SEML</option>
                                        <option value="SEP">SEP</option>
                                        <option value="SICA">SICA</option>
                                        <option value="SNC">SNC</option>
                                        <option value="SPFPLA">SPFPLA</option>
                                        <option value="STEF">STEF</option>
                                    </select>
                                </div>
                                <div className="me-3" hidden={theStatus === "Personne physique"}>
                                    <label htmlFor='siret' className="form-label required">N° Siret :</label>
                                    <input type="text" required={theStatus === "Personne morale"} className="form-control" id="siret" value={siret} onChange={(e) => setSiret(e.target.value.replace(/\D/g, '').slice(0, 14))} />
                                </div>
                                <div className="me-3" hidden={theStatus === "Personne physique"}>
                                    <label htmlFor="civility" className="form-label required">Civilité :</label>
                                    <select required={theStatus === "Personne physique"} className="form-select" id="civility" value={civility} onChange={(e) => setCivility(e.target.value)}>
                                        <option value="Monsieur">Monsieur</option>
                                        <option value="Madame">Madame</option>
                                        <option value="Mademoiselle">Mademoiselle</option>
                                        <option value="Maître">Maître</option>
                                        <option value="Docteur">Docteur</option>
                                    </select>
                                </div>
                                <div className="me-3" hidden={theStatus === "Personne morale"}>
                                    <label htmlFor="name" className="form-label required">Prénom :</label>
                                    <input required={theStatus === "Personne physique"} type="text" className="form-control" id="name" value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                                <div className="me-3" hidden={theStatus === "Personne morale"}>
                                    <label htmlFor="lastName" className="form-label required">Nom :</label>
                                    <input required={theStatus === "Personne physique"} type="text" className="form-control" id="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="mb-3 single-line">
                            <div className="d-flex line-flex">
                                <div className="me-3">
                                    <label htmlFor="email" className="form-label required">Email :</label>
                                    <input type="email" required className="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className="me-3">
                                    <label htmlFor="phone" className="form-label required">Téléphone :</label>
                                    <input type="tel" required className="form-control" id="phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                </div>
                                <div>
                                    <label htmlFor="phone2" className="form-label">Téléphone 2 :</label>
                                    <input type="tel" className="form-control" id="phone2" value={phone2} onChange={(e) => setPhone2(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="mb-3 single-line">
                            <h5 className=' mt-4'>Adresse principale :</h5>
                            <div className="d-flex line-flex">
                                <div className="me-3">
                                    <label htmlFor="roadAndNumber" className="form-label required">Numéro et Rue :</label>
                                    <input type="text" required className="form-control" id="roadAndNumber" value={address.roadAndNumber} onChange={(e) => setAddress({ ...address, roadAndNumber: e.target.value })} />
                                </div>
                                <div className="me-3">
                                    <label htmlFor="complement" className="form-label">Complément d'adresse :</label>
                                    <input type="text" className="form-control" id="complement" value={address.complement} onChange={(e) => setAddress({ ...address, complement: e.target.value })} />
                                </div>

                                <div className="me-3">
                                    <label htmlFor="zipCode" className="form-label required">Code postal :</label>
                                    <input type="text" required className="form-control" id="zipCode" value={address.zipCode} onChange={(e) => setAddress({ ...address, zipCode: e.target.value })} />
                                </div>
                                <div className="me-3">
                                    <label htmlFor="city" className="form-label required">Ville :</label>
                                    <input type="text" required className="form-control" id="city" value={address.city} onChange={(e) => setAddress({ ...address, city: e.target.value })} />
                                </div>
                                <div>
                                    <label htmlFor="country" className="form-label required">Pays :</label>
                                    <input type="text" required className="form-control" id="country" value={address.country} onChange={(e) => setAddress({ ...address, country: e.target.value })} />
                                </div>
                            </div>
                        </div>


                        <div className="mb-3 single-line">
                            <h5>Adresse secondaire :</h5>
                            <div className="d-flex line-flex">
                                <div className="me-3">
                                    <label htmlFor="roadAndNumber" className="form-label required">Numéro et Rue :</label>
                                    <input type="text" required className="form-control" id="roadAndNumber" value={fullSecondAddress.roadAndNumber} onChange={(e) => setFullSecondAddress({ ...fullSecondAddress, roadAndNumber: e.target.value })} />
                                </div>
                                <div className="me-3">
                                    <label htmlFor="complement" className="form-label">Complément d'adresse :</label>
                                    <input type="text" className="form-control" id="complement" value={fullSecondAddress.complement} onChange={(e) => setFullSecondAddress({ ...fullSecondAddress, complement: e.target.value })} />
                                </div>

                                <div className="me-3">
                                    <label htmlFor="zipCode" className="form-label required">Code postal :</label>
                                    <input type="text" required className="form-control" id="zipCode" value={fullSecondAddress.zipCode} onChange={(e) => setFullSecondAddress({ ...fullSecondAddress, zipCode: e.target.value })} />
                                </div>
                                <div className="me-3">
                                    <label htmlFor="city" className="form-label required">Ville :</label>
                                    <input type="text" required className="form-control" id="city" value={fullSecondAddress.city} onChange={(e) => setFullSecondAddress({ ...fullSecondAddress, city: e.target.value })} />
                                </div>
                                <div>
                                    <label htmlFor="country" className="form-label required">Pays :</label>
                                    <input type="text" required className="form-control" id="country" value={fullSecondAddress.country} onChange={(e) => setFullSecondAddress({ ...fullSecondAddress, country: e.target.value })} />
                                </div>
                            </div>
                        </div>

                        <div className="mb-4 single-line">
                            <h5>Adresse de facturation :</h5>
                            <input type="checkbox" id="sameAddress" checked={isTheSameAsFullAddress} onChange={(e) => {
                                const checked = e.target.checked;
                                setIsTheSameAsFullAddress(checked);
                                setBillingAddress((prev) => ({
                                    ...prev,
                                    isTheSameAsFullAddress: checked,
                                }));
                            }}
                            />
                            <label htmlFor="sameAddress" className="form-label">-Même adresse que l'adresse principale</label>

                            <div className="d-flex line-flex">
                                <div className="me-3">
                                    <label htmlFor="roadAndNumber" className="form-label required">Numéro et Rue :</label>
                                    <input disabled={isTheSameAsFullAddress} type="text" required className="form-control" id="roadAndNumber" value={isTheSameAsFullAddress ? address.roadAndNumber : billingAddress.roadAndNumber} onChange={(e) => setBillingAddress({ ...billingAddress, roadAndNumber: e.target.value })} />
                                </div>
                                <div className="me-3">
                                    <label htmlFor="complement" className="form-label">Complément d'adresse :</label>
                                    <input disabled={isTheSameAsFullAddress} type="text" className="form-control" id="complement" value={isTheSameAsFullAddress ? address.complement : billingAddress.complement} onChange={(e) => setBillingAddress({ ...billingAddress, complement: e.target.value })} />
                                </div>

                                <div className="me-3">
                                    <label htmlFor="zipCode" className="form-label required">Code postal :</label>
                                    <input disabled={isTheSameAsFullAddress} type="text" required className="form-control" id="zipCode" value={isTheSameAsFullAddress ? address.zipCode : billingAddress.zipCode} onChange={(e) => setBillingAddress({ ...billingAddress, zipCode: e.target.value })} />
                                </div>
                                <div className="me-3">
                                    <label htmlFor="city" className="form-label required">Ville :</label>
                                    <input disabled={isTheSameAsFullAddress} type="text" required className="form-control" id="city" value={isTheSameAsFullAddress ? address.city : billingAddress.city} onChange={(e) => setBillingAddress({ ...billingAddress, city: e.target.value })} />
                                </div>
                                <div>
                                    <label htmlFor="country" className="form-label required">Pays :</label>
                                    <input disabled={isTheSameAsFullAddress} type="text" required className="form-control" id="country" value={isTheSameAsFullAddress ? address.country : billingAddress.country} onChange={(e) => setBillingAddress({ ...billingAddress, country: e.target.value })} />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <label htmlFor="comments" className="form-label">Commentaires :</label>
                        <textarea className="form-control" id="comments" value={comments} onChange={(e) => setComments(e.target.value)}></textarea>
                        <label htmlFor="taxesInfo" className="form-label">Infos TVA :</label>
                        <input type="text" className="form-control" id="taxesInfo" value={taxesInfo} onChange={(e) => setTaxesInfo(e.target.value)} />
                        <button type="submit" className="btn btn-primary mt-3">{isSubmitting ? <Spinner strokeWidth={8} width={40} color={"#faba79"} /> : "Enregistrer"}</button>
                    </form>
                ) : null}
            </div>
        </div>
    );
}
